import cookieService from './cookieService';
import apiService from './apiService';

export default class authService {

    static signOut () {
        cookieService.removeToken();
    }

    static isUserAuth () {
        return apiService.get('/user/session', true);
    }

}
