import { DialogController } from "../controller/dialogController";
import { HelpController } from "../controller/helpController";
import { MainController } from "../controller/mainController";
import { GameScene } from "../display/gameScene";
import { ParallaxScene } from "../display/parallaxScene";
import { DialogEvent } from "../events/dialogEvent";
import { Text } from "../display/text";

class Chapter7Scene2 extends GameScene {



    constructor() {
        super("chapter7Scene2");
    } 

    preload ()
    {
        super.preload();


        this.load.image('c7-s2-background', 'assets/images/chapters/chapter7/scene2/background.jpg');
        this.load.image('c7-s2-phishing-web', 'assets/images/chapters/chapter7/scene2/phishing_web.png');
        this.load.image('c7-s2-phishing-web-1', 'assets/images/chapters/chapter7/scene2/phishing_web_1.png');
        this.load.image('c7-s2-phishing-email', 'assets/images/chapters/chapter7/scene2/phishing_email.png');
        this.load.image('c7-s2-phishing-email-1', 'assets/images/chapters/chapter7/scene2/phishing_email_1.png');
        this.load.image('c7-s2-phishing-email-2', 'assets/images/chapters/chapter7/scene2/phishing_email_2.png');
        this.load.image('c7-s2-phishing-email-3', 'assets/images/chapters/chapter7/scene2/phishing_email_3.png');

        this.load.audio('ambient-grave', 'assets/audios/musics/ambiance-grave.mp3');
       
        
    }

    create ()
    {
        super.create();

        this.setParallaxDistance( ParallaxScene.DISTANCE_IMMEDIATE );

        this.background = this.add.image(0, 0, 'c7-s2-background');
        
        this.phishingEmail = this.add.image(20, 18, 'c7-s2-phishing-email');
        this.phishingEmailMistakes = this.add.image(20, 18, 'c7-s2-phishing-email-1');
        this.phishingEmailUrgency = this.add.image(20, 18, 'c7-s2-phishing-email-2');
        this.phishingEmailDomain = this.add.image(20, 18, 'c7-s2-phishing-email-3');

        this.phishingEmailMistakes.setVisible(false);
        this.phishingEmailUrgency.setVisible(false);
        this.phishingEmailDomain.setVisible(false);

        this.phishingWeb = this.add.image(20, 18, 'c7-s2-phishing-web');
        this.phishingWebSSL = this.add.image(20, 18, 'c7-s2-phishing-web-1');

        this.phishingWeb.setVisible(false);
        this.phishingWebSSL.setVisible(false);
        
        this.musicAudio = this.sound.add(
                'ambient-grave', 
                {
                    volume: 0.5,
                    loop: true
                });

        this.musicAudio.play();

        this.addToScene( this.background, 1 );

        this.addToScene( this.phishingEmail, 1 );
        this.addToScene( this.phishingEmailMistakes, 1 );
        this.addToScene( this.phishingEmailUrgency, 1 );
        this.addToScene( this.phishingEmailDomain, 1 );

        this.addToScene( this.phishingWeb, 1 );
        this.addToScene( this.phishingWebSSL, 1 );

        this.phishingEmailMistakesArea1 = this.add.rectangle(274, -60, 60, 30, 0xffff00, 0);
        this.phishingEmailMistakesArea1.setInteractive();
        this.phishingEmailMistakesArea1.setVisible(true);
        this.addToScene( this.phishingEmailMistakesArea1, 1 );

        this.phishingEmailMistakesArea2 = this.add.rectangle(-230, -12, 60, 30, 0xffff00, 0);
        this.phishingEmailMistakesArea2.setInteractive();
        this.phishingEmailMistakesArea2.setVisible(true);
        this.addToScene( this.phishingEmailMistakesArea2, 1 );

        this.phishingEmailDomainArea1 = this.add.rectangle(-795, -546, 100, 30, 0x00ffff, 0);
        this.phishingEmailDomainArea1.setInteractive();
        this.phishingEmailDomainArea1.setVisible(true);
        this.addToScene( this.phishingEmailDomainArea1, 1 );

        this.phishingEmailDomainArea2 = this.add.rectangle(-189, 406, 100, 30, 0x00ffff, 0);
        this.phishingEmailDomainArea2.setInteractive();
        this.phishingEmailDomainArea2.setVisible(true);
        this.addToScene( this.phishingEmailDomainArea2, 1 );        

        this.phishingEmailUrgencyArea = this.add.rectangle(-105, 40, 500, 30, 0x00ff00, 0);
        this.phishingEmailUrgencyArea.setInteractive();
        this.phishingEmailUrgencyArea.setVisible(true);
        this.addToScene( this.phishingEmailUrgencyArea, 1 );


        this.phishingEmailLinkWebArea = this.add.rectangle(45, 180, 548, 56, 0xff0000, 0);
        this.phishingEmailLinkWebArea.setInteractive();
        this.phishingEmailLinkWebArea.setVisible(true);
        this.addToScene( this.phishingEmailLinkWebArea, 1 );


        this.phishingWebSSLArea = this.add.rectangle(-1055, -580, 50, 30, 0xff0000, 0);
        this.phishingWebSSLArea.setInteractive();
        this.phishingWebSSLArea.setVisible(true);
        this.addToScene( this.phishingWebSSLArea, 1 );


        this.timerStart = this.time.delayedCall(5000, function(){
            
            this.firstDialog();
            //this.emailUrgencyDialog();

        }, [], this);
    

        MainController.setCurrentScene( this );


    }

    firstDialog()
    {
     
        this.dialog("theo", Text.get("c7s2-dialog1"));
        this.dialog("weston", Text.get("c7s2-dialog2"), function(scene){
            scene.activeEmailMistakesAreas();
            HelpController.add( Text.get("c7s2-help1") );
            scene.timerHelpEmailMistakes = scene.time.delayedCall(60000, function(){
                HelpController.add( Text.get("c7s2-help2") );
            }, [], scene);
        }, this);
        
   
    }

    activeEmailMistakesAreas()
    {
        this.phishingEmailMistakesArea1.once('pointerdown', function(){
            this.emailMistakesWereClicked();
        }, this);

        this.phishingEmailMistakesArea2.once('pointerdown', function(){
            this.emailMistakesWereClicked();
        }, this);
    }

    unactiveEmailMistakesAreas()
    {
        this.phishingEmailMistakesArea1.off('pointerdown');
        this.phishingEmailMistakesArea2.off('pointerdown');
    }

    



    activeEmailDomainsAreas()
    {
        this.phishingEmailDomainArea1.once('pointerdown', function(){
            this.emailDomainsWereClicked();
        }, this);

        this.phishingEmailDomainArea2.once('pointerdown', function(){
            this.emailDomainsWereClicked();
        }, this);
    }

    unactiveEmailDomainsAreas()
    {
        this.phishingEmailDomainArea1.off('pointerdown');
        this.phishingEmailDomainArea2.off('pointerdown');
    }




    activeEmailUrgencyArea()
    {
        this.phishingEmailUrgencyArea.once('pointerdown', function(){
            this.emailUrgencyWasClicked();
        }, this);

    }

    unactiveEmailUrgencyArea()
    {
        this.phishingEmailUrgencyArea.off('pointerdown');
    }




    activeLinkWebArea()
    {
        this.phishingEmailLinkWebArea.once('pointerdown', function(){
            this.emailLinkWebWasClicked();
        }, this);

    }

    unactiveLinkWebArea()
    {
        this.phishingEmailLinkWebArea.off('pointerdown');
    }



    activeLinkSSLArea()
    {
        this.phishingWebSSLArea.once('pointerdown', function(){
            this.linkSSLWasClicked();
        }, this);

    }

    unactiveLinkSSLArea()
    {
        this.phishingWebSSLArea.off('pointerdown');
    }




    emailMistakesWereClicked()
    {
        if(this.timerHelpEmailMistakes != null)
        {
            this.timerHelpEmailMistakes.remove();    
        }

        this.unactiveEmailMistakesAreas();
        this.phishingEmailMistakes.setVisible(true);
    
        this.phishingEmailMistakesTween = this.tweens.add({
            targets: this.phishingEmailMistakes,
            alpha: {from: 0, to: 1},
            duration: 500
        });
        
        this.time.delayedCall(3000, function(){
            this.emailMistakesDialog();
        }, [], this);
    }

    emailDomainsWereClicked()
    {
        if(this.timerHelpEmailDomains != null)
        {
            this.timerHelpEmailDomains.remove();    
        }

        this.unactiveEmailDomainsAreas();
        this.phishingEmailDomain.setVisible(true);

        this.phishingEmailDomainTween = this.tweens.add({
            targets: this.phishingEmailDomain,
            alpha: {from: 0, to: 1},
            duration: 500
        });
        
        this.time.delayedCall(3000, function(){
            this.emailDomainsDialog();
        }, [], this);
    }

    emailUrgencyWasClicked()
    {
        if(this.timerHelpEmailUrgency != null)
        {
            this.timerHelpEmailUrgency.remove();    
        }

        this.unactiveEmailUrgencyArea();
        this.phishingEmailUrgency.setVisible(true);

        this.phishingEmailUrgencyTween = this.tweens.add({
            targets: this.phishingEmailUrgency,
            alpha: {from: 0, to: 1},
            duration: 500
        });
        
        this.time.delayedCall(3000, function(){
            this.emailUrgencyDialog();
        }, [], this);
    }


    emailLinkWebWasClicked()
    {
        this.phishingWeb.setVisible(true);
        this.phishingWebTween = this.tweens.add({
            targets: this.phishingWeb,
            alpha: {from: 0, to: 1},
            duration: 1000
        });

        this.time.delayedCall(5000, function(){
            this.webDialog();
        }, [], this);
     
    }


    linkSSLWasClicked()
    {
        if(this.timerHelpSSL != null)
        {
            this.timerHelpSSL.remove();    
        }

        this.phishingWebSSL.setVisible(true);
        this.phishingWebSSLTween = this.tweens.add({
            targets: this.phishingWebSSL,
            alpha: {from: 0, to: 1},
            duration: 500
        });

        this.time.delayedCall(3000, function(){
            this.sslDialog();
        }, [], this);
     
    }



    emailMistakesDialog()
    {
        HelpController.erase();
        DialogController.erase();
        this.cleanNextDialog();

        this.dialog("theo", Text.get("c7s2-dialog3_0"));
        this.dialog("weston", Text.get("c7s2-dialog3_1"));
        this.dialog("theo", Text.get("c7s2-dialog3_2"));
        this.dialog("weston", Text.get("c7s2-dialog4"), function(scene){
            scene.phishingEmailMistakes.setVisible(false);
            scene.activeEmailDomainsAreas();

            HelpController.add( Text.get("c7s2-help3") );
            scene.timerHelpEmailDomains = scene.time.delayedCall(60000, function(){
                HelpController.add( Text.get("c7s2-help4") );
            }, [], scene);
            
        }, this);
    }



    emailDomainsDialog()
    {
        HelpController.erase();
        DialogController.erase();
        this.cleanNextDialog();

        this.dialog("theo", Text.get("c7s2-dialog5"));
        this.dialog("weston", Text.get("c7s2-dialog6"));
        this.dialog("theo", Text.get("c7s2-dialog7"));
        this.dialog("weston", Text.get("c7s2-dialog8"), function(scene){
            scene.phishingEmailDomain.setVisible(false);
            scene.activeEmailUrgencyArea();

            HelpController.add( Text.get("c7s2-help5") );
            scene.timerHelpEmailUrgency = scene.time.delayedCall(60000, function(){
                HelpController.add( Text.get("c7s2-help6") );
            }, [], scene);
            
        }, this);
    }



    emailUrgencyDialog()
    {
        HelpController.erase();
        DialogController.erase();
        this.cleanNextDialog();

        this.dialog("theo", Text.get("c7s2-dialog9"));
        this.dialog("weston", Text.get("c7s2-dialog10"));
        this.dialog("wang", Text.get("c7s2-dialog11"));
        
        this.dialog("weston", Text.get("c7s2-dialog12"), function(scene){
            scene.phishingEmailDomain.setVisible(false);
            scene.activeLinkWebArea();

            HelpController.add( Text.get("c7s2-help7") );
            
            
        }, this);
    }

    webDialog()
    {
        HelpController.erase();
        DialogController.erase();
        this.cleanNextDialog();

        this.dialog("weston", Text.get("c7s2-dialog13"));
        this.dialog("theo", Text.get("c7s2-dialog14"));
        this.dialog("weston", Text.get("c7s2-dialog15"), function(scene){
            scene.activeLinkSSLArea();
            HelpController.add( Text.get("c7s2-help8") );
            scene.timerHelpSSL = scene.time.delayedCall(60000, function(){
                HelpController.add( Text.get("c7s2-help9") );
            }, [], scene);
        }, this);
        
       
    }


    sslDialog()
    {
        HelpController.erase();
        DialogController.erase();
        this.cleanNextDialog();

        this.dialog("theo", Text.get("c7s2-dialog16"));
        this.dialog("weston", Text.get("c7s2-dialog17"));
        this.dialog("theo", Text.get("c7s2-dialog18"));
        this.dialog("wang", Text.get("c7s2-dialog19"));
        this.dialog("wang", Text.get("c7s2-dialog20"));
        this.dialog("weston", Text.get("c7s2-dialog21"));
        this.dialog("wang", Text.get("c7s2-dialog22"));
        this.dialog("weston", Text.get("c7s2-dialog23"));
        this.dialog("wang", Text.get("c7s2-dialog24"));
        this.dialog("weston", Text.get("c7s2-dialog25"));
        this.dialog("theo", Text.get("c7s2-dialog26"));
        this.dialog("wang", Text.get("c7s2-dialog27"));
        this.dialog("theo", Text.get("c7s2-dialog28"));
        this.dialog("wang", Text.get("c7s2-dialog29"));
        this.dialog("weston", Text.get("c7s2-dialog30"));
        this.dialog("weston", Text.get("c7s2-dialog31"));
        this.dialog("theo", Text.get("c7s2-dialog32"));
        this.dialog("weston", Text.get("c7s2-dialog33"));
        this.dialog("theo", Text.get("c7s2-dialog34"));
        this.dialog("weston", Text.get("c7s2-dialog35"), function(){
            if(MainController.isMobile())
            {
                HelpController.add( Text.get("c7s2-help10")+"<a class='button' target='flirter' href='"+MainController.getFlirterURL()+"/c7s2/"+MainController.getToken()+"'>"+Text.get("c7s2-help10_1")+"</a>" );
            }else
            {
                HelpController.add( Text.get("c7s2-help11")+"<img src='https://api.qrserver.com/v1/create-qr-code/?data="+MainController.getFlirterURL()+"/c7s2/"+MainController.getToken()+"&size=180x180'><br><a target='flirter' href='"+MainController.getFlirterURL()+"/c7s2/"+MainController.getToken()+"'>"+Text.get("c7s2-help11_1")+"</a>" );
            }


           this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                this.removeEventListener(DialogEvent.CLOSE, handler);
                var scene = MainController.getCurrentScene();
                scene.goToScene('chapter7Scene3');
            });
        });

        
       
    }



    beforeTransition()
    {
        
        this.tweens.add({
            targets:  this.musicAudio,
            volume:   0,
            duration: 1000
        });

    } 

    beforeDestroy()
    {
        this.musicAudio.destroy();
    }

    
    


}
export {Chapter7Scene2}