import { ParallaxScene } from "./parallaxScene";
import Phaser from "phaser";

class IntroScene extends ParallaxScene {



	constructor(name) {
		super(name);

		this.parallaxActive = false;
	} 

	preload ()
    {
        super.preload();

       
    }

    create ()
    {
        super.create();


        this.shield = this.add.image(0,0, 'shield');
        this.hat = this.add.image(0,-70, 'hat');
        
        this.textTitle = this.add.bitmapText(0, -20, 'mexcellent', this.title, 95, Phaser.GameObjects.BitmapText.ALIGN_CENTER);
        this.textTitle.setOrigin(0.5);
        this.textSubTitle = this.add.bitmapText(0, 70, 'roboto-condensed-bi', this.subtitle, 44, Phaser.GameObjects.BitmapText.ALIGN_CENTER);
        this.textSubTitle.setOrigin(0.5);
        
		this.addToScene( this.shield );
		this.addToScene( this.hat );
		this.addToScene( this.textTitle );
        this.addToScene( this.textSubTitle );

        this.shield.alpha = 0;
        this.textTitle.alpha = 0;

        
        this.textSubTitle.alpha = 0;
        this.hat.alpha = 0;
        this.hat.angle = -5;
        this.hat.setOrigin(0.5, 1);


///*
        this.timeline = this.tweens.timeline({
            tweens: [
                {
                    targets: this.shield,
                    alpha: { from: 0, to: 1 },
                    ease: 'Linear',
                    duration: 2000
                },
                {
                    targets: this.textTitle,
                    props: {
                        alpha: {
                            value: { from: 0, to: 1 },
                            ease: 'Linear',
                            duration: 2000
                        },
                        y: {
                            value: { from: -200, to: -20 },
                            ease: 'Back',
                            duration: 1000
                        }
                    },
                    offset: '-=1000'
                },
                {
                    targets: this.textSubTitle,
                    props: {
                        alpha: {
                            value: { from: 0, to: 1 },
                            ease: 'Linear',
                            duration: 2000
                        },
                        y: {
                            value: { from: 200, to: 70 },
                            ease: 'Back',
                            duration: 1000
                        }
                    },
                    offset: '-=1000'
                },
                {
                    targets: this.hat,
                    props: {
                        alpha: {
                            value: { from: 0, to: 1 },
                            ease: 'Linear',
                            duration: 2000
                        },
                        y: {
                            value: { from: -200, to: -70 },
                            ease: 'Bounce',
                            duration: 1000
                        },
                        angle: {
                            value: { from: -5, to: 5 },
                            ease: 'Linear',
                            duration: 3000,
                            repeat: -1,
                            yoyo: true
                        }
                    },
                    offset: '-=1000'
                }
            ]
        });

        //*/




    }


}
export {IntroScene}