import { MainController } from "../controller/mainController";
import { IntroScene } from "../display/introScene";
import { Text } from "../display/text";

class Chapter10Intro extends IntroScene {



	constructor() {
		super("chapter10Intro");
        this.chapter = "chapter10";
		this.title = Text.get('chapter10-intro-title');
		this.subtitle = Text.get('chapter10-intro-subtitle');

        MainController.registerChapter(this);
	} 


    create ()
    {
        super.create();

        // j'enregistre la scène intro en bookmark
        MainController.bookmarkChapter(this);
        
		this.goToScene("chapter10Scene1", 5000);

        
    }


    beforeTransition()
    {
        //'on kill tout ce qui peut se déclencher pendant la transition'
        console.log('beforeTransition '+this.name);
    }

    beforeDestroy()
    {
        //'on peut tout killer'
        
    }

}
export {Chapter10Intro};