import { RootScene } from "./rootScene";
import $ from "jquery";
import { MainController } from "../controller/mainController";

class ParallaxScene extends RootScene {

    static get DISTANCE_IMMEDIATE(){
        return "parallax_scene_distance_immediate";
    }

    static get DISTANCE_NEAR(){
        return "parallax_scene_distance_near";
    }

    static get DISTANCE_FAR(){
        return "parallax_scene_distance_far";
    }

    static get DISTANCE_FARAWAY(){
        return "parallax_scene_distance_faraway";
    }

	constructor(name) {
		super(name);
        
        this.parallaxActive = true;
        this.parallaxDepthActive = true;
        

        this.bg1ParallaxValue = 1;
        this.bg2ParallaxValue = 1;
        this.bg3ParallaxValue = 1;

        this.smoothFactor = 0.9;

        this.mouseX = 0;
        this.mouseY = 0;
	}

    preload(active) {
        
        this.load.image('square', 'assets/images/square.png');
        super.preload(active);

    }


    addToScene(gameObject, backgroundIndex)
    {
        switch(backgroundIndex)
        {
            case 1:
                this.bg1.add(gameObject);
            break;
            case 2:
                this.bg2.add(gameObject);
            break;
            case 3:
                this.bg3.add(gameObject);
            break;
            default:
                this.bg0.add(gameObject);
        }
    }


    setParallaxDistance(value)
    {
        switch(value)
        {
            case ParallaxScene.DISTANCE_IMMEDIATE:
                this.bg1ParallaxValue = 1;
                this.bg2ParallaxValue = .95;
                this.bg3ParallaxValue = .9;
            break;
            case ParallaxScene.DISTANCE_NEAR:
                this.bg1ParallaxValue = 1;
                this.bg2ParallaxValue = .9;
                this.bg3ParallaxValue = .8;
            break;
            case ParallaxScene.DISTANCE_FAR:
                this.bg1ParallaxValue = 1;
                this.bg2ParallaxValue = .85;
                this.bg3ParallaxValue = .75;
            break;
            case ParallaxScene.DISTANCE_FARAWAY:
                this.bg1ParallaxValue = 1;
                this.bg2ParallaxValue = .75;
                this.bg3ParallaxValue = .5;
            break;
            default:
                this.bg1ParallaxValue = 1;
                this.bg2ParallaxValue = 1;
                this.bg3ParallaxValue = 1;
        }
    }



    create ()
    {
        this.mainCam = this.cameras.main;

        this.mainCam.fadeIn(this.transitionDuration);

        this.bg3 = this.add.container(this.mainCam.centerX, this.mainCam.centerY);
        this.bg2 = this.add.container(this.mainCam.centerX, this.mainCam.centerY);
        this.bg1 = this.add.container(this.mainCam.centerX, this.mainCam.centerY);
        this.bg0 = this.add.container(this.mainCam.centerX, this.mainCam.centerY);
        
        window.mouseX = this.mainCam.centerX;
        window.mouseY = this.mainCam.centerY;

        this.mouseListener = document.addEventListener('mousemove', this.onMouseUpdate);
    }



    update()
    {
        if(this.parallaxActive)
        {
            this.parallaxUpdate();
        }

    }

    onMouseUpdate(e) {
        
      window.mouseX = e.pageX;
      window.mouseY = e.pageY;


    }

    parallaxUpdate()
    {
        var offset = $("#game canvas").offset();
        var x = offset.left;
        var y = offset.top;
        var w = $("#game canvas").width();
        var h = $("#game canvas").height();

        this.updatedPos = {
            x: 0,
            y: 0
        };
        

        var mX = window.mouseX - x;
        var mY = window.mouseY - y;

        this.initialW = MainController.getGameInitialSize().width;
        this.initialH = MainController.getGameInitialSize().height;

        var posXPourc = (mX / w - 0.5 )* -1;
        var posYPourc = (mY / h - 0.5 )* -1;


        
        if(posXPourc < -0.5)
        {
            posXPourc = -0.5;
        }

        if(posXPourc > 0.5)
        {
            posXPourc = 0.5;
        }

        if(posYPourc < -0.5)
        {
            posYPourc = -0.5;
        }

        if(posYPourc > 0.5)
        {
            posYPourc = 0.5;
        }

        this.updatedPos.x = mX;
        this.updatedPos.y = mY;

        
        this.parallaxDepthActive = true;

        if(this.parallaxDepthActive)
        {
            var bg1PosX = (this.mainCam.centerX + this.initialW*posXPourc*this.bg1ParallaxValue);
            this.bg1.x = bg1PosX - (bg1PosX - this.bg1.x) * this.smoothFactor;

            var bg1PosY = (this.mainCam.centerY + this.initialH*posYPourc*this.bg1ParallaxValue)
            this.bg1.y = bg1PosY - (bg1PosY - this.bg1.y) * this.smoothFactor;

            var bg2PosX = (this.mainCam.centerX + this.initialW*posXPourc*this.bg2ParallaxValue);
            this.bg2.x = bg2PosX - (bg2PosX - this.bg2.x) * this.smoothFactor;

            var bg2PosY = (this.mainCam.centerY + this.initialH*posYPourc*this.bg2ParallaxValue)
            this.bg2.y = bg2PosY - (bg2PosY - this.bg2.y) * this.smoothFactor; 

            var bg3PosX = (this.mainCam.centerX + this.initialW*posXPourc*this.bg3ParallaxValue);
            this.bg3.x = bg3PosX - (bg3PosX - this.bg3.x) * this.smoothFactor;

            var bg3PosY = (this.mainCam.centerY + this.initialH*posYPourc*this.bg3ParallaxValue)
            this.bg3.y = bg3PosY - (bg3PosY - this.bg3.y) * this.smoothFactor;                        
        }

    }

    beforeDestroy()
    {
        if(this.mouseListener)
        this.mouseListener.removeEventListener("mousemove", this.onMouseUpdate);
    }



}
export {ParallaxScene}