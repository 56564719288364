import { DialogController } from "../controller/dialogController";
import { HelpController } from "../controller/helpController";
import { MainController } from "../controller/mainController";
import { RessourcesController } from "../controller/ressourcesController";
import { GameScene } from "../display/gameScene";
import { ParallaxScene } from "../display/parallaxScene";
import { DialogEvent } from "../events/dialogEvent";
import { Text } from "../display/text";

class Chapter3Scene2 extends GameScene {

    constructor() {
        super("chapter3Scene2");
        this.title = "";
        this.subtitle = "";
        this.pass = "miaou";

        
    } 

    preload ()
    {
        super.preload();
        this.load.plugin('rexinputtextplugin', 'assets/js/libs/rexinputtextplugin.min.js', true);

        this.load.image('c3-s2-background', 'assets/images/chapters/chapter3/scene2/background.jpg');
        this.load.image('c3-s2-firstplan', 'assets/images/chapters/chapter3/scene2/firstplan.png');

        this.load.image('c3-s2-session', 'assets/images/chapters/chapter3/scene2/session.jpg');

        this.load.image('c3-s2-usb-not-connected', 'assets/images/chapters/chapter3/scene2/usb-not-connected.png');
        this.load.image('c3-s2-usb-connected', 'assets/images/chapters/chapter3/scene2/usb-connected.png');

        this.load.image('c3-s2-desk', 'assets/images/chapters/chapter3/scene2/desk.jpg');
        this.load.image('c3-s2-desk1', 'assets/images/chapters/chapter3/scene2/desk1.jpg');
        this.load.image('c3-s2-desk2', 'assets/images/chapters/chapter3/scene2/desk2.jpg');
        this.load.image('c3-s2-desk3', 'assets/images/chapters/chapter3/scene2/desk3.jpg');
        this.load.image('c3-s2-desk4', 'assets/images/chapters/chapter3/scene2/desk4.jpg');
        this.load.image('c3-s2-desk5', 'assets/images/chapters/chapter3/scene2/desk5.jpg');
        this.load.image('c3-s2-alert', 'assets/images/chapters/chapter3/scene2/alert.jpg');
        
        this.load.audio('ambient-action', 'assets/audios/musics/ambiance-action.mp3');

        this.load.audio('click-dir', 'assets/audios/click-dir.mp3');
        this.load.audio('open-session', 'assets/audios/open-session.mp3');
        this.load.audio('error', 'assets/audios/error.mp3');

        
    }

    create ()
    {
        super.create();

        this.setParallaxDistance( ParallaxScene.DISTANCE_IMMEDIATE );

        this.background = this.add.image(0,0, 'c3-s2-background');
        
        this.firstplan = this.add.image(0, 0, 'c3-s2-firstplan');
        this.usbNotConnected = this.add.image(1275, 350, 'c3-s2-usb-not-connected');
        this.usbConnected = this.add.image(1280, 490, 'c3-s2-usb-connected');
        this.usbConnected.setVisible(false);

        this.session = this.add.image(-35, -260, 'c3-s2-session');
        this.session.setScale(1.1);

        this.submitArea = this.add.rectangle(172, -123, 60, 60, 0xff0000, 0);
        this.submitArea.setInteractive();

        this.desk = this.add.image(-8, -258, 'c3-s2-desk');
        this.desk.setScale(0.793);
        this.desk.alpha = 0;
        this.deskArea = this.add.rectangle(-980, -570, 60, 60, 0xff0000, 0);
        this.deskArea.setInteractive();
        this.deskArea.setVisible(false);


        this.desk1 = this.add.image(-8, -258, 'c3-s2-desk1');
        this.desk1.setScale(0.793);
        this.desk1.alpha = 0;
        this.desk1Area = this.add.rectangle(-600, -388, 200, 15, 0xff0000, 0);
        this.desk1Area.setInteractive();
        this.desk1Area.setVisible(false);

        this.desk2 = this.add.image(-8, -258, 'c3-s2-desk2');
        this.desk2.setScale(0.793);
        this.desk2.alpha = 0;
        this.desk2Area = this.add.rectangle(-600, -388, 200, 15, 0xff0000, 0);
        this.desk2Area.setInteractive();
        this.desk2Area.setVisible(false);

        this.desk3 = this.add.image(-8, -258, 'c3-s2-desk3');
        this.desk3.setScale(0.793);
        this.desk3.alpha = 0;
        this.desk3Area = this.add.rectangle(-600, -355, 200, 15, 0xff0000, 0);
        this.desk3Area.setInteractive();
        this.desk3Area.setVisible(false);

        this.desk4 = this.add.image(-8, -258, 'c3-s2-desk4');
        this.desk4.setScale(0.793);
        this.desk4.alpha = 0;
        this.desk4Area = this.add.rectangle(-600, -388, 200, 15, 0xff0000, 0);
        this.desk4Area.setInteractive();
        this.desk4Area.setVisible(false);

        this.desk5 = this.add.image(-8, -258, 'c3-s2-desk5');
        this.desk5.setScale(0.793);
        this.desk5.alpha = 0;


        this.alert = this.add.image(0, 0, 'c3-s2-alert');
        this.alert.setScale(1.1);
        this.alert.alpha = 0;

        this.inputSession = this.add.rexInputText(-60, -123, 380, 60, {
            
            type: 'password',
            placeholder: 'Mot de passe',
            fontSize: '32px',
            color: '#7c7060',
            align: ''
        });
        

        


        this.musicAudio = this.sound.add(
                'ambient-action', 
                {
                    volume: 0.5,
                    loop: true
                });

        

        this.musicAudio.play();

        this.clickAudio = this.sound.add('click-dir');
        this.sessionAudio = this.sound.add('open-session');
        this.errorAudio = this.sound.add('error');



        this.addToScene( this.background, 2 );
        
        this.addToScene( this.firstplan, 1 );
        this.addToScene( this.usbConnected, 1 );
        this.addToScene( this.usbNotConnected, 1 );

        this.addToScene( this.session, 1 );
        this.addToScene( this.inputSession, 1 );
        this.addToScene( this.submitArea, 1 );

        this.addToScene( this.desk, 1 );
        this.addToScene( this.deskArea, 1 );

        this.addToScene( this.desk1, 1 );
        this.addToScene( this.desk1Area, 1 );

        this.addToScene( this.desk2, 1 );
        this.addToScene( this.desk2Area, 1 );

        this.addToScene( this.desk3, 1 );
        this.addToScene( this.desk3Area, 1 );

        this.addToScene( this.desk4, 1 );
        this.addToScene( this.desk4Area, 1 );

        this.addToScene( this.desk5, 1 );

        this.addToScene( this.alert, 1 );

        
        this.timerStart = this.time.delayedCall(5000, function(){
            this.firstDialog();
            
        }, [], this);

        
        


        MainController.setCurrentScene( this );

     
    }

    firstDialog()
    {
        this.dialog("theo", Text.get("c3s2-dialog1"));
        this.dialog("wang", Text.get("c3s2-dialog2"), function(scene){
            RessourcesController.setRessource('secure-office');
        }, this);
        this.dialog("weston", Text.get("c3s2-dialog3"));
        this.dialog("wang", Text.get("c3s2-dialog4"), function(scene){
            scene.timerSession = scene.time.delayedCall(20000, function(){
                HelpController.add( Text.get("c3s2-help1") );
            }, [], scene); 

        }, this);
        
        this.submitArea.on('pointerdown', function(){
            this.checkPassword();
        }, this);
        
    }

    checkPassword(){
        
        this.clickAudio.play();

        if(this.timerSession)
        {
            this.timerSession.remove();    
        }
        

        if(this.inputSession.text != this.pass)
        {
            this.passwordFailure();
        }else
        {
            
            this.passwordSuccess();
        }
    }

    passwordFailure()
    {
        HelpController.erase();
        DialogController.erase();
        this.cleanNextDialog();

        this.dialog("theo", Text.get("c3s2-dialog5"));
        HelpController.add( Text.get("c3s2-help2") );
    }

    passwordSuccess()
    {
        HelpController.clear();
        DialogController.erase();
        this.cleanNextDialog();

        this.tweens.add({
            targets:  this.session,
            alpha:   0,
            duration: 1000
        });
        this.submitArea.destroy();
        this.inputSession.destroy();

        this.sessionAudio.play();

        this.dialog("theo", Text.get("c3s2-dialog6"));
        this.dialog("weston", Text.get("c3s2-dialog7"));
        this.quizFillIn("theo", Text.get("c3s2-dialog8"),
        Text.get("c3s2-dialog9"),
            function(result){
                DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
        
                if( (result[0].value.toUpperCase() == Text.get("c3s2-dialog10")) && 
                ( (result[1].value.toUpperCase() == Text.get("c3s2-dialog11")) || 
                (result[1].value.toUpperCase() == Text.get("c3s2-dialog12")) ) && 
                ( (result[2].value.toUpperCase() == Text.get("c3s2-dialog13")) || 
                (result[2].value.toUpperCase() == Text.get("c3s2-dialog14")) ) && 
                (result[3].value.toUpperCase() == Text.get("c3s2-dialog15")) && 
                (result[4].value.toUpperCase() == Text.get("c3s2-dialog16")) )
                {
        
                    this.scene.dialog("weston", Text.get("c3s2-dialog17"));
                }else
                {
        
                    this.scene.dialog("weston", Text.get("c3s2-dialog18"));
                }

                this.scene.openDesk();

            },
            null,
            this
        );
        

        this.tweens.add({
            targets:  this.desk,
            alpha:   1,
            duration: 1000
        });

        



    }

    openDesk()
    {
        this.clickAudio.play();

        this.deskArea.setVisible(true);
        this.deskArea.on('pointerdown', function(){
            this.openDesk1();
        }, this);


        this.timerDesk = this.time.delayedCall(3000, function(){
            this.cleanNextDialog();

            this.dialog("theo", Text.get("c3s2-dialog19"));
            this.dialog("wang", Text.get("c3s2-dialog20"), function(scene){
                scene.timerDeskB = scene.time.delayedCall(30000, function(){
                    DialogController.erase();
                    this.cleanNextDialog();
                    this.dialog("weston", Text.get("c3s2-dialog21"));
                    this.dialog("wang", Text.get("c3s2-dialog22"), function(scene){
                        scene.timerDeskC = scene.time.delayedCall(10000, function(){
                            HelpController.add( Text.get("c3s2-help3") );
                        }, [], scene); 
                    }, this);

                }, [], scene);
            }, this);



        }, [], this);


    }

    openDesk1()
    {
        this.clickAudio.play();

        HelpController.clear();
        DialogController.erase();
        this.cleanNextDialog();
        
        this.timerDesk1 = this.time.delayedCall(3000, function(){
            this.dialog("theo", Text.get("c3s2-dialog23"));
            this.dialog("wang", Text.get("c3s2-dialog24"));
        }, [], this);

        if(this.timerDesk)
        {
            this.timerDesk.remove();
        }
        if(this.timerDeskB)
        {
            this.timerDeskB.remove();
        }
        if(this.timerDeskC)
        {
            this.timerDeskC.remove();
        }

        this.tweens.add({
            targets:  this.desk,
            alpha:   0,
            duration: 1000
        });
        this.deskArea.destroy();

        this.tweens.add({
            targets:  this.desk1,
            alpha:   1,
            duration: 1000
        });

        this.desk1Area.setVisible(true);
        this.desk1Area.on('pointerdown', function(){
            this.openDesk2();
        }, this);

        
    }

    openDesk2()
    {
        this.clickAudio.play();

        HelpController.clear();
        DialogController.erase();
        this.cleanNextDialog();

        if(this.timerDesk1)
        {
            this.timerDesk1.remove();
        }

        this.timerDesk2 = this.time.delayedCall(3000, function(){
            this.dialog("weston", Text.get("c3s2-dialog25"));
            this.dialog("wang", Text.get("c3s2-dialog26"));
        }, [], this);         

        this.tweens.add({
            targets:  this.desk1,
            alpha:   0,
            duration: 1000
        });
        this.desk1Area.destroy();

        this.tweens.add({
            targets:  this.desk2,
            alpha:   1,
            duration: 1000
        });

        this.desk2Area.setVisible(true);
        this.desk2Area.on('pointerdown', function(){
            this.openDesk3();
        }, this);
    }    

    openDesk3()
    {
        this.clickAudio.play();

        HelpController.clear();
        DialogController.erase();
        this.cleanNextDialog();

        if(this.timerDesk2)
        {
            this.timerDesk2.remove();
        }

        this.timerDesk3 = this.time.delayedCall(3000, function(){
            this.dialog("wang", Text.get("c3s2-dialog27"));
            this.dialog("weston", Text.get("c3s2-dialog28"));
        }, [], this);            

        this.tweens.add({
            targets:  this.desk2,
            alpha:   0,
            duration: 1000
        });
        this.desk2Area.destroy();

        this.tweens.add({
            targets:  this.desk3,
            alpha:   1,
            duration: 1000
        });

        this.desk3Area.setVisible(true);
        this.desk3Area.on('pointerdown', function(){
            this.openDesk4();
        }, this);
    } 

    openDesk4()
    {
        this.clickAudio.play();

        HelpController.clear();
        DialogController.erase();
        this.cleanNextDialog();

        if(this.timerDesk3)
        {
            this.timerDesk3.remove();
        }

        this.timerDesk4 = this.time.delayedCall(3000, function(){
            this.dialog("theo", Text.get("c3s2-dialog29"));
            this.dialog("weston", Text.get("c3s2-dialog30"));
        }, [], this);

        this.tweens.add({
            targets:  this.desk3,
            alpha:   0,
            duration: 1000
        });
        this.desk3Area.destroy();

        this.tweens.add({
            targets:  this.desk4,
            alpha:   1,
            duration: 1000
        });

        this.desk4Area.setVisible(true);
        this.desk4Area.on('pointerdown', function(){
            this.openDesk5();
        }, this);
    }  


    openDesk5()
    {
        this.clickAudio.play();

        HelpController.clear();
        DialogController.erase();
        this.cleanNextDialog();

        if(this.timerDesk4)
        {
            this.timerDesk4.remove();
        }

        this.timerDesk5 = this.time.delayedCall(3000, function(){
            this.dialog("wang", Text.get("c3s2-dialog31"));
            this.dialog("theo", Text.get("c3s2-dialog32"));

        }, [], this);

        this.tweens.add({
            targets:  this.desk4,
            alpha:   0,
            duration: 1000
        });
        this.desk4Area.destroy();

        this.tweens.add({
            targets:  this.desk5,
            alpha:   1,
            duration: 1000
        });

        this.usbNotConnected.setInteractive();
        this.usbNotConnected.once('pointerdown', function(){
            this.usbConnected.alpha = 0;
            this.usbConnected.setVisible(true);

            this.usbTimeline = this.tweens.timeline({
                tweens: [
                    {
                        targets: this.usbNotConnected,
                        alpha: 0,
                        scaleX: 1.2,
                        scaleY: 1.2,
                        ease: 'Cubic',
                        duration: 500
                    },
                    {
                        targets: this.usbConnected,
                        alpha: 1,
                        scaleX: {from: 1.2, to: 1},
                        scaleY: {from: 1.2, to: 1},
                        ease: 'Cubic',
                        duration: 500,
                        offset: '-=200'
                    }
                    ]
                });
            this.openAlert();
        }, this);
    }   

    openAlert()
    {
        this.errorAudio.play();

        HelpController.clear();
        DialogController.erase();
        this.cleanNextDialog();

        if(this.timerDesk5)
        {
            this.timerDesk5.remove();
        }

        this.timerAlert = this.time.delayedCall(3000, function(){
            this.dialog("weston", Text.get("c3s2-dialog33"));
            this.dialog("weston", Text.get("c3s2-dialog34"));
            this.dialog("wang", Text.get("c3s2-dialog35"));
            this.dialog("weston", Text.get("c3s2-dialog36"));
            this.dialog("wang", Text.get("c3s2-dialog37"));
            this.dialog("weston", Text.get("c3s2-dialog38"), function(scene){
                
                MainController.completeChapter('chapter3');

                scene.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                    this.removeEventListener(DialogEvent.CLOSE, handler);
                    var scene = MainController.getCurrentScene();
                    scene.endScene();
                });
            }, this);
            
        }, [], this);

        


        this.tweens.add({
            targets:  this.alert,
            alpha:   1,
            duration: 1000
        });
    }  

    endScene()
    {
        if(this.timerAlert)
        {
            this.timerAlert.remove();
        }

        this.goToScene('chapter4Intro');

    }

    beforeTransition()
    {
        
        this.tweens.add({
            targets:  this.musicAudio,
            volume:   0,
            duration: 1000
        });

    }

    beforeDestroy()
    {
        this.musicAudio.destroy();
    }

}
export {Chapter3Scene2}