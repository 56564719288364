import { DialogController } from "../controller/dialogController";
import { HelpController } from "../controller/helpController";
import { MainController } from "../controller/mainController";
import { RessourcesController } from "../controller/ressourcesController";
import { GameScene } from "../display/gameScene";
import { ParallaxScene } from "../display/parallaxScene";
import { DialogEvent } from "../events/dialogEvent";
import { Text } from "../display/text";

class Chapter9Scene1 extends GameScene {



    constructor() {
        super("chapter9Scene1");
        this.title = Text.get("c9s1-title");
        this.subtitle = Text.get("c9s1-subtitle");
    } 

    preload ()
    {
        super.preload();
        this.load.image('c9-s1-background', 'assets/images/chapters/chapter9/scene1/background.jpg');
        this.load.image('c9-s1-firstplan', 'assets/images/chapters/chapter9/scene1/firstplan.png');
        this.load.image('c9-s1-secondplan', 'assets/images/chapters/chapter9/scene1/secondplan.png');

        this.load.audio('ambient-grave', 'assets/audios/musics/ambiance-grave.mp3');

        this.load.audio('tone-busy', 'assets/audios/tone-busy.mp3');
        
    }

    create ()
    {
        
        super.create();

        this.setParallaxDistance( ParallaxScene.DISTANCE_NEAR );

        this.background = this.add.image(0,0, 'c9-s1-background');
        this.secondplan = this.add.image(-250, 150, 'c9-s1-secondplan');
        this.firstplan = this.add.image(-800, 400, 'c9-s1-firstplan');

        this.toneBusy = this.sound.add('tone-busy');
        this.musicAudio = this.sound.add(
                'ambient-grave', 
                {
                    volume: 0.5,
                    loop: true
                });

        this.musicAudio.play();

        this.addToScene( this.background, 3 );
        this.addToScene( this.secondplan, 2 );
        this.addToScene( this.firstplan, 1 );

        this.timerStart = this.time.delayedCall(1000, function(){
            
            this.firstDialog();
           //this.sixthDialog();

        }, [], this);
    

        MainController.setCurrentScene( this );


    }

    firstDialog()
    {
        this.dialog("theo", Text.get("c9s1-dialog1"));
        this.dialog("weston", Text.get("c9s1-dialog2"));
        this.dialog("theo", Text.get("c9s1-dialog3"));
        this.dialog("weston", Text.get("c9s1-dialog4"));
        this.dialog("theo", Text.get("c9s1-dialog5"));
        this.dialog("weston", Text.get("c9s1-dialog6"));
        this.dialog("theo", Text.get("c9s1-dialog7"));
        this.dialog("weston", Text.get("c9s1-dialog8"));
        this.dialog("theo", Text.get("c9s1-dialog9"));
        this.dialog("weston", Text.get("c9s1-dialog10"));
        this.dialog("theo", Text.get("c9s1-dialog11"), function(scene){
            
            this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                this.removeEventListener(DialogEvent.CLOSE, handler);
                var scene = MainController.getCurrentScene();

                scene.toneBusy.play();


                scene.time.delayedCall(5000, function(){
                    this.toneBusy.stop();
                    this.secondDialog();
        
                }, [], scene);

                
            });

        });

    }

    secondDialog()
    {
        
        DialogController.erase();
        this.cleanNextDialog();

        this.dialog("theo", Text.get("c9s1-dialog12"));
        this.quizUniqueChoice("theo", Text.get("c9s1-dialog13"),
            [
                {
                    "id": "q1-choice1",
                    "label": Text.get("c9s1-dialog14"),
                    "value": 0
                },
                {
                    "id": "q1-choice2",
                    "label": Text.get("c9s1-dialog15"),
                    "value": 1
                }
            ],
            function(result){
                DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
                
                if(result[0].value == 1)
                {
                    this.scene.dialog("theo", Text.get("c9s1-dialog16"));
                }
                
                this.scene.thirdDialog();
                
            },
            null,
            this
        );
    }

    thirdDialog()
    {
        this.dialog("theo", Text.get("c9s1-dialog17"));
        this.quizUniqueChoice("theo", Text.get("c9s1-dialog18"),
            [
                {
                    "id": "q2-choice1",
                    "label": Text.get("c9s1-dialog19"),
                    "value": 0
                },
                {
                    "id": "q2-choice2",
                    "label": Text.get("c9s1-dialog20"),
                    "value": 1
                }
            ],
            function(result){
                DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
                
                if(result[0].value == 1)
                {
                    this.scene.dialog("theo", Text.get("c9s1-dialog21"));
                }
                
                this.scene.fourthDialog();
                
            },
            null,
            this
        );
    }


    fourthDialog()
    {
        this.dialog("theo", Text.get("c9s1-dialog22"));
        this.quizUniqueChoice("theo", Text.get("c9s1-dialog23"),
            [
                {
                    "id": "q3-choice1",
                    "label": Text.get("c9s1-dialog24"),
                    "value": 0
                },
                {
                    "id": "q3-choice2",
                    "label": Text.get("c9s1-dialog25"),
                    "value": 1
                }
            ],
            function(result){
                DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
                
                if(result[0].value == 0)
                {
                    this.scene.dialog("theo", Text.get("c9s1-dialog26"));
                }
                
                this.scene.dialog("theo", Text.get("c9s1-dialog27"), function(){
                    RessourcesController.setRessource('public-wifi');
                });
                this.scene.dialog("theo", Text.get("c9s1-dialog28"));
                this.scene.dialog("theo", Text.get("c9s1-dialog29"));
                
                this.scene.fifthDialog();
                
            },
            null,
            this
        );
    }

    fifthDialog()
    {
        this.dialog("theo", Text.get("c9s1-dialog30"));
        this.dialog("theo", Text.get("c9s1-dialog31"));
        this.dialog("theo", Text.get("c9s1-dialog32"));
        this.quizUniqueChoice("theo", Text.get("c9s1-dialog33"),
            [
                {
                    "id": "q4-choice1",
                    "label": Text.get("c9s1-dialog34"),
                    "value": 0
                },
                {
                    "id": "q4-choice2",
                    "label": Text.get("c9s1-dialog35"),
                    "value": 1
                }
            ],
            function(result){
                DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
                
                if(result[0].value == 0)
                {
                    this.scene.dialog("theo", Text.get("c9s1-dialog36"), function(){
                        RessourcesController.setRessource('vpn');
                    });
                }else
                {
                    this.scene.dialog("theo", Text.get("c9s1-dialog37"), function(){
                        RessourcesController.setRessource('vpn');
                    });
                }
                
                this.scene.sixthDialog();
                
                
            },
            null,
            this
        );
    }
    
    sixthDialog()
    {
        this.dialog("theo", Text.get("c9s1-dialog38"));
        this.dialog("theo", Text.get("c9s1-dialog39"));
        this.dialog("theo", Text.get("c9s1-dialog40"));
        this.quizUniqueChoice("theo", Text.get("c9s1-dialog41"),
            [
                {
                    "id": "q5-choice1",
                    "label": Text.get("c9s1-dialog42"),
                    "value": 0
                },
                {
                    "id": "q5-choice2",
                    "label": Text.get("c9s1-dialog43"),
                    "value": 1
                },
                {
                    "id": "q5-choice3",
                    "label": Text.get("c9s1-dialog44"),
                    "value": 2
                }
            ],
            function(result){
                DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
                
                if(result[0].value == 0)
                {
                    this.scene.dialog("theo", Text.get("c9s1-dialog45"));
                    this.scene.dialog("theo", Text.get("c9s1-dialog46"));
                }else if(result[0].value == 1)
                {
                    this.scene.dialog("theo", Text.get("c9s1-dialog47"));
                    this.scene.dialog("theo", Text.get("c9s1-dialog48"));
                }else
                {
                    this.scene.dialog("theo", Text.get("c9s1-dialog49"), function(){
                        RessourcesController.setRessource('personal-datas');
                    });
                }
                
                this.scene.seventhDialog();
                
                
            },
            null,
            this
        );
    }

    seventhDialog()
    {
        this.dialog("theo", Text.get("c9s1-dialog50"), function(){
            if(MainController.isMobile())
            {
                HelpController.add( Text.get("c9s1-help1")+"<a class='button' target='flirter' href='"+MainController.getFlirterURL()+"/c9s1/"+MainController.getToken()+"'>"+Text.get("c9s1-help1_1")+"</a>" );
            }else
            {
                HelpController.add( Text.get("c9s1-help2")+"<img src='https://api.qrserver.com/v1/create-qr-code/?data="+MainController.getFlirterURL()+"/c9s1/"+MainController.getToken()+"&size=180x180'><br><a target='flirter' href='"+MainController.getFlirterURL()+"/c9s1/"+MainController.getToken()+"'>"+Text.get("c9s1-help2_1")+"</a>" );
            }


           this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                this.removeEventListener(DialogEvent.CLOSE, handler);
                var scene = MainController.getCurrentScene();
                scene.goToScene('chapter9Scene2');
            });
        });

        
       
    }


    beforeTransition()
    {
        
        this.tweens.add({
            targets:  this.musicAudio,
            volume:   0,
            duration: 1000
        });

    } 

    beforeDestroy()
    {
        this.musicAudio.destroy();
    }
              


}
export {Chapter9Scene1}