class SoundController {

	constructor() {
        if (!SoundController.instance) {
            return SoundController.instance;
        }

        SoundController.instance = this;
        

        return this;
    }

	static init()
	{

		this.audioElements = [];


	}

	static play(src, id, loop)
	{

		var audioElement = document.createElement('audio');
		audioElement.setAttribute('src', src);
		audioElement.id = id;

		if(loop)
		{
			audioElement.loop = true;	
		}

		audioElement.play();

		this.audioElements.push(audioElement);
	}

	static stop(id)
	{
		for (var i = 0; i < this.audioElements.length; i++)
		{
			if(this.audioElements[i].id == id)
			{
				this.audioElements[i].pause();
				this.audioElements[i].currentTime = 0;
			}
		}
	}



}
export {SoundController}