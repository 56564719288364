import { DialogController } from "../controller/dialogController";
import { MainController } from "../controller/mainController";
import { RessourcesController } from "../controller/ressourcesController";
import { GameScene } from "../display/gameScene";
import { ParallaxScene } from "../display/parallaxScene";
import { DialogEvent } from "../events/dialogEvent";
import { Text } from "../display/text";

class Chapter7Scene1 extends GameScene {



    constructor() {
        super("chapter7Scene1");
        this.title = Text.get("c7s1-title");
        this.subtitle = Text.get("c7s1-subtitle");
    } 

    preload ()
    {
        super.preload();
        

        this.load.image('c7-s1-background', 'assets/images/chapters/chapter7/scene1/background.jpg');
        this.load.image('c7-s1-firstplan', 'assets/images/chapters/chapter7/scene1/firstplan.png');
        this.load.image('c7-s1-secondplan', 'assets/images/chapters/chapter7/scene1/secondplan.png');

        this.load.audio('ambient-detresse', 'assets/audios/musics/ambiance-detresse.mp3');
        
    }

    create ()
    {
        super.create();

        this.setParallaxDistance( ParallaxScene.DISTANCE_NEAR );

        this.background = this.add.image(0,0, 'c7-s1-background');
        this.secondplan = this.add.image(-600, -200, 'c7-s1-secondplan');
        this.firstplan = this.add.image(500, 300, 'c7-s1-firstplan');


        this.musicAudio = this.sound.add(
                'ambient-detresse', 
                {
                    volume: 0.5,
                    loop: true
                });

        this.musicAudio.play();

        this.addToScene( this.background, 3 );
        this.addToScene( this.secondplan, 2 );
        this.addToScene( this.firstplan, 1 );

        this.timerStart = this.time.delayedCall(5000, function(){
            
            this.firstDialog();

        }, [], this);
    

        MainController.setCurrentScene( this );



    }


    firstDialog()
    {
        this.dialog("weston", Text.get("c7s1-dialog1"));

        this.dialog("wang", Text.get("c7s1-dialog2"));
        this.dialog("weston", Text.get("c7s1-dialog3"));
        this.dialog("wang", Text.get("c7s1-dialog4"));
        this.dialog("theo", Text.get("c7s1-dialog5"));
        this.dialog("wang", Text.get("c7s1-dialog6"));
        this.dialog("weston", Text.get("c7s1-dialog7"));
        this.dialog("wang", Text.get("c7s1-dialog8"));        
        this.dialog("weston", Text.get("c7s1-dialog9"));
        
        this.dialog("weston", Text.get("c7s1-dialog10"), function(){
            RessourcesController.setRessource('phishing');
        });
        this.dialog("theo", Text.get("c7s1-dialog11"));
        this.dialog("weston", Text.get("c7s1-dialog12"));
             
        this.quizUniqueChoice("theo", Text.get("c7s1-dialog13"),
            [
                {
                    "id": "q1-choice1",
                    "label": Text.get("c7s1-dialog14"),
                    "value": 0
                },
                {
                    "id": "q1-choice2",
                    "label": Text.get("c7s1-dialog15"),
                    "value": 1
                },
                {
                    "id": "q1-choice3",
                    "label": Text.get("c7s1-dialog16"),
                    "value": 0
                }
            ],
            function(result){
                DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
                
                if(result[0].value == 1)
                {
                    this.scene.successQuizz();
                }else
                {
                    this.scene.failureQuizz();
                }
            },
            null,
            this
        );
    }

    successQuizz()
    {
        this.dialog("weston", Text.get("c7s1-dialog17"));
        this.secondDialog();
    }

    failureQuizz()
    {
        this.dialog("weston", Text.get("c7s1-dialog18"));
        this.secondDialog();
    }

    secondDialog()
    {

        this.dialog("weston", Text.get("c7s1-dialog19"), function(scene){
            scene.endDialog();
        }, this);
    }

    endDialog()
    {

        this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
            this.removeEventListener(DialogEvent.CLOSE, handler);
            var scene = MainController.getCurrentScene();

            scene.goToScene('chapter7Scene2');
        });
        
    }

    beforeTransition()
    {
        
        this.tweens.add({
            targets:  this.musicAudio,
            volume:   0,
            duration: 1000
        });

    } 

    beforeDestroy()
    {
        this.musicAudio.destroy();
    }

    


}
export {Chapter7Scene1}