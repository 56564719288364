import { DialogController } from "../controller/dialogController";
import { HelpController } from "../controller/helpController";
import { MainController } from "../controller/mainController";
import { RessourcesController } from "../controller/ressourcesController";
import { GameScene } from "../display/gameScene";
import { ParallaxScene } from "../display/parallaxScene";
import { Text } from "../display/text";
import { DialogEvent } from "../events/dialogEvent";

class Chapter1Scene2 extends GameScene {



    constructor() {
        super("chapter1Scene2");
        this.title = Text.get('c1s2-title');
        this.subtitle = Text.get('c1s2-subtitle');
    } 

    preload ()
    {
        super.preload();

        this.load.image('c1-s2-background', 'assets/images/chapters/chapter1/scene2/background.jpg');
        this.load.image('c1-s2-bin', 'assets/images/chapters/chapter1/scene2/bin.png');
        this.load.image('c1-s2-crusher', 'assets/images/chapters/chapter1/scene2/crusher.png');
        this.load.image('c1-s2-desk', 'assets/images/chapters/chapter1/scene2/desk.png');
        this.load.image('c1-s2-etagere1', 'assets/images/chapters/chapter1/scene2/etagere1.png');
        this.load.image('c1-s2-etagere2', 'assets/images/chapters/chapter1/scene2/etagere2.png');
        this.load.image('c1-s2-etagere3', 'assets/images/chapters/chapter1/scene2/etagere3.png');
        this.load.image('c1-s2-paper1', 'assets/images/chapters/chapter1/scene2/paper1.png');
        this.load.image('c1-s2-paper2', 'assets/images/chapters/chapter1/scene2/paper2.png');
        this.load.image('c1-s2-paper3', 'assets/images/chapters/chapter1/scene2/paper3.png');
        this.load.image('c1-s2-paper4', 'assets/images/chapters/chapter1/scene2/paper4.png');
        this.load.image('c1-s2-desk-protected', 'assets/images/chapters/chapter1/scene2/desk-protected.png');
        this.load.image('c1-s2-etagere1-protected', 'assets/images/chapters/chapter1/scene2/etagere1-protected.png');
        this.load.image('c1-s2-etagere2-protected', 'assets/images/chapters/chapter1/scene2/etagere2-protected.png');
        this.load.image('c1-s2-etagere3-protected', 'assets/images/chapters/chapter1/scene2/etagere3-protected.png');        

        this.load.audio('ambient-detective', 'assets/audios/musics/ambiance-detective.mp3');
        this.load.audio('paper-sound', 'assets/audios/paper.mp3');
        this.load.audio('etagere-sound', 'assets/audios/etagere.mp3');
        this.load.audio('desk-sound', 'assets/audios/desk.mp3');
        this.load.audio('bin-sound', 'assets/audios/bin.mp3');
    }

    create ()
    {
        super.create();


        this.setParallaxDistance( ParallaxScene.DISTANCE_IMMEDIATE );

        this.background = this.add.image(0,0, 'c1-s2-background');

        this.etagere1 = this.add.image(235, -350, 'c1-s2-etagere1');
        this.etagere1Protected = this.add.image(235, -350, 'c1-s2-etagere1-protected');
        this.etagere1Protected.alpha = 0;
        

        this.etagere3 = this.add.image(535, -67, 'c1-s2-etagere3');
        this.etagere3Protected = this.add.image(535, -67, 'c1-s2-etagere3-protected');
        this.etagere3Protected.alpha = 0;
        

        this.etagere2 = this.add.image(1057, -260, 'c1-s2-etagere2');
        this.etagere2Protected = this.add.image(1057, -260, 'c1-s2-etagere2-protected');
        this.etagere2Protected.alpha = 0;
        

        this.desk = this.add.image(-150,230, 'c1-s2-desk');
        this.deskProtected = this.add.image(-373,245, 'c1-s2-desk-protected');
        this.deskProtected.alpha = 0;
        
        this.bin = this.add.image(600, 220, 'c1-s2-bin'); 
        this.crusher = this.add.image(600, 220, 'c1-s2-crusher');
        this.crusher.alpha = 0;

        this.paper1 = this.add.image(240,-10, 'c1-s2-paper1');
        this.paper2 = this.add.image(638,-135, 'c1-s2-paper2');
        this.paper3 = this.add.image(120,-270, 'c1-s2-paper3');
        this.paper4 = this.add.image(-280, -160, 'c1-s2-paper4');


        this.musicAudio = this.sound.add(
            'ambient-detective', 
            {
                volume: 0.5,
                loop: true
            });
        this.musicAudio.play();

        this.paperAudio = this.sound.add('paper-sound');
        this.etagereAudio = this.sound.add('etagere-sound');
        this.deskAudio = this.sound.add('desk-sound');
        this.binAudio = this.sound.add('bin-sound');
        

        this.addToScene( this.background, 3 );
        this.addToScene( this.etagere1, 3 );
        this.addToScene( this.etagere1Protected, 3 );
        this.addToScene( this.etagere3, 3 );
        this.addToScene( this.etagere3Protected, 3 );
        this.addToScene( this.etagere2, 3 );
        this.addToScene( this.etagere2Protected, 3 );
        this.addToScene( this.bin, 3 );
        this.addToScene( this.crusher, 3 );
        
        
        this.addToScene( this.desk, 2 );
        this.addToScene( this.deskProtected, 2 );
        
        this.addToScene( this.paper1, 2 );
        this.addToScene( this.paper2, 3 );
        this.addToScene( this.paper3, 2 );
        this.addToScene( this.paper4, 2 );
        
        this.timerStart = this.time.delayedCall(5000, function(){
                
            this.firstDialogSequence();

            
        }, [], this);
        

        MainController.setCurrentScene( this );

    }


    firstDialogSequence()
    {
        this.dialog("theo", Text.get("c1s2-dialog1"), function(scene){
            scene.activePapers();

            scene.timerHelpPapers = scene.time.delayedCall(30000, function(){
                HelpController.add( Text.get("c1s2-help1") );
            }, [], scene);
            
            
            scene.timerHelpPapers2 = scene.time.delayedCall(60000, function(){
                HelpController.add( Text.get("c1s2-help2-1")+scene.papersHidden+Text.get("c1s2-help2-2") );
            }, [], scene);

        }, this);
    }

    secondDialogSequence()
    {
        this.cleanDialog();

        if(this.timerHelpPapers != null)
        {
            this.timerHelpPapers.remove();    
        }

        if(this.timerHelpPapers2 != null)
        {
            this.timerHelpPapers2.remove();    
        }
        
        HelpController.clear();

        this.dialog("theo", Text.get("c1s2-dialog2"), function(scene){
            RessourcesController.setRessource('secure-workspace');


            scene.timerHelpRessources = scene.time.delayedCall(5000, function(){
                HelpController.add( Text.get("c1s2-help3") );
            }, [], scene);

            scene.timerHelpBin = scene.time.delayedCall(60000, function(){
                HelpController.add( Text.get("c1s2-help4") );
            }, [], scene);

            scene.activeBin();
        }, this);
    }

    thirdDialogSequence()
    {
        this.cleanDialog();

        if(this.timerHelpRessources != null)
        {
            this.timerHelpRessources.remove();
        }

        if(this.timerHelpBin != null)
        {
            this.timerHelpBin.remove();
        }

        HelpController.clear();

        this.dialog("theo", Text.get("c1s2-dialog3"));
        this.dialog("theo", Text.get("c1s2-dialog4"), function(scene){
            scene.activeEtageres();

            scene.timerHelpEtageres = scene.time.delayedCall(30000, function(){
                HelpController.add( Text.get("c1s2-help5") );
            }, [], scene);

            scene.timerHelpEtageres2 = scene.time.delayedCall(60000, function(){
                HelpController.add( Text.get("c1s2-help6-1")+scene.etageresProtected+Text.get("c1s2-help6-2") );
            }, [], scene);

        }, this);
    }

    fourthDialogSequence()
    {
        if(this.timerHelpEtageres != null)
        {
            this.timerHelpEtageres.remove();
        }

        if(this.timerHelpEtageres2 != null)
        {
            this.timerHelpEtageres2.remove();
        }
        
        HelpController.clear();

        this.cleanDialog();
        this.dialog("theo", Text.get("c1s2-dialog5"));
        this.dialog("theo", Text.get("c1s2-dialog6"), function(scene){
            scene.activeDesk();

            scene.timerHelpDesk = scene.time.delayedCall(30000, function(){
                HelpController.add( Text.get("c1s2-help7") );
            }, [], scene);

        }, this);
    }

    fifthDialogSequence()
    {
        MainController.completeChapter('chapter1');

        
        if(this.timerHelpDesk != null)
        {
            this.timerHelpDesk.remove();
        }
        
        HelpController.clear();

        this.cleanDialog();

        this.timerEnd = this.time.delayedCall(3000, function(){

            this.dialog("theo", Text.get("c1s2-dialog7"));
            this.dialog("theo", Text.get("c1s2-dialog8"), function(scene){
                scene.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                    this.removeEventListener(DialogEvent.CLOSE, handler);
                    var scene = MainController.getCurrentScene();
                    
                    scene.gotoChapter2();
                });
            }, this);

        }, [], this);

            
    }

    gotoChapter2()
    {
        
        this.goToScene('chapter2Intro', 2000);
    }

    activeBin()
    {
        this.bin.setInteractive();
        this.bin.once('pointerdown', function(){
               
               this.crusher.alpha = 1;
               
               this.crusherTween = this.tweens.add({
                    targets: this.crusher,
                    y: {from: -420, to: 220},
                    ease: 'Bounce',
                    duration: 500
                });

               this.thirdDialogSequence();

               this.binAudio.play();

        }, this);
    }

    activeDesk()
    {

        this.desk.setInteractive();
        this.desk.once('pointerdown', function(){
               
            this.binTween = this.tweens.add({
                targets: [this.bin, this.crusher],
                alpha: 0,
                ease: 'Cubic',
                duration: 500
            });

            this.deskTimeline = this.tweens.timeline({
                tweens: [
                    {
                        targets: this.desk,
                        alpha: 0,
                        scaleX: 1.2,
                        scaleY: 1.2,
                        ease: 'Cubic',
                        duration: 500
                    },
                    {
                        targets: this.deskProtected,
                        alpha: 1,
                        scaleX: {from: 1.2, to: 1},
                        scaleY: {from: 1.2, to: 1},
                        ease: 'Cubic',
                        duration: 500,
                        offset: '-=200'
                    }
                    ]
                });

            this.deskAudio.play();
            this.fifthDialogSequence();

        }, this);
    }

    hidePaper()
    {
        this.papersHidden++;
        this.checkPapers();
        this.paperAudio.play();
    }

    checkPapers()
    {

        if(this.papersHidden >= 4)
        {
            this.secondDialogSequence();
        }
    }

    activePapers()
    {
        this.papersHidden = 0;

        this.paper1.setInteractive();
        this.paper1.once('pointerdown', function(){
               
               this.paper1Tween = this.tweens.add({
                    targets: this.paper1,
                    alpha: 0,
                    scaleX: 1.2,
                    scaleY: 1.2,
                    ease: 'Cubic',
                    duration: 500
                });

               this.hidePaper();

        }, this);

        this.paper2.setInteractive();
        this.paper2.once('pointerdown', function(){
               
               this.paper2Tween = this.tweens.add({
                    targets: this.paper2,
                    alpha: 0,
                    scaleX: 1.2,
                    scaleY: 1.2,
                    ease: 'Cubic',
                    duration: 500
                });

               this.hidePaper();

        }, this);

        this.paper3.setInteractive();
        this.paper3.once('pointerdown', function(){
               
               this.paper3Tween = this.tweens.add({
                    targets: this.paper3,
                    alpha: 0,
                    scaleX: 1.2,
                    scaleY: 1.2,
                    ease: 'Cubic',
                    duration: 500
                });

               this.hidePaper();

        }, this);

        this.paper4.setInteractive();
        this.paper4.once('pointerdown', function(){
               
               this.paper4Tween = this.tweens.add({
                    targets: this.paper4,
                    alpha: 0,
                    scaleX: 1.2,
                    scaleY: 1.2,
                    ease: 'Cubic',
                    duration: 500
                });

               this.hidePaper();

        }, this);
    }


    protectEtagere()
    {
        this.etageresProtected++;
        this.checkEtageres();
        this.etagereAudio.play();
    }

    checkEtageres()
    {

        if(this.etageresProtected >= 3)
        {
            this.fourthDialogSequence();
        }
    }    

    activeEtageres()
    {
        this.etageresProtected = 0;

        this.etagere1.setInteractive();
        this.etagere1.once('pointerdown', function(){
               
               this.etagere1Protected.alpha = 1;
               
               this.etagere1Tween = this.tweens.add({
                    targets: this.etagere1Protected,
                    y: {from: -550, to: -350},
                    ease: 'Bounce',
                    duration: 500
                });

               this.protectEtagere();

        }, this);


        this.etagere2.setInteractive();
        this.etagere2.once('pointerdown', function(){
               
               this.etagere2Protected.alpha = 1;
               
               this.etagere2Tween = this.tweens.add({
                    targets: this.etagere2Protected,
                    y: {from: -460, to: -260},
                    ease: 'Bounce',
                    duration: 500
                });

               this.protectEtagere();

        }, this);


        this.etagere3.setInteractive();
        this.etagere3.once('pointerdown', function(){
               
               this.etagere3Protected.alpha = 1;
               
               this.etagere3Tween = this.tweens.add({
                    targets: this.etagere3Protected,
                    y: {from: -267, to: -67},
                    ease: 'Bounce',
                    duration: 500
                });

               this.protectEtagere();

        }, this);
    }

    beforeTransition()
    {

        this.tweens.add({
            targets:  this.musicAudio,
            volume:   0,
            duration: 1000
        });
    }

    beforeDestroy()
    {
        this.musicAudio.destroy();
    }

}
export {Chapter1Scene2}