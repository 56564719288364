class RessourcesEvent {
	static get LOADING_COMPLETE(){
		return "ressources_loading_complete";
	}

	static get PREPARING_COMPLETE(){
		return "ressources_preparing_complete";
	}

	static get CHANGE(){
		return "ressources_change";
	}
}
export {RessourcesEvent}