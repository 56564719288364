import { DialogController } from "../controller/dialogController";
import { MainController } from "../controller/mainController";
import { RessourcesController } from "../controller/ressourcesController";
import { GameScene } from "../display/gameScene";
import { ParallaxScene } from "../display/parallaxScene";
import { DialogEvent } from "../events/dialogEvent";
import { Text } from "../display/text";

class Chapter2Scene2 extends GameScene {



    constructor() {
        super("chapter2Scene2");
        this.title = Text.get("c2s2-title");
        this.subtitle = Text.get("c2s2-subtitle");
    } 

    preload ()
    {
        super.preload();
        this.load.image('c2-s2-background', 'assets/images/chapters/chapter2/scene2/background.jpg');
        this.load.image('c2-s2-firstplan', 'assets/images/chapters/chapter2/scene2/firstplan.png');
        this.load.image('c2-s2-secondplan', 'assets/images/chapters/chapter2/scene2/secondplan.png');
        
        this.load.audio('ambient-detente', 'assets/audios/musics/ambiance-detente.mp3');
    }

    create ()
    {
        super.create();

        this.setParallaxDistance( ParallaxScene.DISTANCE_NEAR );

        this.background = this.add.image(0,0, 'c2-s2-background');
        this.secondplan = this.add.image(-420,320, 'c2-s2-secondplan');
        this.firstplan = this.add.image(-200, 400, 'c2-s2-firstplan');


        

        this.musicAudio = this.sound.add(
                'ambient-detente', 
                {
                    volume: 0.5,
                    loop: true
                });

        

        this.musicAudio.play();



        this.addToScene( this.background, 3 );
        this.addToScene( this.secondplan, 2 );
        this.addToScene( this.firstplan, 1 );
        
        this.timerStart = this.time.delayedCall(5000, function(){
            this.firstDialog();
            
        }, [], this);

        
    
        MainController.setCurrentScene( this );


    }

    firstDialog()
    {
        this.dialog("emily", Text.get("c2s2-dialog1"));
        this.dialog("weston", Text.get("c2s2-dialog2"), function(scene){
            RessourcesController.setRessource('secure-office');
            scene.firstQuizz();
        }, this);
        
    }

    firstQuizz()
    {
        
        DialogController.DisplayNextButton(); // je force le bouton à apparaitre car la pile est vide
        

        this.quizUniqueChoice("theo", Text.get("c2s2-dialog3"),
            [
                {
                    "id": "q1-choice1",
                    "label": Text.get("c2s2-dialog4"),
                    "value": 1
                },
                {
                    "id": "q1-choice2",
                    "label": Text.get("c2s2-dialog5"),
                    "value": 0
                },
                {
                    "id": "q1-choice3",
                    "label": Text.get("c2s2-dialog6"),
                    "value": 0
                }
            ],
            function(result){
                DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
        
                if(result[0].value == 1)
                {
                    this.scene.SuccessFirstQuizz();
                }else
                {
                    this.scene.FailureFirstQuizz();
                }
            },
            null,
            this
        );


    }

    SuccessFirstQuizz()
    {
        this.dialog("weston", Text.get("c2s2-dialog7"));
        this.SecondQuizz();
    }

    FailureFirstQuizz()
    {
        this.dialog("weston", Text.get("c2s2-dialog8"));
        this.SecondQuizz();
    }

    SecondQuizz()
    {
        
        DialogController.DisplayNextButton(); // je force le bouton à apparaitre car la pile est vide
        

        
        this.quizMultipleChoice("theo", Text.get("c2s2-dialog9"),
            [
                {
                    "id": "q2-choiceBad1",
                    "label": Text.get("c2s2-dialog10"),
                    "value": 0
                },{
                    "id": "q2-choice1",
                    "label": Text.get("c2s2-dialog11"),
                    "value": 1
                },
                {
                    "id": "q2-choice2",
                    "label": Text.get("c2s2-dialog12"),
                    "value": 1
                },
                {
                    "id": "q2-choiceBad2",
                    "label": Text.get("c2s2-dialog13"),
                    "value": 0
                },
                {
                    "id": "q2-choice3",
                    "label": Text.get("c2s2-dialog14"),
                    "value": 1
                },
                {
                    "id": "q2-choice4",
                    "label": Text.get("c2s2-dialog15"),
                    "value": 1
                },
                {
                    "id": "q2-choiceBad3",
                    "label": Text.get("c2s2-dialog16"),
                    "value": 0
                }
            ],
            function(result){
                DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
        

                var success = true;
                var total = 0;
                for (var i = 0; i < result.length; i++) 
                {
                    if(result[i].value == 0)
                    {
                        success = false;
                        break;
                    }else
                    {
                        total++;
                    }
                }

                if(total != 4)
                {
                    success = false;
                }

                if(success)
                {
                    this.scene.SuccessSecondQuizz();
                }else
                {
                    this.scene.FailureSecondQuizz();
                }
            },
            null,
            this
        );


    }

    SuccessSecondQuizz()
    {
        this.dialog("weston", Text.get("c2s2-dialog17"));
        this.secondDialog();
    }

    FailureSecondQuizz()
    {
        this.dialog("weston", Text.get("c2s2-dialog18"));
        this.secondDialog();
    }

    secondDialog()
    {
        this.dialog("emily", Text.get("c2s2-dialog19"));
        this.dialog("weston", Text.get("c2s2-dialog20"));
        this.dialog("emily", Text.get("c2s2-dialog21"));
        this.dialog("weston", Text.get("c2s2-dialog22"));
        this.dialog("emily", Text.get("c2s2-dialog23"));
        this.dialog("weston", Text.get("c2s2-dialog24"));
        this.quizUniqueChoice("theo", Text.get("c2s2-dialog25"),
            [
                {
                    "id": "q3-choice1",
                    "label": Text.get("c2s2-dialog26"),
                    "value": 1
                },
                {
                    "id": "q3-choice2",
                    "label": Text.get("c2s2-dialog27"),
                    "value": 0
                }
            ],
            function(result){
                DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
        
                if(result[0].value == 1)
                {
                    this.scene.SuccessThirdQuizz();
                }else
                {
                    this.scene.FailureThirdQuizz();
                }
            },
            null,
            this
        );
    }

    SuccessThirdQuizz()
    {
        this.thirdDialog();
    }

    FailureThirdQuizz()
    {
        this.dialog("weston", Text.get("c2s2-dialog28"));
        this.thirdDialog();
    }

    thirdDialog()
    {
        this.dialog("emily", Text.get("c2s2-dialog29"), function(scene){
            
            MainController.completeChapter('chapter2');
            
            scene.endDialog();
        }, this);
    }

    endDialog()
    {
        
        this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
            this.removeEventListener(DialogEvent.CLOSE, handler);
            var scene = MainController.getCurrentScene();
            scene.gotoChapter3Intro();
        });
    }

    gotoChapter3Intro()
    {

        this.goToScene('chapter3Intro', 2000);
    }

    beforeTransition()
    {
        
        this.tweens.add({
            targets:  this.musicAudio,
            volume:   0,
            duration: 1000
        });

    }

    beforeDestroy()
    {
        this.musicAudio.destroy();
    }

}
export {Chapter2Scene2}