import { MainController } from "../controller/mainController";
import { RootScene } from "../display/rootScene";

class Loader extends RootScene {



    constructor() {
        super("loader");
    } 

    preload ()
    {
        super.preload(true);

        this.load.image('shield', 'assets/images/shield.png');
        this.load.image('hat', 'assets/images/white-hat.png');
        this.load.image('title1', 'assets/images/title-chap1.png');
        this.load.bitmapFont('mexcellent', 'assets/fonts/mexcellent.png', 'assets/fonts/mexcellent.xml');
        this.load.bitmapFont('roboto-condensed-bi', 'assets/fonts/roboto-condensed-bi.png', 'assets/fonts/roboto-condensed-bi.xml'); 
        this.load.bitmapFont('roboto-condensed-b', 'assets/fonts/roboto-condensed-b.png', 'assets/fonts/roboto-condensed-b.xml');

    }

    create ()
    {
        
        this.scene.start( MainController.getStartSceneName() );
                
    }

}

export {Loader}