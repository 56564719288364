import { HelpController } from "../controller/helpController";
import { MainController } from "../controller/mainController";
import { GameScene } from "../display/gameScene";
import { ParallaxScene } from "../display/parallaxScene";
import { Text } from "../display/text";

class Chapter3Scene1 extends GameScene {



    constructor() {
        super("chapter3Scene1");
        this.title = Text.get("c3s1-title");
        this.subtitle = Text.get("c3s1-subtitle");
    } 

    preload ()
    {
        super.preload();

        this.load.image('c3-s1-background', 'assets/images/chapters/chapter3/scene1/background.jpg');
        this.load.image('c3-s1-firstplan', 'assets/images/chapters/chapter3/scene1/firstplan.png');
        this.load.image('c3-s1-secondplan', 'assets/images/chapters/chapter3/scene1/secondplan.png');
        
        this.load.audio('ambient-stress', 'assets/audios/musics/ambiance-stress.mp3');
        
    }

    create ()
    {
        super.create();

        this.setParallaxDistance( ParallaxScene.DISTANCE_NEAR );

        this.background = this.add.image(0,0, 'c3-s1-background');
        this.secondplan = this.add.image(0,150, 'c3-s1-secondplan');
        this.firstplan = this.add.image(-980, 250, 'c3-s1-firstplan');


        this.computerArea = this.add.rectangle(735, 380, 250, 180, 0xff0000, 0);
        


        this.musicAudio = this.sound.add(
                'ambient-stress', 
                {
                    volume: 0.5,
                    loop: true
                });

        

        this.musicAudio.play();



        this.addToScene( this.background, 3 );
        this.addToScene( this.secondplan, 2 );
        this.addToScene( this.computerArea, 2 );
        this.addToScene( this.firstplan, 1 );
        
        this.timerStart = this.time.delayedCall(5000, function(){
            this.firstDialog();
            
        }, [], this);

        this.computerArea.once('pointerdown', function(){
            this.endScene();
        }, this);

        

        MainController.setCurrentScene( this );


    }



    firstDialog()
    {
        this.dialog("weston", Text.get("c3s1-dialog1"));
        this.dialog("wang", Text.get("c3s1-dialog2"));
        this.dialog("weston", Text.get("c3s1-dialog3"));
        this.dialog("wang", Text.get("c3s1-dialog4"));
        this.dialog("weston", Text.get("c3s1-dialog5"));
        this.dialog("wang", Text.get("c3s1-dialog6"), function(scene){
            scene.computerArea.setInteractive();
            scene.timerHelp = scene.time.delayedCall(10000, function(){
                HelpController.add( Text.get("c3s1-help1") );
            }, [], scene);
        }, this);
        
    }

    endScene()
    {
        this.goToScene('chapter3Scene2');

    }

    beforeTransition()
    {
        
        this.tweens.add({
            targets:  this.musicAudio,
            volume:   0,
            duration: 1000
        });

    }
    
    beforeDestroy()
    {
        this.musicAudio.destroy();
    }

}
export {Chapter3Scene1}