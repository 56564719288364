import { DialogController } from "../controller/dialogController";
import { MainController } from "../controller/mainController";
import { GameScene } from "../display/gameScene";
import { ParallaxScene } from "../display/parallaxScene";
import { DialogEvent } from "../events/dialogEvent";
import { Text } from "../display/text";

class Chapter2Scene1 extends GameScene {



    constructor() {
        super("chapter2Scene1");
        this.title = Text.get("c2s1-title");
        this.subtitle = Text.get("c2s1-subtitle");
    } 

    preload ()
    {
        
        super.preload();
        this.load.image('c2-s1-background', 'assets/images/chapters/chapter2/scene1/background.jpg');
        this.load.image('c2-s1-norbert', 'assets/images/chapters/chapter2/scene1/norbert.png');
        this.load.image('c2-s1-desk', 'assets/images/chapters/chapter2/scene1/desk.png');
        
        this.load.audio('ambient-secret', 'assets/audios/musics/ambiance-secret.mp3');
    }

    create ()
    {
        super.create();


        this.setParallaxDistance( ParallaxScene.DISTANCE_NEAR );

        this.background = this.add.image(0,0, 'c2-s1-background');
        this.desk = this.add.image(290,210, 'c2-s1-desk');
        this.norbert = this.add.image(-890, 240, 'c2-s1-norbert');


        

        this.musicAudio = this.sound.add(
                'ambient-secret', 
                {
                    volume: 0.5,
                    loop: true
                });

        

        this.musicAudio.play();



        this.addToScene( this.background, 3 );
        this.addToScene( this.desk, 2 );
        this.addToScene( this.norbert, 1 );
        
        this.timerStart = this.time.delayedCall(5000, function(){
            //HelpController.add( Text.get('chapter1-help-phone') );
            this.firstDialog();
            
        }, [], this);

        
    
        MainController.setCurrentScene( this );


    }

    firstDialog()
    {
        this.dialog("emily", Text.get("c2s1-dialog1"));
        this.dialog("weston", Text.get("c2s1-dialog2"));
        this.dialog("emily", Text.get("c2s1-dialog3"));
        this.dialog("weston", Text.get("c2s1-dialog4"));
        this.dialog("emily", Text.get("c2s1-dialog5"));
        this.dialog("weston", Text.get("c2s1-dialog6"));
        this.dialog("emily", Text.get("c2s1-dialog7"));
        
        this.dialog("weston", Text.get("c2s1-dialog8"), function(scene){
             //RessourcesController.setRessource('vpn');
            scene.firstQuizz();
        }, this);
        
    }

    firstQuizz()
    {
        
        DialogController.DisplayNextButton(); // je force le bouton à apparaitre car la pile est vide
        

        this.quizUniqueChoice("theo", Text.get("c2s1-dialog9"),
            [
                {
                    "id": "q1-choice1",
                    "label": Text.get("c2s1-dialog10"),
                    "value": 0
                },
                {
                    "id": "q1-choice2",
                    "label": Text.get("c2s1-dialog11"),
                    "value": 1
                }
            ],
            function(result){
                DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
        
                if(result[0].value == 1)
                {
                    this.scene.SuccessFirstQuizz();
                }else
                {
                    this.scene.FailureFirstQuizz();
                }
            },
            null,
            this
        );


    }

    SuccessFirstQuizz()
    {
        this.dialog("weston", Text.get("c2s1-dialog12"));
        this.SecondQuizz();
    }

    FailureFirstQuizz()
    {
        this.dialog("weston", Text.get("c2s1-dialog13"));
        this.SecondQuizz();
    }

    SecondQuizz()
    {
        
        DialogController.DisplayNextButton(); // je force le bouton à apparaitre car la pile est vide
        

        this.quizUniqueChoice("theo", Text.get("c2s1-dialog14"),
            [
                {
                    "id": "q2-choice1",
                    "label": Text.get("c2s1-dialog15"),
                    "value": 0
                },
                {
                    "id": "q2-choice2",
                    "label": Text.get("c2s1-dialog16"),
                    "value": 1
                },
                {
                    "id": "q2-choice3",
                    "label": Text.get("c2s1-dialog17"),
                    "value": 0
                }
            ],
            function(result){
                DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
        
                if(result[0].value == 1)
                {
                    this.scene.SuccessSecondQuizz();
                }else
                {
                    this.scene.FailureSecondQuizz();
                }
            },
            null,
            this
        );


    }

    SuccessSecondQuizz()
    {
        this.dialog("weston", Text.get("c2s1-dialog18"));
        this.secondDialog();
    }

    FailureSecondQuizz()
    {
        this.dialog("weston", Text.get("c2s1-dialog19"));
        this.secondDialog();
    }

    secondDialog()
    {
        this.dialog("emily", Text.get("c2s1-dialog20"));
        this.dialog("weston", Text.get("c2s1-dialog21"));
        this.dialog("emily", Text.get("c2s1-dialog22"), function(scene){
            scene.endDialog();
        }, this);
    }

    endDialog()
    {
        
        this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
            this.removeEventListener(DialogEvent.CLOSE, handler);
            var scene = MainController.getCurrentScene();
            
            scene.gotoChapter2Scene2();
        });
    }

    gotoChapter2Scene2()
    {
        this.goToScene('chapter2Scene2');
    }
/*
    AllQuizz()
    {

        this.quizFillIn("theo", "Vous connaissez bien la Géographie ?<br>Remplissez le texte à trous.",
        "Paris est la capitale de la <input name='q0-fill1'>. Ce beau pays dont le plus long fleuve est la <input name='q0-fill2'>.<br>Il faut absolument visiter <input name='q0-fill3'> la ville Design !",
            function(result){
                console.log(result);
            }
        );

        this.quizUniqueChoice("theo", "Combien font 7x7 ?<br>Sélectionnez la bonne réponse.",
            [
                {
                    "id": "q1-choice1",
                    "label": "Ça fait 10",
                    "value": 10
                },
                {
                    "id": "q1-choice2",
                    "label": "Ça fait 20",
                    "value": 20
                },
                {
                    "id": "q1-choice3",
                    "label": "Ça fait 49",
                    "value": 49
                }
            ],
            function(result){
                console.log(result);
            }
        );

        
     
        this.quizMultipleChoice("theo", "Quels sont les chiffres pairs ?<br>Sélectionnez les bonnes réponses.",
            [
                {
                    "id": "q2-choice1",
                    "label": "10 est un chiffre pair az az az az lazdk azld alzdk azld azldk alzdk alzdk alzdk azldk azdlka zdlakz dlazkd alzdk azldk azldk azzadl",
                    "value": 10
                },
                {
                    "id": "q2-choice2",
                    "label": "20 est un chiffre pair",
                    "value": 20
                },
                {
                    "id": "q2-choice3",
                    "label": "49 est un chiffre pair",
                    "value": 49
                }
            ],
            function(result){
                console.log(result);
            }
        );
        

        this.quizSortableChoice("theo", "Qui est le plus fort ?<br>Classez ces animaux du plus fort au moins fort.",
            [
                {
                    "id": "q3-choice0",
                    "label": "dino"
                },
                {
                    "id": "q3-choice1",
                    "label": "l'éléphant"
                },
                {
                    "id": "q3-choice2",
                    "label": "le singe"
                },
                {
                    "id": "q3-choice3",
                    "label": "la souris"
                },
                {
                    "id": "q3-choice4",
                    "label": "l'ours"
                },
                {
                    "id": "q3-choice5",
                    "label": "le chien"
                }
            ],
            function(result){
                console.log(result);
            }
        );
        
    }

*/

    beforeTransition()
    {
        
        
        this.tweens.add({
            targets:  this.musicAudio,
            volume:   0,
            duration: 1000
        });
        
    }

    beforeDestroy()
    {
        this.musicAudio.destroy();
    }


}
export {Chapter2Scene1}