class InterfaceEvent {
	static get CLIENT_SPACE_OPENED(){
		return "interface_event_client_space_opened";
	}
	static get CYBER_COMPETENCES_OPENED(){
		return "interface_event_cyber_competence_opened";
	}
	static get START_QUIZ_OPENED(){
		return "interface_event_start_quiz_opened";
	}
	static get END_QUIZ_OPENED(){
		return "interface_event_end_quiz_opened";
	}
}
export {InterfaceEvent}