import axios from 'axios';
import cookieService from './cookieService';
import eventDispatcher from '../eventDispatcher'
import eventConfig from '@/configs/eventConfig';

export default class apiService {


    static get (url, useUserToken=false) {
        var params = {};
        if(useUserToken)
        {
            params.headers = {
                'Authorization': 'Bearer ' +cookieService.getToken(),
                "Content-Type": "application/json",
                };
        }

        
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL+url, params)
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                console.log(error);
                //reject(error);
                
                
                if(error.response.status == 401)
                {
                    eventDispatcher.emit(eventConfig.UNAUTHORIZED);
                }else
                {
                    reject(error);
                }
                
            });
         });
       
    }

    

    static post (url, data, useUserToken=false) {

        var params = {};
        if(useUserToken)
        {
            params.headers = {
                'Authorization': 'Bearer ' +cookieService.getToken(),
                "Content-Type": "application/json",
                };
        }
        
        return new Promise((resolve, reject) => {
           
            axios.post(process.env.VUE_APP_API_URL+url, data, params)
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                console.log(error);
                reject(error);
                
            });
         });
       
    }

}
