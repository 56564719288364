import { RessourcesEvent } from "../events/ressourcesEvent";
import { MainController } from "./mainController";
import { SoundController } from "./soundController";
import $ from "jquery";
import gsap from "gsap";
import { PlayerController } from "./playerController";

class RessourcesController {

	constructor() {
        if (!RessourcesController.instance) {
            return RessourcesController.instance;
        }

        RessourcesController.instance = this;
        
		this.opened = false;
		this.data = {};
		this.bumpTween = null;

        return this;
    }

	static init()
	{

		MainController.stopPropagation();

		$("#interface nav .ressources").on("click", function(e){
			RessourcesController.toggle();
			SoundController.play('assets/audios/click-ressource.mp3');
			e.stopPropagation();
		});

		$("#ressources a.close").on("click", function(e){
			RessourcesController.close();
			SoundController.play('assets/audios/click-ressource.mp3');
			e.stopPropagation();
		});

		

		

	}

	static load(file)
	{
		$.getJSON( file, function( data ) {
			RessourcesController.setData(data);
			RessourcesController.prepareDocument();
			window.dispatchEvent(new CustomEvent(RessourcesEvent.LOADING_COMPLETE));
		});
		
	}

	static prepareDocument()
	{

		PlayerController.getMe().then(me => 
		{
			var premiumParams = "";
			if(me.activeKey)
			{
				premiumParams = "?mode=premium"
			}

			var list = '';
			var active = '';
	
			for (var ressource of this.data)
			{
				list += "<li name="+ressource.name+" class="+active+"><a href="+ressource.url+premiumParams+" target='ressources'>"+ressource.title+"</a></li>";
			}
			$("#ressources .index .list").html(list);
	
			$("#ressources .index li a").unbind("click");
			$("#ressources .index li a").on("click", function(e){
				$("#ressources .index li").removeClass('active');
				$(this).parent().addClass("active");
	
				e.stopPropagation();
			});
			
	
			window.dispatchEvent(new CustomEvent(RessourcesEvent.PREPARING_COMPLETE));

		}
		)
		.catch(error => {
		console.log(error)
				
			}
		);

		
	}

	static setData(data)
	{
		this.data = data;
	}


	static toggle()
	{
		if(this.opened)
		{
			RessourcesController.close();
		}else
		{
			RessourcesController.open();
		}
	}

	static setRessource(name)
	{
		$("#interface nav a.ressources").addClass("active");

		if(this.bumpTween == null)
		{
			this.bumpTween = gsap.to($("#interface nav a.ressources"), {duration: 0.5, marginBottom: 20, repeat: -1, yoyo: true});
		}
		

		if(name)
		{
			$("#ressources .index li").removeClass('active');
			var li = $("#ressources .index li[name="+name+"]");
			$(li).addClass('active');	


			$("#ressources .body iframe.content").attr('src', $(li).find('a').attr('href'));
		}
		

	}

	static open(name)
	{
		if(this.bumpTween != null)
		{
			this.bumpTween.kill();
			$("#interface nav a.ressources").css("margin-bottom", "0px");
			this.bumpTween = null;
		}
		

		if(name)
		{
			this.setRessource(name);
		}

		$("#ressources").hide().fadeIn(500).addClass("active");


		this.opened = true;
	}

	static close()
	{
		$("#ressources").removeClass("active").fadeOut(500);

		this.opened = false;
	}

	


}
export {RessourcesController}