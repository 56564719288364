import { MainController } from "./mainController";
import { SoundController } from "./soundController";

import $ from "jquery";
import {gsap} from "gsap";
import { DialogEvent } from "../events/dialogEvent";
import jsTypeText from 'js-type-text';

class DialogController {


	constructor() {
        if (!DialogController.instance) {
            return DialogController.instance;
        }

        DialogController.instance = this;
        DialogController.offsetFix = true;
        DialogController.forceDisplayNext = false;

        this.bumpTween = null;
        


        return this;
    }

	static init()
	{

		this.messagesStack = [];
		this.opened = false;

		MainController.stopPropagation();

		$("#interface nav .dialog").on("click", function(e){
			DialogController.toggle();
			SoundController.play('assets/audios/click-dialog.mp3');
			e.stopPropagation();
		});

		$("#dialog .messages .dialog-next").on("click", function(e){
			
			DialogController.next();
			SoundController.play('assets/audios/click-dialog.mp3');
			e.stopPropagation();
		});

		$("#dialog .messages .dialog-close").on("click", function(e){
		
			DialogController.close();
			SoundController.play('assets/audios/click-dialog.mp3');
			e.stopPropagation();
		});
	
		this.nextTween = gsap.to($("#dialog .messages .dialog-next"), {duration: 1, marginTop: 40, repeat: -1, yoyo: true});
		this.nextTween.pause();

		this.cleanNextActiveFirstSound = false;
		this.cleanNextActive = false;

		this.callbackNext = null;
		this.callbackNextScene = null;

	}

	static isStackEmpty()
	{
		if(this.messagesStack.length > 0)
		{
			return false;
		}

		return true;
	}




	static add(message, avatar, name, title, side, callback, scene, isQuiz, callbackQuiz)
	{

		var classQuiz = '';
		if(isQuiz)
		{
			classQuiz = 'quiz';
		}

		

		var htmlMessage = "<div class='message "+side+" "+classQuiz+"'><div class='identity'><div class='avatar "+avatar+"'></div><div class='name'>"+name+"</div><div class='title'>"+title+"</div></div><div class='expression'><div class='quote quote-start'></div><div class='text'>"+message+"</div><div class='quote quote-end'></div></div></div>";

		if(side == "right")
		{
			htmlMessage = "<div class='message "+side+"'><div class='expression'><div class='quote quote-start'></div><div class='text'>"+message+"</div><div class='quote quote-end'></div></div><div class='identity'><div class='avatar "+avatar+"'></div><div class='name'>"+name+"</div><div class='title'>"+title+"</div></div></div>";
		}


		
		var msg = {
			htmlMessage: htmlMessage,
			callback: callback,
			callbackQuiz: callbackQuiz,
			scene: scene,
			isQuiz: isQuiz
		}
		

		var msgDisplayedCount = $("#dialog .messages .message").length;

		// On a demandé à nettoyer la liste des message 
		if(this.cleanNextActive)
		{
			if(this.cleanNextActiveFirstSound)
			{
				SoundController.play('assets/audios/bubble-on.mp3');
				this.cleanNextActiveFirstSound = false;
			}

		
			DialogController.showNext();
			DialogController.hideClose();

			
		}



		// on ajoute le message à la pile d'attente
		this.messagesStack.push(msg);


		// si la liste des messages affichés est vide on affiche le msg
		if(msgDisplayedCount == 0)
		{
			DialogController.next();
		}else if(DialogController.forceDisplayNext) // en passant via un appel direct
		{
			DialogController.forceDisplayNext = false;
			DialogController.next();
		}

		

		$("#interface nav a.dialog").addClass("active");
		DialogController.open();

		
		
	}

	static quizUniqueChoice(message, choices, avatar, name, title, side, callbackQuiz, callback, scene)
	{
		var htmlChoices = '';
		var group = "g"+Math.floor(Date.now() / 1000);
		for (var i=0; i < choices.length; i++)
		{
			htmlChoices += "<input type='radio' id='"+choices[i].id+"'' name='"+group+"' value='"+choices[i].value+"'><label for='"+choices[i].id+"''>"+choices[i].label+"</label>";
		}
		var messageForm = "<h3>"+message+"</h3><form data-type='uniqueChoice'>"+htmlChoices+"<button type='submit'>Valider</button></form>";
		

		this.add(messageForm, avatar, name, title, side, callback, scene, true, callbackQuiz);
	}


	static quizMultipleChoice(message, choices, avatar, name, title, side, callbackQuiz, callback, scene)
	{
		var htmlChoices = '';
		var group = "g"+Math.floor(Date.now() / 1000);
		for (var i=0; i < choices.length; i++)
		{
			htmlChoices += "<input type='checkbox' id='"+choices[i].id+"'' name='"+group+"' value='"+choices[i].value+"'><label for='"+choices[i].id+"''>"+choices[i].label+"</label>";
		}
		var messageForm = "<h3>"+message+"</h3><form data-type='multipleChoice'>"+htmlChoices+"<button type='submit'>Valider</button></form>";
		
		this.add(messageForm, avatar, name, title, side, callback, scene, true, callbackQuiz);
	}


	static quizSortableChoice(message, choices, avatar, name, title, side, callbackQuiz, callback, scene)
	{
		var htmlChoices = '';
		var group = "g"+Math.floor(Date.now() / 1000);
		for (var i=0; i < choices.length; i++)
		{
			htmlChoices += "<li id='"+choices[i].id+"'>"+choices[i].label+"</li>";
		}
		var messageForm = "<h3>"+message+"</h3><form data-type='sortableChoice'><ul class='sortable'>"+htmlChoices+"</ul><button type='submit'>Valider</button></form>";
		
		this.add(messageForm, avatar, name, title, side, callback, scene, true, callbackQuiz);
	}

	static quizFillIn(message, datas, avatar, name, title, side, callbackQuiz, callback, scene, multipleChances)
	{
		var classMultipleChances = '';
		if(multipleChances)
		{
			classMultipleChances = 'multipleChances';
		}

		var htmlDatas = "<div class='fillIn "+classMultipleChances+"'>" + datas + "</div>";
		
		var messageForm = "<h3>"+message+"</h3><form data-type='fillIn'>"+htmlDatas+"<br><button type='submit'>Valider</button></form>";
		
		this.add(messageForm, avatar, name, title, side, callback, scene, true, callbackQuiz);
	}	


	static next()
	{


		if(this.cleanNextActive)
		{
			this.cleanNextActive = false;
			DialogController.clean();
		}

		if(this.callbackNext != null)
		{
			this.callbackNext( this.callbackNextScene );
			this.callbackNext = null;
			this.callbackNextScene = null;
		}

		DialogController.hideNext();
		DialogController.showClose();



		if(this.messagesStack.length > 0)
		{
			
			
			var lastMessage = this.messagesStack.shift(); // pop extrait la premiere valeur et la retire du tableau
			

			$(lastMessage.htmlMessage).hide().appendTo("#dialog .messages .stack").fadeIn(500).addClass("active");

			
			
			
			if(lastMessage.isQuiz != true)
			{
				//SoundController.play('assets/audios/keyboard.mp3', 'keyboard', true);


				var t = $("#dialog .message.active:last-child .text").html()
				var speed = 10;

				jsTypeText.start({
					text: t,
					speed: speed,
					cursor: false,
					cursorSpeed: 350,
					cursorStyle: "vertical"
				  }, (result)=>{
					$("#dialog .message.active:last-child .text").html(result)
				  });

				  setTimeout(DialogController.prepareNext, t.length*speed, lastMessage);


			}else
			{
				DialogController.prepareQuizz($("#dialog .messages .stack .message form").last(), lastMessage);
				

				var delay = 100; 
				setTimeout(DialogController.prepareNext, delay, lastMessage);
			}


			

			var msgs = $("#dialog .messages");
			msgs.stop().animate({scrollTop:$('#dialog .messages')[0].scrollHeight}, 500, 'swing', function() { 
			   //alert("Finished animating");
			});

			
		}


		
		
	}

	static prepareNext(msg)
	{

		if(!DialogController.isStackEmpty())
		{
			

			SoundController.play('assets/audios/bubble-on.mp3');
			DialogController.showNext();
			DialogController.hideClose();

			
		}

		if(msg.callback != null)
		{
			msg.callback(msg.scene);
		}

						
	}


	static prepareQuizz(form, msg)
	{
/*
		$(form).find(".sortable").sortable(
			{
				
             	cursor: 'move',
             	helper: 'clone',
             	appendTo: 'body',
             	start: function(e, ui)
             	{
					var t = parseFloat($('#dialog .message form ul.sortable li').css('padding-top'));
					var b = parseFloat($('#dialog .message form ul.sortable li').css('padding-bottom'));
					var l = parseFloat($('#dialog .message form ul.sortable li').css('padding-left'));
					var r = parseFloat($('#dialog .message form ul.sortable li').css('padding-right'));
             		ui.helper.height( ui.helper.height() + t + b );
             		ui.helper.width( ui.helper.width() + l + r );
             	},
             	change: function(e, ui)
             	{
             		//console.log(e);
             		//console.log(ui);

             	}
			}
		);
*/


		$(form).on("submit", function( event ) {

			

			//var sortableList = $(this).find('.sortable');
			var results = $( this ).serializeArray();
			
			/*
			if(sortableList.length > 0)
			{
				results = $(this).find('.sortable').sortable( "toArray" );
				
			}
			*/

			if(results && results.length > 0)
			{

				SoundController.play('assets/audios/click-valid.mp3');

				msg.callbackQuiz( results );

				if($(this).find('.multipleChances').length == 0)
				{
					//$(this).find('.sortable').sortable( "disable" );
					$(this).find('input, button').prop('disabled', true);
					$(this).find('label').addClass('disabled');
				}

				

			}else
			{
				// erreur
			}
			
  			event.preventDefault();
		});
	}



	static showNext()
	{
		$("#dialog .messages .dialog-next").addClass("active");
		
		this.nextTween.play();

		if( !this.opened )
		{
			this.showBump();
		}

		
	}

	static showBump()
	{
		if(this.bumpTween == null)
		{
			this.bumpTween = gsap.to($("#interface nav a.dialog"), {duration: 0.5, marginBottom: 20, repeat: -1, yoyo: true});
		}
	}

	static hideBump()
	{
		if(this.bumpTween != null)
		{
			this.bumpTween.kill();
			$("#interface nav a.dialog").css("margin-bottom", "0px");
			this.bumpTween = null;
		}
	}

	static hideNext()
	{
		$("#dialog .messages .dialog-next").removeClass("active");
		this.nextTween.pause();
		
	}

	static showClose()
	{
		$("#dialog .messages .dialog-close").addClass("active");
		
	}

	static hideClose()
	{
		$("#dialog .messages .dialog-close").removeClass("active");
	
	}

	static clean()
	{
		$("#dialog .messages .stack").empty();

	}

	static cleanNext()
	{
		this.cleanNextActive = true;
		this.cleanNextActiveFirstSound = true;
	}

	static callNext( callback, scene)
	{
		this.callbackNext = callback;
		this.callbackNextScene = scene;
	}

	static DisplayNextButton() {
		DialogController.showNext();
		DialogController.hideClose();
		SoundController.play('assets/audios/bubble-on.mp3');
	}

	static DisplayNext()
	{
		DialogController.forceDisplayNext = true;
	}
	static clear()
	{
		this.messagesStack = [];

		$("#dialog .messages .message").removeClass("active").fadeOut(500, function(e){
				$(this).remove();
				DialogController.close();
				DialogController.hideNext();
		});
		$("#interface nav a.dialog").removeClass("active");
	}

	static erase()
	{
		this.messagesStack = [];

		$("#dialog .messages .message").removeClass("active").each(function(){
				$(this).remove();
				DialogController.close();
				DialogController.hideNext();
		});
		$("#interface nav a.dialog").removeClass("active");
	}

	static toggle()
	{
		if(this.opened)
		{
			DialogController.close();
		}else
		{
			DialogController.open();
		}
	}

	static open()
	{
		if(!this.opened)
		{
			$("#dialog").addClass("active").hide().fadeIn(500);
		}

		this.hideBump();

		this.opened = true;

	}

	static close()
	{
		if(this.opened)
		{
			$("#dialog").fadeOut(500, function(e){
				
				$(this).removeClass("active");
			});

			if(!DialogController.isStackEmpty())
			{
				this.showBump();
			}
		}

		this.opened = false;
		

		window.dispatchEvent(new CustomEvent(DialogEvent.CLOSE, {
            detail: {
                
            }
            
        }));
	}


}
export {DialogController}