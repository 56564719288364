
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/Home.vue';
import GameView from '@/views/Game.vue';
import authService from '../services/authService';
import redirectService from '../services/redirectService';
import eventDispatcher from '../eventDispatcher';
import eventConfig from '@/configs/eventConfig';

const defaultMeta = {
};

const requireAuthMeta = {
  requiresAuth: true
};

const routes = [
  
  {
    path: '/play',
    name: 'Game',
    component: GameView,
    meta: requireAuthMeta
  },
  {
    path: '/:message?',
    name: 'Home',
    component: HomeView,
    meta: defaultMeta
  }
];

var router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    
    authService.isUserAuth().then(
      () => {
        
        next();
      },
      error => {
        redirectService.setRedirectRoute(to);
        
        next({ name: 'Home', params: {error: error.response.data.msg} });
      }
    );

  }else{
    
        next();
    
  }


});

eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
  router.push({ name: 'Home', params: {error: "Veuillez vous reconnecter."} });
})

export default router
