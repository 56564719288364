import { MainController } from "../controller/mainController";
import { IntroScene } from "../display/introScene";
import { Text } from "../display/text";

class Chapter7Intro extends IntroScene {



	constructor() {
		super("chapter7Intro");
        this.chapter = "chapter7";
		this.title = Text.get('chapter7-intro-title');
		this.subtitle = Text.get('chapter7-intro-subtitle');

        MainController.registerChapter(this);
    } 


    create ()
    {
        super.create();

        // j'enregistre la scène intro en bookmark
        
        MainController.bookmarkChapter(this);
        MainController.setCurrentScene( this );

		this.goToScene("chapter7Scene1", 5000);

    }


    beforeTransition()
    {
        //'on kill tout ce qui peut se déclencher pendant la transition'
        console.log('beforeTransition '+this.name);
    }

    beforeDestroy()
    {
        //'on peut tout killer'
        
    }

}
export {Chapter7Intro}