import { DialogController } from "../controller/dialogController";
import { HelpController } from "../controller/helpController";
import { MainController } from "../controller/mainController";
import { MenuController } from "../controller/menuController";
import { GameScene } from "../display/gameScene";
import { ParallaxScene } from "../display/parallaxScene";
import { DialogEvent } from "../events/dialogEvent";
import { Text } from "../display/text";

class Chapter11Scene1 extends GameScene {



    constructor() {
        super("chapter11Scene1");

        this.title = Text.get("c11s1-title");
        this.subtitle = Text.get("c11s1-subtitle");
    } 

    preload ()
    {
        super.preload();
        this.load.image('c11-s1-background', 'assets/images/chapters/chapter11/scene1/background.jpg');
        this.load.image('c11-s1-justine', 'assets/images/chapters/chapter11/scene1/justine.jpg');
        this.load.image('c11-s1-firstplan', 'assets/images/chapters/chapter11/scene1/firstplan.png');
        this.load.image('c11-s1-secondplan', 'assets/images/chapters/chapter11/scene1/secondplan.png');

        this.load.audio('ambient-night', 'assets/audios/musics/ambiance-night.mp3');
        this.load.audio('ambient-intrig', 'assets/audios/musics/ambiance-intrig.mp3');
        
    }


    create ()
    {
        
        super.create();

        this.setParallaxDistance( ParallaxScene.DISTANCE_NEAR );

        this.background = this.add.image(0,0, 'c11-s1-background');
        this.secondplan = this.add.image(-200, -50, 'c11-s1-secondplan');
        this.firstplan = this.add.image(0, -200, 'c11-s1-firstplan');

        this.justine = this.add.image(0, 0, 'c11-s1-justine');
        this.justine.setVisible(false);

        this.musicAudio = this.sound.add(
                'ambient-night', 
                {
                    volume: 0.5,
                    loop: true
                });

        this.musicAudioEnd = this.sound.add(
            'ambient-intrig', 
            {
                volume: 0.5,
                loop: true
            });                

        this.musicAudio.play();

        this.addToScene( this.background, 3 );
        this.addToScene( this.secondplan, 2 );
        this.addToScene( this.firstplan, 1 );
        this.addToScene( this.justine, 1 );

        this.timerStart = this.time.delayedCall(5000, function(){
            
            this.firstDialog();
            

        }, [], this);
    

        MainController.setCurrentScene( this );


    }


    firstDialog()
    {
        this.dialog("theo", Text.get("c11s1-dialog1"));
        this.dialog("weston", Text.get("c11s1-dialog2"));
        this.dialog("theo", Text.get("c11s1-dialog3"));
        this.dialog("weston", Text.get("c11s1-dialog4"));
        this.dialog("theo", Text.get("c11s1-dialog5"));
        this.dialog("weston", Text.get("c11s1-dialog6"));
        this.dialog("theo", Text.get("c11s1-dialog7"));
        this.dialog("weston", Text.get("c11s1-dialog8"));

        this.quizFillIn(
            "theo", 
            Text.get("c11s1-dialog9"),
            Text.get("c11s1-dialog10"),
            function(result){
                DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
                
                if( (result[0].value.toUpperCase() == Text.get("c11s1-dialog11")) &&
                    (result[1].value.toUpperCase() == Text.get("c11s1-dialog12")) &&
                    (result[2].value.toUpperCase() == Text.get("c11s1-dialog13")) &&
                    ( (result[3].value.toUpperCase() == Text.get("c11s1-dialog14")) || (result[3].value.toUpperCase() == Text.get("c11s1-dialog15")) ) &&
                    ( (result[4].value.toUpperCase() == Text.get("c11s1-dialog16")) || (result[4].value.toUpperCase() == Text.get("c11s1-dialog17")) ) &&
                    (result[5].value.toUpperCase() == Text.get("c11s1-dialog18")) &&
                    (result[6].value.toUpperCase() == Text.get("c11s1-dialog19")) &&
                    (result[7].value.toUpperCase() == Text.get("c11s1-dialog20")) &&
                    (result[8].value.toUpperCase() == Text.get("c11s1-dialog21")) &&
                    (result[9].value.toUpperCase() == Text.get("c11s1-dialog22")) &&
                    ( (result[10].value.toUpperCase() == Text.get("c11s1-dialog23")) || (result[10].value.toUpperCase() == Text.get("c11s1-dialog24")) || (result[10].value.toUpperCase() == Text.get("c11s1-dialog25")) || (result[10].value.toUpperCase() == Text.get("c11s1-dialog26")) ) &&
                    ( (result[11].value.toUpperCase() == Text.get("c11s1-dialog27")) || (result[11].value.toUpperCase() == Text.get("c11s1-dialog28")) ) &&
                    (result[12].value.toUpperCase() == Text.get("c11s1-dialog29")) &&
                    (result[13].value.toUpperCase() == Text.get("c11s1-dialog30")) &&
                    (result[14].value.toUpperCase() == Text.get("c11s1-dialog31")) &&
                    (result[15].value.toUpperCase() == Text.get("c11s1-dialog32")) &&
                    ( (result[16].value.toUpperCase() == Text.get("c11s1-dialog33")) || (result[16].value.toUpperCase() == Text.get("c11s1-dialog34")) )
                 )
                {
                    MainController.completeChapter('chapter11');
                    HelpController.erase();
                    this.scene.secondDialog();
                }else
                {
                    
                    HelpController.add( Text.get("c11s1-help1"));
                    
                }

                

            },
            null,
            this,
            true // true pour multipleChances
        );
    }

    secondDialog()
    {
        this.dialog("weston", Text.get("c11s1-dialog35"));
        this.dialog("theo", Text.get("c11s1-dialog36"));
        this.dialog("weston", Text.get("c11s1-dialog37"), function(scene){
            

            this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                this.removeEventListener(DialogEvent.CLOSE, handler);
                var scene = MainController.getCurrentScene();
                DialogController.erase();
                scene.justine.alpha = 0;
                scene.justine.setVisible(true);
                scene.tweens.add({
                    targets: scene.justine,
                    alpha: {from: 0, to: 1},
                    duration: 4000
                });

                scene.time.delayedCall(8000, function(){
                    
                    this.thirdDialog();
        
                }, [], scene);

                
            });

        }, this);

    }

    thirdDialog()
    {
        
        this.cleanNextDialog();

        this.dialog("theo", Text.get("c11s1-dialog38"));
        this.dialog("weston", Text.get("c11s1-dialog39"));
        this.dialog("theo", Text.get("c11s1-dialog40"));
        this.dialog("weston", Text.get("c11s1-dialog41"), function(scene){
            

            this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                this.removeEventListener(DialogEvent.CLOSE, handler);
                
                MainController.endGame();

                var scene = MainController.getCurrentScene();

                scene.tweens.add({
                    targets:  this.musicAudio,
                    volume:   0,
                    duration: 1000
                });

                scene.time.delayedCall(1000, function(){
                    this.musicAudio.destroy();
                    this.musicAudioEnd.play();
        
                }, [], scene);

                

                
            });

        }, this);
    }



    beforeTransition()
    {
        
        
        this.tweens.add({
            targets:  this.musicAudio,
            volume:   0,
            duration: 1000
        });
        

    }

    beforeDestroy()
    {
        if(this.musicAudio)
        this.musicAudio.destroy();

        if(this.musicAudioEnd)
        this.musicAudioEnd.destroy();
    }   


}
export {Chapter11Scene1}