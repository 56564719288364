import { DialogController } from "../controller/dialogController";
import { MainController } from "../controller/mainController";
import { RessourcesController } from "../controller/ressourcesController";
import { GameScene } from "../display/gameScene";
import { ParallaxScene } from "../display/parallaxScene";
import { DialogEvent } from "../events/dialogEvent";
import { Text } from "../display/text";

class Chapter4Scene1 extends GameScene {



    constructor() {
        super("chapter4Scene1");
        this.title = Text.get("c4s1-title");
        this.subtitle = Text.get("c4s1-subtitle");

        
    } 

    
    preload ()
    {
        super.preload();

        this.load.image('c4-s1-background', 'assets/images/chapters/chapter4/scene1/background.jpg');
        this.load.image('c4-s1-secondplan', 'assets/images/chapters/chapter4/scene1/secondplan.png');
        this.load.image('c4-s1-firstplan', 'assets/images/chapters/chapter4/scene1/firstplan.png');
        
        
        this.load.audio('ambient-night', 'assets/audios/musics/ambiance-night.mp3');
        
    }

    create ()
    {
        super.create();

        this.setParallaxDistance( ParallaxScene.DISTANCE_NEAR );

        this.background = this.add.image(0,0, 'c4-s1-background');
        this.secondplan = this.add.image(-780,150, 'c4-s1-secondplan');
        this.firstplan = this.add.image(100, 300, 'c4-s1-firstplan');




        this.musicAudio = this.sound.add(
                'ambient-night', 
                {
                    volume: 0.5,
                    loop: true
                });

        

        this.musicAudio.play();



        this.addToScene( this.background, 3 );
        this.addToScene( this.secondplan, 2 );
        this.addToScene( this.firstplan, 1 );
        
        this.timerStart = this.time.delayedCall(5000, function(){
            this.firstDialog();
            
        }, [], this);

        

        MainController.setCurrentScene( this );


    }



    firstDialog()
    {
        this.dialog("weston", Text.get("c4s1-dialog1"));
        this.dialog("wang", Text.get("c4s1-dialog2"));
        this.dialog("weston", Text.get("c4s1-dialog3"), function(){
            RessourcesController.setRessource('password');
        }, this);
        this.dialog("theo", Text.get("c4s1-dialog4"));
        this.dialog("weston", Text.get("c4s1-dialog5"));
        this.quizzFillIn4();
    }

    quizzFillIn4()
    {
        this.quizFillIn("wang", Text.get("c4s1-dialog6"),
            "<input name='q0-fill1'>",
            function(result){
                DialogController.DisplayNext();

                const regexSimpleAlpha = new RegExp('^[a-z]{5}$');

                if(regexSimpleAlpha.test(result[0].value))
                {
                    this.scene.dialog("weston", Text.get("c4s1-dialog7"));
                    this.scene.dialog("weston", Text.get("c4s1-dialog8"));
                    this.scene.quizzFillIn6();
                }else
                {
                    this.scene.dialog("weston", Text.get("c4s1-dialog9"));
                    this.scene.quizzFillIn4();                    
                }
            },
            null,
            this
        );
        
    }

    quizzFillIn6()
    {
        
        this.quizFillIn("wang", Text.get("c4s1-dialog10"),
            "<input name='q0-fill1'>",
            function(result){
                DialogController.DisplayNext();

                const regexSimpleAlphaMaj = new RegExp('^[a-zA-Z0-9]{6}$');
                if(regexSimpleAlphaMaj.test(result[0].value))
                {
                    this.scene.dialog("weston", Text.get("c4s1-dialog11"));
                    this.scene.dialog("weston", Text.get("c4s1-dialog12"));
                    this.scene.quizzFillIn10();
                }else
                {
                    this.scene.dialog("weston", Text.get("c4s1-dialog13"));
                    this.scene.quizzFillIn6();                    
                }
            },
            null,
            this
        );
        
    }

    quizzFillIn10()
    {
        
        this.quizFillIn("wang", Text.get("c4s1-dialog14"),
            "<input name='q0-fill1'>",
            function(result){
                DialogController.DisplayNext();

                const regexComplexe = new RegExp('^[a-zA-Z0-9&@_°#€%]{10}$');
                if(regexComplexe.test(result[0].value))
                {
                    this.scene.dialog("weston", Text.get("c4s1-dialog15"));                    
                    this.scene.secondDialog();
                }else
                {
                    this.scene.dialog("weston", Text.get("c4s1-dialog16"));
                    this.scene.quizzFillIn10();                    
                }
            },
            null,
            this
        );
        
    }    

    secondDialog()
    {
        
        this.dialog("theo", Text.get("c4s1-dialog17"));
        this.dialog("wang", Text.get("c4s1-dialog18"));
        this.dialog("weston", Text.get("c4s1-dialog19"));
        this.dialog("theo", Text.get("c4s1-dialog20"));
        this.dialog("wang", Text.get("c4s1-dialog21"));
        this.dialog("weston", Text.get("c4s1-dialog22"));
        this.dialog("wang", Text.get("c4s1-dialog23"));
        this.quizUniqueChoice("theo", Text.get("c4s1-dialog24"),
            [
                {
                    "id": "q2-choice1",
                    "label": Text.get("c4s1-dialog25"),
                    "value": 1
                },
                {
                    "id": "q2-choice2",
                    "label": Text.get("c4s1-dialog26"),
                    "value": 0
                }
            ],
            function(result){
                DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
                
                if(result[0].value == 1)
                {
                    this.scene.dialog("weston", Text.get("c4s1-dialog27"));

                }

                this.scene.thirdDialog();
            },
            null,
            this
        );
    }

    thirdDialog()
    {
        this.dialog("wang", Text.get("c4s1-dialog28"));
        this.dialog("weston", Text.get("c4s1-dialog29"));
        this.dialog("wang", Text.get("c4s1-dialog30"));
        this.quizUniqueChoice("theo", Text.get("c4s1-dialog31"),
            [
                {
                    "id": "q3-choice1",
                    "label": Text.get("c4s1-dialog32"),
                    "value": 1
                },
                {
                    "id": "q3-choice2",
                    "label": Text.get("c4s1-dialog33"),
                    "value": 0
                }
            ],
            function(){
                DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
                

                this.scene.fourthDialog();
            },
            null,
            this
        );
    }

    fourthDialog()
    {
        this.dialog("weston", Text.get("c4s1-dialog34"));
        this.dialog("theo", Text.get("c4s1-dialog35"));
        this.dialog("wang", Text.get("c4s1-dialog36"));
        this.dialog("weston", Text.get("c4s1-dialog37"), 
            function(scene){

            MainController.completeChapter('chapter4');

            scene.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler()
            {
                this.removeEventListener(DialogEvent.CLOSE, handler);
                var scene = MainController.getCurrentScene();
                scene.goToScene("chapter5Intro");
            }
            );
            }, this
        );
    }



    beforeTransition()
    {
        
        this.tweens.add({
            targets:  this.musicAudio,
            volume:   0,
            duration: 1000
        });

    } 

    beforeDestroy()
    {
        this.musicAudio.destroy();
    }

}
export {Chapter4Scene1}