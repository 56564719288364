class Text {

	static get(key) {
		return this.datas[key];
	}

	constructor() {
        if (!Text.instance) {
            return Text.instance;
        }

        Text.instance = this;
        this.datas = {}; 

        return this;
    }

    static setData(value)
    {
        this.datas = value;
    }
}
export {Text}