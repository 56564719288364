class SceneEvent {
	static get SCENE_PRELOAD(){
		return "scene_event_scene_preload";
	}

	static get SCENE_CREATE(){
		return "scene_event_scene_create";
	}

	static get SCENE_CHANGE(){
		return "scene_event_scene_change";
	}

	static get SCENE_DESTROYED(){
		return "scene_event_scene_destroyed";
	}
}
export {SceneEvent}