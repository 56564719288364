import Phaser from "phaser";
import { SceneEvent } from "../events/sceneEvent";
import { Text } from "./text";

class RootScene extends Phaser.Scene {

    static get DISTANCE_IMMEDIATE(){
        return "parallax_scene_distance_immediate";
    }

    static get DISTANCE_NEAR(){
        return "parallax_scene_distance_near";
    }

    static get DISTANCE_FAR(){
        return "parallax_scene_distance_far";
    }

    static get DISTANCE_FARAWAY(){
        return "parallax_scene_distance_faraway";
    }

	constructor(name) {
		super(name);
        this.name = name;
        this.side = 'left';
        this.transitionDuration = 2000;

	}

    preload(active) {
        this.mainCam = this.cameras.main;

        if(active)
        {
            
            
            this.graphics = this.add.graphics();
            this.newGraphics = this.add.graphics();
            var progressBar = new Phaser.Geom.Rectangle(this.mainCam.centerX-200, this.mainCam.centerY, 400, 20);
            var progressBarFill = new Phaser.Geom.Rectangle(this.mainCam.centerX-195, this.mainCam.centerY+5, 390, 10);


            this.graphics.fillStyle(0xFFFF00, 1);
            this.graphics.fillRectShape(progressBar);
            

            this.newGraphics.fillStyle(0x000000, 1);
            this.newGraphics.fillRectShape(progressBarFill);
            

            this.loadingText = this.add.text(this.mainCam.centerX, this.mainCam.centerY+50, Text.get('interface-chargement'), { fontFamily: 'Verdana, Arial, Helvetica, sans-serif', fontSize: '22px', fill: '#FFFF00' });
            this.loadingText.setOrigin(0.5);


            this.load.on('progress', this.loadingProgress, {scene: this});
            this.load.on('complete', this.loadingComplete, {scene: this});

        }
        
        
    }


    loadingProgress(percentage) {
        this.scene.newGraphics.clear();
        this.scene.newGraphics.fillStyle(0x000000, 1);
        this.scene.newGraphics.fillRectShape(new Phaser.Geom.Rectangle(this.scene.mainCam.centerX-195, this.scene.mainCam.centerY+5, percentage*390, 10));
                
        percentage = percentage * 100;
        this.scene.loadingText.setText(Text.get('interface-chargement')+" " + percentage.toFixed(0) + "%");
        //console.log("P:" + percentage);
    }

    loadingComplete() {
        //console.log(this.name+" LOADING COMPLETE!");

        this.scene.graphics.destroy();
        this.scene.newGraphics.destroy();
        this.scene.loadingText.destroy();

    }



    goToScene(sceneName, delay)
    {
        this.time.delayedCall(delay, function(){

            this.beforeDestroy();

            window.dispatchEvent(new CustomEvent(SceneEvent.SCENE_CHANGE, {
                detail: {
                    name: this.name
                }
                
            }));

            this.scene.start(sceneName);
        }, [], this);
        /*
        var timer = this.time.delayedCall(delay, function(){
            
            this.mainCam.fadeOut(this.transitionDuration);

            window.dispatchEvent(new CustomEvent(SceneEvent.SCENE_CHANGE, {
                detail: {
                    name: this.name
                }
                
            }));

            this.beforeTransition();
            
            var timer = this.time.delayedCall(this.transitionDuration, function(){
                
                this.scene.transition({ target: sceneName, duration: this.transitionDuration, remove: true });

                
            }, [], this);

        }, [], this);

        */

    }


    create ()
    {



        window.dispatchEvent(new CustomEvent(SceneEvent.SCENE_CREATE, {
            detail: {
                name: this.name
            }
            
        }));

        this.scene.scene.events.on('destroy', function(scene){
            console.log("destroy");

            window.dispatchEvent(new CustomEvent(SceneEvent.SCENE_DESTROYED, {
                detail: {
                    name: scene.config
                }
            
            }));
        });

        this.scene.scene.events.on('transitionstart', function(fromScene){
            fromScene.beforeDestroy();
        });

        


    }


    update()
    {
       

    }



    beforeTransition()
    {
        //'on kill tout ce qui peut se déclencher pendant la transition'
        
    }

    beforeDestroy()
    {
        //'on peut tout killer'
        
    }


}
export {RootScene}