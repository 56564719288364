import { DialogController } from "../controller/dialogController";
import { ParallaxScene } from "./parallaxScene";
import Phaser from "phaser";
import { Text } from "./text";

class GameScene extends ParallaxScene {


	constructor(name) {
		super(name);
        this.name = name;
        this.side = 'left';

	}

    preload(active) {
        var activeBool = true;
        
        if(active != undefined)
        {
            activeBool = active;
        }
        super.preload(activeBool);
        
    }



    cleanDialog()
    {
        DialogController.clean();
        this.side = 'left';
    }

    cleanNextDialog()
    {
        DialogController.cleanNext();
        this.side = 'left';
    }

    callNextDialog(callback, scene)
    {
        DialogController.callNext( callback, scene);
    }

    dialog(who, text, callback, scene)
    {

        if(this.side == 'left')
        {
            this.side = 'right';
        }else
        {
            this.side = 'left';
        }
        

        DialogController.add( text , who, Text.get(who+'-name'), Text.get(who+'-title'), this.side, callback, scene);
        
        
    }

    quizUniqueChoice(who, text, choices, callbackQuiz, callback, scene)
    {
        if(this.side == 'left')
        {
            this.side = 'right';
        }else
        {
            this.side = 'left';
        }
        

        DialogController.quizUniqueChoice( text, choices , who, Text.get(who+'-name'), Text.get(who+'-title'), this.side, callbackQuiz, callback, scene);
        
    }

    quizMultipleChoice(who, text, choices, callbackQuiz, callback, scene)
    {
        if(this.side == 'left')
        {
            this.side = 'right';
        }else
        {
            this.side = 'left';
        }
        

        DialogController.quizMultipleChoice( text, choices , who, Text.get(who+'-name'), Text.get(who+'-title'), this.side, callbackQuiz, callback, scene);
        
    }

    quizSortableChoice(who, text, choices, callbackQuiz, callback, scene)
    {
        if(this.side == 'left')
        {
            this.side = 'right';
        }else
        {
            this.side = 'left';
        }
        

        DialogController.quizSortableChoice( text, choices , who, Text.get(who+'-name'), Text.get(who+'-title'), this.side, callbackQuiz, callback, scene);
        
    }

    quizFillIn(who, text, datas, callbackQuiz, callback, scene, multipleChances)
    {
        if(this.side == 'left')
        {
            this.side = 'right';
        }else
        {
            this.side = 'left';
        }
        

        DialogController.quizFillIn( text, datas, who, Text.get(who+'-name'), Text.get(who+'-title'), this.side, callbackQuiz, callback, scene, multipleChances);
        
    }    


    create ()
    {
        super.create();
        
        this.mainCam = this.cameras.main;
        this.setSceneTitles();
        

    }

    setSceneTitles()
    {
        this.titles = this.add.container(this.mainCam.centerX, this.mainCam.height+50);
        this.subtitles = this.add.container(this.mainCam.centerX, this.mainCam.height+50);
        
        this.textTitleShadow = this.add.bitmapText(3, 3, 'roboto-condensed-bi', this.title, 34, Phaser.GameObjects.BitmapText.ALIGN_CENTER);
        this.textTitle = this.add.bitmapText(0, 0, 'roboto-condensed-bi', this.title, 34, Phaser.GameObjects.BitmapText.ALIGN_CENTER);
        this.textTitleShadow.setTint(0x000000);
        this.textTitleShadow.alpha = 0.5;
        
        this.textTitle.setOrigin(0.5);
        this.textTitle.scrollFactorX = 0;
        this.textTitle.scrollFactorY = 0;

        this.textTitleShadow.setOrigin(0.5);
        this.textTitleShadow.scrollFactorX = 0;
        this.textTitleShadow.scrollFactorY = 0;



        this.textSubtitleShadow = this.add.bitmapText(2, 2, 'roboto-condensed-bi', this.subtitle, 30, Phaser.GameObjects.BitmapText.ALIGN_CENTER);
        this.textSubtitle = this.add.bitmapText(0, 0, 'roboto-condensed-bi', this.subtitle, 30, Phaser.GameObjects.BitmapText.ALIGN_CENTER);
        this.textSubtitleShadow.setTint(0x000000);
        this.textSubtitleShadow.alpha = 0.5;
        
        this.textSubtitle.setOrigin(0.5);
        this.textSubtitle.scrollFactorX = 0;
        this.textSubtitle.scrollFactorY = 0;

        this.textSubtitleShadow.setOrigin(0.5);
        this.textSubtitleShadow.scrollFactorX = 0;
        this.textSubtitleShadow.scrollFactorY = 0;

        

        this.titles.add(this.textTitleShadow);
        this.titles.add(this.textTitle);
        this.subtitles.add(this.textSubtitleShadow);
        this.subtitles.add(this.textSubtitle);


        this.timelineTitles = this.tweens.timeline({
            tweens: [
                {
                    targets: this.titles,
                    props: {
                        alpha: {
                            value: { from: 0, to: 1 },
                            ease: 'Linear',
                            duration: 2000
                        },
                        y: {
                            value: { from: this.mainCam.height+50, to: this.mainCam.height-80 },
                            ease: 'Back',
                            duration: 2000
                        }
                    }
                },
                {
                    targets: this.subtitles,
                    props: {
                        alpha: {
                            value: { from: 0, to: 1 },
                            ease: 'Linear',
                            duration: 2000
                        },
                        y: {
                            value: { from: this.mainCam.height+100, to: this.mainCam.height-40 },
                            ease: 'Back',
                            duration: 2000
                        }
                    },
                    offset: '-=1500'
                },
                {
                    targets: [this.titles, this.subtitles],
                    props: {
                        alpha: {
                            value: { from: 1, to: 0 },
                            ease: 'Linear',
                            duration: 2000
                        }
                    },
                    offset: '+=4000'
                }
            ]
        });
    }




}
export {GameScene}