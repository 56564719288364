import { PlayerEvent } from "../events/playerEvent";
import { DatasController } from "./datasController";
import { MainController } from "./mainController";

class PlayerController {

	constructor() {
        if (!PlayerController.instance) {
            return PlayerController.instance;
        }

        PlayerController.instance = this;

        

        return this;
    }

	static init(token)
	{

		this.token = token;
		this.initialDatas = {
			chapters: [],
			interactions: [],
			objectives: [],
			ressources: [],
			date: null
		};
		this.datas = this.initialDatas;
		this.initialBookmark = "chapter1Intro";
		this.bookmark = this.initialBookmark;

		if(this.token == "development_token")
		{
			this.bookmark = MainController.debugScene;
			window.dispatchEvent(new CustomEvent(PlayerEvent.LOADING_COMPLETE));
		}else
		{

			DatasController.init(this.token);

			
			Promise.all([DatasController.getDatas(), DatasController.getBookmark()]).then(datas => {
				
				if( (datas != null) && (datas != 'null'))
				{
					this.datas = datas[0];
					this.bookmark = datas[1].bookmark;
				}
				
				window.dispatchEvent(new CustomEvent(PlayerEvent.LOADING_COMPLETE));
			})
			.catch(error => {
				console.log(error.response.data);

			});
			

		}

		
	}

	static setDatas()
	{
		this.datas.date = Date.now();
		DatasController.setDatas(this.datas);
	}

	static eraseDatas()
	{
		this.datas = this.initialDatas;
		DatasController.setDatas(this.datas);

	}

	static getMe()
	{
		return DatasController.getMe()
			.then(datas => {
				if( (datas != null) && (datas != 'null'))
				{
					this.me = datas;	
				}

				return datas;

			})
			.catch(error => {
				console.log(error.response.data);
			});
	}

	static getResults()
	{
		return DatasController.getResults()
			.then(datas => {
				if( (datas != null) && (datas != 'null'))
				{
					this.results = datas;	
				}

				return datas;

			})
			.catch(error => {
				console.log(error.response.data);
			});
	}

	static getDatas()
	{
		return DatasController.getDatas()
			.then(datas => {

				if( (datas != null) && (datas != 'null'))
				{
					this.datas = datas;	
				}

			})
			.catch(error => {
				console.log(error.response.data);
			});
	}

	static setBookmark()
	{
		DatasController.setBookmark(this.bookmark);
	}

	static eraseBookmark()
	{
		this.bookmark = this.initialBookmark;
		DatasController.setBookmark(this.bookmark);

	}


	static getBookmark()
	{
		return DatasController.getBookmark()
			.then(data => {

				if( (data != null) && (data != 'null'))
				{
					this.bookmark = data.bookmark;	
				}

			})
			.catch(error => {
				console.log(error.response.data);
			});
	}


	static getRecordedSceneName()
	{
		return this.bookmark;
	}


	static getChapters()
	{
		return this.datas.chapters;
	}


	static registerChapter(id, title, subtitle, sceneName)
	{

		let chapterFounded = false;
		
		for (var i = 0; i < this.datas.chapters.length; i++) 
		{
			if(this.datas.chapters[i].id == id)
			{
				chapterFounded = true;
				break;
			}
		}


		if(!chapterFounded)
		{
			this.datas.chapters.push( {
				id: id,
				scene: sceneName,
				title: title,
				subtitle: subtitle,
				opened: false,
				completed: false
			} );

			
		}

		
	}

	static bookmarkScene(sceneName)
	{

		this.bookmark = sceneName;
		
		this.setBookmark();
	}


	static bookmarkSceneAndOpenChapter(chapterId, sceneName)
	{
		this.bookmarkScene(sceneName);
		
		this.openChapter(chapterId);
	}


	static openChapter(chapterId)
	{
		let chapterFounded = false;
		for (var i = 0; i < this.datas.chapters.length; i++) 
		{
			if(this.datas.chapters[i].id == chapterId)
			{
				chapterFounded = true;
				this.datas.chapters[i].opened = true;
				break;
			}
		}

		if(chapterFounded)
		{
			this.setDatas(); 	
		}

		
	}


	static completeChapter(chapterId)
	{
		let chapterFounded = false;
		for (var i = 0; i < this.datas.chapters.length; i++) 
		{
			if(this.datas.chapters[i].id == chapterId)
			{
				chapterFounded = true;
				this.datas.chapters[i].completed = true;
				break;
			}
		}

		if(chapterFounded)
		{
			this.setDatas(); 	
		}

		
	}

	static isChapterCompleted(chapter)
	{
		var dataPromise = this.getDatas();
		
		return Promise.resolve(dataPromise).then(datas => {
			
			if(this.datas.chapters)
			{
				for (const chap of this.datas.chapters) {
					if(chap.id === chapter)
					{
						if(chap.completed)
						{
							return true;
						}else
						{
							return false;
						}
					}
				}
			}
			return false;
		});
	}

}
export {PlayerController}