import { DialogController } from "../controller/dialogController";
import { HelpController } from "../controller/helpController";
import { MainController } from "../controller/mainController";
import { RessourcesController } from "../controller/ressourcesController";
import { GameScene } from "../display/gameScene";
import { ParallaxScene } from "../display/parallaxScene";
import { DialogEvent } from "../events/dialogEvent";
import { Text } from "../display/text";

class Chapter5Scene1 extends GameScene {



    constructor() {
        super("chapter5Scene1");
        this.title = Text.get("c5s1-title");
        this.subtitle = Text.get("c5s1-subtitle");
    } 

    
    preload ()
    {
        super.preload();

        this.load.image('c5-s1-background', 'assets/images/chapters/chapter5/scene1/background.jpg');
        this.load.image('c5-s1-secondplan', 'assets/images/chapters/chapter5/scene1/secondplan.png');
        this.load.image('c5-s1-firstplan', 'assets/images/chapters/chapter5/scene1/firstplan.png');
        
        
        this.load.audio('ambient-action', 'assets/audios/musics/ambiance-action.mp3');
        
    }

    create ()
    {
        super.create();

        this.setParallaxDistance( ParallaxScene.DISTANCE_NEAR );

        this.background = this.add.image(0,0, 'c5-s1-background');
        this.secondplan = this.add.image(0,450, 'c5-s1-secondplan');
        this.firstplan = this.add.image(0, 400, 'c5-s1-firstplan');




        this.musicAudio = this.sound.add(
                'ambient-action', 
                {
                    volume: 0.5,
                    loop: true
                });

        

        this.musicAudio.play();



        this.addToScene( this.background, 3 );
        this.addToScene( this.secondplan, 2 );
        this.addToScene( this.firstplan, 1 );
        
        this.timerStart = this.time.delayedCall(5000, function(){
            
            this.firstDialog();
            

        }, [], this);

        

        MainController.setCurrentScene( this );


    }

    firstDialog()
    {
        
        this.dialog("theo", Text.get("c5s1-dialog1"));
        this.dialog("theo", Text.get("c5s1-dialog2"), function(scene){
            RessourcesController.setRessource('public-wifi');
        });
        this.quizUniqueChoice("theo", Text.get("c5s1-dialog3"),
            [
                {
                    "id": "q2-choice1",
                    "label": Text.get("c5s1-dialog4"),
                    "value": 1
                },
                {
                    "id": "q2-choice2",
                    "label": Text.get("c5s1-dialog5"),
                    "value": 0
                }
            ],
            function(result){
                DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer

                this.scene.secondDialog();
            },
            null,
            this
        );
    }


    secondDialog()
    {
        this.dialog("theo", Text.get("c5s1-dialog6"));
        this.dialog("theo", Text.get("c5s1-dialog7"), function(scene){
            RessourcesController.setRessource('vpn');
            scene.timerWifi = scene.time.delayedCall(2000, function(){

                if(MainController.isMobile())
                {
                    HelpController.add( Text.get("c5s1-help1")+"<a class='button' target='flirter' href='"+MainController.getFlirterURL()+"/c5s1/"+MainController.getToken()+"'>"+Text.get("c5s1-help1_1")+"</a>" );
                }else
                {
                    HelpController.add( Text.get("c5s1-help2")+"<img src='https://api.qrserver.com/v1/create-qr-code/?data="+MainController.getFlirterURL()+"/c5s1/"+MainController.getToken()+"&size=180x180'><br><a target='flirter' href='"+MainController.getFlirterURL()+"/c5s1/"+MainController.getToken()+"'>"+Text.get("c5s1-help2_1")+"</a>" );
                }

                
            }, [], scene);
        }, this);
        this.dialog("theo", Text.get("c5s1-dialog8"), function(scene){
            scene.endDialog();
        }, this);

    }


    endDialog()
    {
        this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
            this.removeEventListener(DialogEvent.CLOSE, handler);
            var scene = MainController.getCurrentScene();
            scene.goToScene('chapter5Scene2');
        });
    }



    
    beforeTransition()
    {
        
        this.tweens.add({
            targets:  this.musicAudio,
            volume:   0,
            duration: 1000
        });

    } 

    beforeDestroy()
    {
        this.musicAudio.destroy();
    }

}
export {Chapter5Scene1}