import { DialogController } from "../controller/dialogController";
import { HelpController } from "../controller/helpController";
import { MainController } from "../controller/mainController";
import { RessourcesController } from "../controller/ressourcesController";
import { GameScene } from "../display/gameScene";
import { ParallaxScene } from "../display/parallaxScene";
import { DialogEvent } from "../events/dialogEvent";
import { Text } from "../display/text";

class Chapter6Scene1 extends GameScene {



    constructor() {
        super("chapter6Scene1");
        this.title = Text.get("c6s1-title");
        this.subtitle = Text.get("c6s1-subtitle");
    } 

    
    preload ()
    {
        super.preload();

        

        this.load.image('c6-s1-background', 'assets/images/chapters/chapter6/scene1/background.jpg');
        this.load.image('c6-s1-firstplan', 'assets/images/chapters/chapter6/scene1/firstplan.png');
        this.load.image('c6-s1-cafe', 'assets/images/chapters/chapter6/scene1/cafe.jpg');
        this.load.image('c6-s1-library', 'assets/images/chapters/chapter6/scene1/library.jpg');
        this.load.image('c6-s1-room', 'assets/images/chapters/chapter6/scene1/room.jpg');
        this.load.image('c6-s1-sport', 'assets/images/chapters/chapter6/scene1/sport.jpg');
        this.load.image('check', 'assets/images/check.png');

        this.load.audio('ambient-detente', 'assets/audios/musics/ambiance-detente.mp3');
        
    }

    create ()
    {
        super.create();

        this.setParallaxDistance( ParallaxScene.DISTANCE_NEAR );

        this.background = this.add.image(0,0, 'c6-s1-background');
        this.firstplan = this.add.image(0, 0, 'c6-s1-firstplan');

        this.cafe = this.add.image(0, 0, 'c6-s1-cafe');
        this.library = this.add.image(0, 0, 'c6-s1-library');
        this.room = this.add.image(0, 0, 'c6-s1-room');
        this.sport = this.add.image(0, 0, 'c6-s1-sport');

        

        this.cafe.setVisible(false);
        this.library.setVisible(false);
        this.room.setVisible(false);
        this.sport.setVisible(false);

        this.cafe.setInteractive();
        this.library.setInteractive();
        this.room.setInteractive();
        this.sport.setInteractive();


        this.musicAudio = this.sound.add(
                'ambient-detente', 
                {
                    volume: 0.5,
                    loop: true
                });

        this.musicAudio.play();

        this.addToScene( this.background, 3 );
        
        this.addToScene( this.firstplan, 2 );
        this.addToScene( this.cafe, 1 );
        this.addToScene( this.library, 1 );
        this.addToScene( this.room, 1 );
        this.addToScene( this.sport, 1 );

        this.sportArea = this.add.rectangle(0, 0, 440, 280, 0xff0000, 0);
        this.sportArea.setInteractive();
        this.sportArea.setVisible(true);
        this.sportArea.angle = 20;
        this.addToScene( this.sportArea, 2 );

        this.sportCheck = this.add.image(this.sportArea.x, this.sportArea.y, 'check');
        this.sportCheck.scaleX = 0.2;
        this.sportCheck.scaleY = 0.2;
        this.sportCheck.setVisible(false);
        this.addToScene( this.sportCheck, 2 );
        


        this.roomArea = this.add.rectangle(370, 0, 340, 220, 0x00ff00, 0);
        this.roomArea.setInteractive();
        this.roomArea.setVisible(true);
        this.roomArea.angle = -40;
        this.addToScene( this.roomArea, 2 );

        this.roomCheck = this.add.image(this.roomArea.x, this.roomArea.y, 'check');
        this.roomCheck.scaleX = 0.2;
        this.roomCheck.scaleY = 0.2;
        this.roomCheck.setVisible(false);
        this.addToScene( this.roomCheck, 2 );        
        
        this.libraryArea = this.add.rectangle(520, 270, 320, 260, 0x0000ff, 0);
        this.libraryArea.setInteractive();
        this.libraryArea.setVisible(true);
        this.libraryArea.angle = -5;
        this.addToScene( this.libraryArea, 2 );

        this.libraryCheck = this.add.image(this.libraryArea.x, this.libraryArea.y, 'check');
        this.libraryCheck.scaleX = 0.2;
        this.libraryCheck.scaleY = 0.2;
        this.libraryCheck.setVisible(false);
        this.addToScene( this.libraryCheck, 2 );  


        this.cafeArea = this.add.rectangle(110, 360, 380, 260, 0x000000, 0);
        this.cafeArea.setInteractive();
        this.cafeArea.setVisible(true);
        this.cafeArea.angle = -20;
        this.addToScene( this.cafeArea, 2 );    
        
        this.cafeCheck = this.add.image(this.cafeArea.x, this.cafeArea.y, 'check');
        this.cafeCheck.scaleX = 0.2;
        this.cafeCheck.scaleY = 0.2;
        this.cafeCheck.setVisible(false);
        this.addToScene( this.cafeCheck, 2 );  

        this.libraryCompleted = this.cafeCompleted = this.roomCompleted = this.sportCompleted = false;

        this.timerStart = this.time.delayedCall(5000, function(){
            
            this.firstDialog();

        }, [], this);
    

        MainController.setCurrentScene( this );


    }

    firstDialog()
    {
        this.dialog("weston", Text.get("c6s1-dialog1"), function(scene){
            
            scene.activePictures();

            scene.timerHelpPictures = scene.time.delayedCall(30000, function(){
                HelpController.add( Text.get("c6s1-help1") );
            }, [], scene);

        }, this);

        
    }

    activePictures()
    {
        this.roomArea.once('pointerdown', function(){
            this.pictureWasClicked();
            this.roomDialog();
        }, this);

        this.sportArea.once('pointerdown', function(){
            this.pictureWasClicked();
            this.sportDialog();
        }, this);

        this.cafeArea.once('pointerdown', function(){
            this.pictureWasClicked();
            this.cafeDialog();
        }, this);

        this.libraryArea.once('pointerdown', function(){
            this.pictureWasClicked();
            this.libraryDialog();
        }, this);
    }

    pictureWasClicked()
    {
        if(this.timerHelpPictures != null)
        {
            this.timerHelpPictures.remove();    
        }
    }

    cafeDialog()
    {

        HelpController.erase();
        DialogController.erase();
        this.cleanNextDialog();

        this.cafe.scaleX = 0;
        this.cafe.scaleY = 0;
        this.cafe.alpha = 0;

        this.cafe.x = this.cafeArea.x;
        this.cafe.y = this.cafeArea.y;

        this.cafe.setVisible(true);

        this.cafeTween = this.tweens.add({
            targets: this.cafe,
            scaleX: 1,
            scaleY: 1,
            alpha: 1,
            x: 0,
            y: 0,
            ease: 'Linear',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
            duration: 800
        });

        
        this.timerCafe = this.time.delayedCall(4000, function(){
            
            RessourcesController.setRessource('public-wifi');
            
            this.dialog("weston", Text.get("c6s1-dialog2"));
            this.dialog("theo", Text.get("c6s1-dialog3"));
            this.dialog("weston", Text.get("c6s1-dialog4"));
            this.dialog("theo", Text.get("c6s1-dialog5"));
            this.dialog("weston", Text.get("c6s1-dialog6"), function(scene){
                this.scene.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                    this.removeEventListener(DialogEvent.CLOSE, handler);
                    var scene = MainController.getCurrentScene();
        
                    scene.closeCafe();
                });
                
            }, this);

        }, [], this);
    
    }


    closeCafe()
    {
        this.closeCafeTween = this.tweens.add({
            targets: this.cafe,
            scaleX: 0,
            scaleY: 0,
            alpha: 0,
            x: this.cafeArea.x,
            y: this.cafeArea.y,
            ease: 'Back',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
            duration: 800,
            onComplete: this.closeCafeComplete.bind(this)
        });
    }

    closeCafeComplete()
    {
        this.cafe.setVisible(false);
        this.cafeCheck.setVisible(true);
        this.cafeCompleted = true;
        this.checkCompleted();
    }

    libraryDialog()
    {
        
        HelpController.erase();
        DialogController.erase();
        this.cleanNextDialog();

        this.library.scaleX = 0;
        this.library.scaleY = 0;
        this.library.alpha = 0;

        this.library.x = this.libraryArea.x;
        this.library.y = this.libraryArea.y;

        this.library.setVisible(true);

        this.libraryTween = this.tweens.add({
            targets: this.library,
            scaleX: 1,
            scaleY: 1,
            alpha: 1,
            x: 0,
            y: 0,
            ease: 'Linear',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
            duration: 800
        });

        
        this.timerLibrary = this.time.delayedCall(4000, function(){

            RessourcesController.setRessource('update-devices');

            this.dialog("weston", Text.get("c6s1-dialog7"));
            this.dialog("theo", Text.get("c6s1-dialog8"));
            this.dialog("weston", Text.get("c6s1-dialog9"));
            this.dialog("theo", Text.get("c6s1-dialog10"));
            this.dialog("weston", Text.get("c6s1-dialog11"), function(scene){
                this.scene.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                    this.removeEventListener(DialogEvent.CLOSE, handler);
                    var scene = MainController.getCurrentScene();
        
                    scene.closeLibrary();
                });
                
            }, this);

        }, [], this);
    }


    closeLibrary()
    {
        

        this.closeLibraryTween = this.tweens.add({
            targets: this.library,
            scaleX: 0,
            scaleY: 0,
            alpha: 0,
            x: this.libraryArea.x,
            y: this.libraryArea.y,
            ease: 'Back',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
            duration: 800,
            onComplete: this.closeLibraryComplete.bind(this)
        });
    }

    closeLibraryComplete()
    {
        this.library.setVisible(false);
        this.libraryCheck.setVisible(true);
        this.libraryCompleted = true;
        this.checkCompleted();
    }

    

    sportDialog()
    {

        HelpController.erase();
        DialogController.erase();
        this.cleanNextDialog();

        this.sport.scaleX = 0;
        this.sport.scaleY = 0;
        this.sport.alpha = 0;

        this.sport.x = this.sportArea.x;
        this.sport.y = this.sportArea.y;

        this.sport.setVisible(true);

        this.sportTween = this.tweens.add({
            targets: this.sport,
            scaleX: 1,
            scaleY: 1,
            alpha: 1,
            x: 0,
            y: 0,
            ease: 'Linear',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
            duration: 800
        });

        
        this.timerSport= this.time.delayedCall(4000, function(){

            RessourcesController.setRessource('personal-datas');

            this.dialog("weston", Text.get("c6s1-dialog12"));
            this.dialog("theo", Text.get("c6s1-dialog13"));
            this.dialog("weston", Text.get("c6s1-dialog14"));
            this.dialog("theo", Text.get("c6s1-dialog15"));
            this.dialog("weston", Text.get("c6s1-dialog16"));
            this.dialog("theo", Text.get("c6s1-dialog17"));
            this.dialog("weston", Text.get("c6s1-dialog18"), function(scene){
                this.scene.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                    this.removeEventListener(DialogEvent.CLOSE, handler);
                    var scene = MainController.getCurrentScene();
        
                    scene.closeSport();
                });
                
            }, this);

        }, [], this);
    }


    closeSport()
    {
        this.closeSportTween = this.tweens.add({
            targets: this.sport,
            scaleX: 0,
            scaleY: 0,
            alpha: 0,
            x: this.sportArea.x,
            y: this.sportArea.y,
            ease: 'Back',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
            duration: 800,
            onComplete: this.closeSportComplete.bind(this)
        });
    }

    closeSportComplete()
    {
        this.sport.setVisible(false);
        this.sportCheck.setVisible(true);
        this.sportCompleted = true;
        this.checkCompleted();
    }

    roomDialog()
    {

        HelpController.erase();
        DialogController.erase();
        this.cleanNextDialog();

        this.room.scaleX = 0;
        this.room.scaleY = 0;
        this.room.alpha = 0;

        this.room.x = this.roomArea.x;
        this.room.y = this.roomArea.y;

        this.room.setVisible(true);

        this.roomTween = this.tweens.add({
            targets: this.room,
            scaleX: 1,
            scaleY: 1,
            alpha: 1,
            x: 0,
            y: 0,
            ease: 'Linear',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
            duration: 800
        });

        
        this.timerRoom = this.time.delayedCall(4000, function(){
            
            RessourcesController.setRessource('secure-office');

            this.dialog("weston", Text.get("c6s1-dialog19"));
            
            this.quizUniqueChoice("theo", Text.get("c6s1-dialog20"),
                [
                    {
                        "id": "q1-choice1",
                        "label": Text.get("c6s1-dialog21"),
                        "value": 0
                    },
                    {
                        "id": "q1-choice2",
                        "label": Text.get("c6s1-dialog22"),
                        "value": 0
                    },
                    {
                        "id": "q1-choice3",
                        "label": Text.get("c6s1-dialog23"),
                        "value": 1
                    }
                ],
                function(result){
                    DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
            
                    if(result[0].value == 1)
                    {
                        this.scene.dialog("weston", Text.get("c6s1-dialog24"));
                    }else
                    {
                        this.scene.dialog("weston", Text.get("c6s1-dialog25"));
                    }
                    this.scene.dialog("theo", Text.get("c6s1-dialog26"));
                    this.scene.dialog("weston", Text.get("c6s1-dialog27"), function(scene){
                        this.scene.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                            this.removeEventListener(DialogEvent.CLOSE, handler);
                            var scene = MainController.getCurrentScene();
                
                            scene.closeRoom();
                        });
                        
                    }, this.scene);

                },
                null,
                this
            );


        }, [], this);
        
    }

    closeRoom()
    {
        this.closeRoomTween = this.tweens.add({
            targets: this.room,
            scaleX: 0,
            scaleY: 0,
            alpha: 0,
            x: this.roomArea.x,
            y: this.roomArea.y,
            ease: 'Back',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
            duration: 800,
            onComplete: this.closeRoomComplete.bind(this)
        });
    }

    closeRoomComplete()
    {
        this.room.setVisible(false);
        this.roomCheck.setVisible(true);
        this.roomCompleted = true;
        this.checkCompleted();
    }

    checkCompleted()
    {
        if(this.libraryCompleted && this.cafeCompleted && this.roomCompleted && this.sportCompleted)
        {

            this.quizzCafe();
        
        }
    }

    quizzCafe()
    {
        
            DialogController.erase();
            this.cleanNextDialog();

            this.timerQuizz = this.time.delayedCall(3000, function(){
                this.dialog("weston", Text.get("c6s1-dialog28"));
            
                this.quizMultipleChoice("theo", Text.get("c6s1-dialog29"),
                    [
                        {
                            "id": "q-cafe-choice5",
                            "label": Text.get("c6s1-dialog30"),
                            "value": 1
                        },
                        {
                            "id": "q-cafe-choice1",
                            "label": Text.get("c6s1-dialog31"),
                            "value": 0
                        },
                        {
                            "id": "q-cafe-choice2",
                            "label": Text.get("c6s1-dialog32"),
                            "value": 0
                        },
                        {
                            "id": "q-cafe-choice3",
                            "label": Text.get("c6s1-dialog33"),
                            "value": 1
                        },
                        {
                            "id": "q-cafe-choice4",
                            "label": Text.get("c6s1-dialog34"),
                            "value": 1
                        },
                        {
                            "id": "q-cafe-choice6",
                            "label": Text.get("c6s1-dialog35"),
                            "value": 0
                        }
                    ],
                    function(result){
                        DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
        

                        var success = true;
                        var total = 0;
                        for (var i = 0; i < result.length; i++) 
                        {
                            if(result[i].value == 0)
                            {
                                success = false;
                                break;
                            }else
                            {
                                total++;
                            }
                        }
        
                        if(total != 3)
                        {
                            success = false;
                        }
        
                        if(success)
                        {
                            this.scene.successQuizzCafe();
                        }else
                        {
                            this.scene.failureQuizzCafe();
                        }
                    },
                    null,
                    this
                );


            }, [], this);
    }

    successQuizzCafe()
    {
        this.dialog("weston", Text.get("c6s1-dialog36"));
        this.quizzLibrary();
    }

    failureQuizzCafe()
    {
        this.dialog("weston", Text.get("c6s1-dialog37"));
        this.quizzLibrary();
    }


    quizzLibrary()
    {
        
            this.cleanNextDialog();

            this.timerQuizz = this.time.delayedCall(3000, function(){
                this.dialog("weston", Text.get("c6s1-dialog38"));
            
                this.quizMultipleChoice("theo", Text.get("c6s1-dialog39"),
                    [
                        {
                            "id": "q-library-choice1",
                            "label": Text.get("c6s1-dialog40"),
                            "value": 0
                        },
                        {
                            "id": "q-library-choice2",
                            "label": Text.get("c6s1-dialog41"),
                            "value": 1
                        },
                        {
                            "id": "q-library-choice3",
                            "label": Text.get("c6s1-dialog42"),
                            "value": 1
                        },
                        {
                            "id": "q-library-choice4",
                            "label": Text.get("c6s1-dialog43"),
                            "value": 0
                        },
                        {
                            "id": "q-library-choice5",
                            "label": Text.get("c6s1-dialog44"),
                            "value": 0
                        }
                    ],
                    function(result){
                        DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
        

                        var success = true;
                        var total = 0;
                        for (var i = 0; i < result.length; i++) 
                        {
                            if(result[i].value == 0)
                            {
                                success = false;
                                break;
                            }else
                            {
                                total++;
                            }
                        }
        
                        if(total != 2)
                        {
                            success = false;
                        }
        
                        if(success)
                        {
                            this.scene.successQuizzLibrary();
                        }else
                        {
                            this.scene.failureQuizzLibrary();
                        }
                    },
                    null,
                    this
                );


            }, [], this);
    }

    successQuizzLibrary()
    {
        this.dialog("weston", Text.get("c6s1-dialog45"));
        this.quizzRoom();
    }

    failureQuizzLibrary()
    {
        this.dialog("weston", Text.get("c6s1-dialog46"));
        this.quizzRoom();
    }


    quizzRoom()
    {
        
            this.cleanNextDialog();

            this.timerQuizz = this.time.delayedCall(3000, function(){
                this.dialog("weston", Text.get("c6s1-dialog47"));
                
                this.quizFillIn("theo", Text.get("c6s1-dialog48"),
                Text.get("c6s1-dialog49"),
                    function(result){
                        DialogController.DisplayNext();

                        if( (result[0].value.toLowerCase() == Text.get("c6s1-dialog50")) && (result[1].value.toLowerCase() == Text.get("c6s1-dialog51")) && (result[2].value.toLowerCase() == Text.get("c6s1-dialog51")) && (result[3].value.toLowerCase() == Text.get("c6s1-dialog50"))  )
                        {
                            this.scene.successQuizzRoom();
                        }else
                        {
                            this.scene.failureQuizzRoom();
                        }
                
                        
                    },
                    null,
                    this
                );


            }, [], this);
    }

    successQuizzRoom()
    {
        this.dialog("weston", Text.get("c6s1-dialog52"));
        this.endDialog();
    }

    failureQuizzRoom()
    {
        this.dialog("weston", Text.get("c6s1-dialog53"));
        this.endDialog();
    }

    endDialog()
    {
        this.dialog("weston", Text.get("c6s1-dialog54"), function(){
            if(MainController.isMobile())
            {
                HelpController.add( Text.get("c6s1-help2")+"<a class='button' target='flirter' href='"+MainController.getFlirterURL()+"/c6s1/"+MainController.getToken()+"'>"+Text.get("c6s1-help2_1")+"</a>" );
            }else
            {
                HelpController.add( Text.get("c6s1-help3")+"<img src='https://api.qrserver.com/v1/create-qr-code/?data="+MainController.getFlirterURL()+"/c6s1/"+MainController.getToken()+"&size=180x180'><br><a target='flirter' href='"+MainController.getFlirterURL()+"/c6s1/"+MainController.getToken()+"'>"+Text.get("c6s1-help3_1")+"</a>" );
            }


           this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                this.removeEventListener(DialogEvent.CLOSE, handler);
                var scene = MainController.getCurrentScene();
                scene.goToScene('chapter6Scene2');
            });
        });
        

    }


    beforeTransition()
    {
        
        this.tweens.add({
            targets:  this.musicAudio,
            volume:   0,
            duration: 1000
        });

    } 

    beforeDestroy()
    {
        this.musicAudio.destroy();
    }

}
export {Chapter6Scene1}