import apiService from "@/services/apiService";
import cookieService from "@/services/cookieService";

class DatasController {

	constructor() {
        if (!DatasController.instance) {
            return DatasController.instance;
        }

        DatasController.instance = this;

        return this;
    }

	static init(token)
	{

		this.token = token;
		this.scorm = false;
		this.datas = {};
		this.bookmark = null;
	}

	static getDatas()
	{
		if(!this.scorm)
		{
			this.datas = this.getWebDatas();
			return this.datas;
		}
	}

	static getMe()
	{
		if(!this.scorm)
		{
			this.me = this.getWebMe();
			return this.me;
		}
	}

	static getResults()
	{
		if(!this.scorm)
		{
			this.results = this.getWebResults();
			return this.results;
		}
	}

	static getBookmark()
	{
		if(!this.scorm)
		{
			this.datas = this.getWebBookmark();
			return this.datas;
		}
	}

	static getWebMe()
	{
		if(this.token != "development_token")
		{
			
			 return apiService
		      .get('/user/me', true)
		      .then(response => {
		      		return response.data;
		      	}
		      )
		      .catch(error => {
		      		
		      		return error;
		      	}
		      );		  				
		}else
		{
			// on retourne la valeur locale si on est en mode debug env de dev
			return new Promise((resolve, reject) => {
					    
					    resolve();
					})
					.then(() => {
					    return this.me;
					})
					.catch(() => {
					    return 'error dev';
					});
		}
	}


	static getWebResults()
	{
		if(this.token != "development_token")
		{
			
			 return apiService
		      .get('/user/results', true)
		      .then(response => {
		      		return response.data;
		      	}
		      )
		      .catch(error => {
		      		
		      		return error;
		      	}
		      );		  				
		}else
		{
			// on retourne la valeur locale si on est en mode debug env de dev
			return new Promise((resolve, reject) => {
					    
					    resolve();
					})
					.then(() => {
					    return this.results;
					})
					.catch(() => {
					    return 'error dev';
					});
		}
	}


	static getWebDatas()
	{
		if(this.token != "development_token")
		{
			
			 return apiService
		      .get('/user/getdatas', true)
		      .then(response => {
		      		return JSON.parse(response.data);

		      	}
		      )
		      .catch(error => {
		      		
		      		return error;
		      	}
		      );		  				
		}else
		{
			// on retourne la valeur locale si on est en mode debug env de dev
			return new Promise((resolve, reject) => {
					    
					    resolve();
					})
					.then(() => {
					    return this.datas;
					})
					.catch(() => {
					    return 'error dev';
					});
		}
	}

	static getWebBookmark()
	{
		if(this.token != "development_token")
		{
			 return apiService
		      .get('/user/getbookmark', true)
		      .then(response => {
				
		      		return JSON.parse(response.data);

		      	}
		      )
		      .catch(error => {
		      		
		      		return error;
		      	}
		      );		  				
		}else
		{
			// on retourne la valeur locale si on est en mode debug env de dev
			return new Promise((resolve, reject) => {
					    
					    resolve();
					})
					.then(() => {
					    return this.datas;
					})
					.catch(() => {
					    return 'error dev';
					});
		}
	}

	static setDatas(datas)
	{

		if(!this.scorm)
		{
			this.setWebDatas(datas);
		}
	}

	static setWebDatas(datas)
	{
		
		if(this.token != "development_token")
		{
		   apiService
		      .post('/user/setdatas',
		      	{
		            datas: JSON.stringify(datas)
		        }, true)
		      .then(response => {
		      	   //console.log(response);
		      	
		      	}
		      )
		      .catch(error => {
		      		console.log(error);

		      		
		      	}
		      );
		}
	}

	static setBookmark(bookmark)
	{

		if(!this.scorm)
		{
			this.setWebBookmark(bookmark);
		}
	}

	static setWebBookmark(bookmark)
	{
		if(this.token != "development_token")
		{
		   apiService
		      .post('/user/setbookmark',
		      	{
		            bookmark: bookmark
		        }, true)
		      .then(response => {
		      	   //console.log(response);
		      	
		      	}
		      )
		      .catch(error => {
		      		console.log(error);

		      		
		      	}
		      );
		}
	}


}
export {DatasController}