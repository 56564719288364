import $ from "jquery";
import { MainController } from "./mainController";
import { SoundController } from "./soundController";

class HelpController {

	constructor() {
        if (!HelpController.instance) {
            return HelpController.instance;
        }

        HelpController.instance = this;
        
        return this;
    }

	static init()
	{

		this.opened = false;

		MainController.stopPropagation();
		$("#interface nav .help").on("click", function(e){
			HelpController.toggle();
			SoundController.play('assets/audios/click-help.mp3');

			e.stopPropagation();
		})

	}

	static add(message)
	{
		
		var htmlMessage = "<div class='message'><div class='close'><a></a></div><div class='text'>"+message+"</div></div>";
		$(htmlMessage).hide().prependTo("#help .messages").fadeIn(500).addClass("active");

		MainController.stopPropagation();
		$("#help .messages .close a").on("click", function(e){
			$(this).parent().parent().removeClass("active").fadeOut(500, function(e){
				this.remove();
				if($("#help .messages").children().length == 0)
				{
					HelpController.close();
					$("#interface nav a.help").removeClass("active");
				}
			});
			e.stopPropagation();
		})

		$("#interface nav a.help").addClass("active");
		HelpController.open();
		SoundController.play('assets/audios/help-on.mp3');

	}

	static erase()
	{
		$("#help .messages .message").removeClass("active").each(function(){
				this.remove();
				
		});
		$("#interface nav a.help").removeClass("active");
	}

	static clear()
	{
		$("#help .messages .message").removeClass("active").fadeOut(500, function(e){
				this.remove();
				HelpController.close();
		});
		$("#interface nav a.help").removeClass("active");
	}

	static toggle()
	{
		if(this.opened)
		{
			HelpController.close();
		}else
		{
			HelpController.open();
		}
	}

	static open()
	{
		$("#help").addClass("active").hide().fadeIn(500);

		this.opened = true;

	}

	static close()
	{
		$("#help").fadeOut(500, function(e){
				
				$(this).removeClass("active");
			});

		this.opened = false;
	}


}
export {HelpController}