import { DialogController } from "../controller/dialogController";
import { HelpController } from "../controller/helpController";
import { MainController } from "../controller/mainController";
import { RessourcesController } from "../controller/ressourcesController";
import { GameScene } from "../display/gameScene";
import { ParallaxScene } from "../display/parallaxScene";
import { DialogEvent } from "../events/dialogEvent";
import { Text } from "../display/text";

class Chapter10Scene1 extends GameScene {



    constructor() {
        super("chapter10Scene1");
        this.title = Text.get("c10s1-title");
        this.subtitle = Text.get("c10s1-subtitle");
    } 

    preload ()
    {
        super.preload();
        this.load.image('c9-s1-background', 'assets/images/chapters/chapter9/scene1/background.jpg');
        this.load.image('c9-s1-firstplan', 'assets/images/chapters/chapter9/scene1/firstplan.png');
        this.load.image('c9-s1-secondplan', 'assets/images/chapters/chapter9/scene1/secondplan.png');

        this.load.audio('ambient-stress', 'assets/audios/musics/ambiance-stress.mp3');

        this.load.audio('tone-busy', 'assets/audios/tone-busy.mp3');
        this.load.audio('call-accept', 'assets/audios/call-accept.mp3');
        this.load.audio('call-decline', 'assets/audios/call-decline.mp3');
        this.load.audio('ringtone', 'assets/audios/ringtone.mp3');
        this.load.audio('num-tone', 'assets/audios/num-tone.mp3');
        this.load.audio('pick-up-phone', 'assets/audios/pick-up-phone.mp3');
    }

    create ()
    {
        
        super.create();

        this.setParallaxDistance( ParallaxScene.DISTANCE_NEAR );

        this.background = this.add.image(0,0, 'c9-s1-background');
        this.secondplan = this.add.image(-250, 150, 'c9-s1-secondplan');
        this.firstplan = this.add.image(-800, 400, 'c9-s1-firstplan');


        this.ringtone = this.sound.add('ringtone',{
            loop: true
        });
        this.numTone = this.sound.add('num-tone');
        this.pickupAudio = this.sound.add('pick-up-phone');

        this.ringtone.play();
        this.callAccept = this.sound.add('call-accept');
        this.callDecline = this.sound.add('call-decline');

        this.toneBusy = this.sound.add('tone-busy');
        this.musicAudio = this.sound.add(
                'ambient-stress', 
                {
                    volume: 0.5,
                    loop: true
                });

        this.musicAudio.play();

        this.addToScene( this.background, 3 );
        this.addToScene( this.secondplan, 2 );
        this.addToScene( this.firstplan, 1 );

        this.timerStart = this.time.delayedCall(2000, function(){
            
           this.firstDialog();

        }, [], this);
    

        MainController.setCurrentScene( this );


    }

    firstDialog()
    {
        this.callAccept.play();
        this.ringtone.stop();
        this.dialog("theo", Text.get("c10s1-dialog1"));
        this.dialog("weston", Text.get("c10s1-dialog2"));
        this.dialog("theo", Text.get("c10s1-dialog3"));
        this.dialog("weston", Text.get("c10s1-dialog4"), function(scene){
            

            this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                this.removeEventListener(DialogEvent.CLOSE, handler);
                var scene = MainController.getCurrentScene();

                scene.callDecline.play();


                scene.time.delayedCall(5000, function(){
                    
                    this.secondDialog();
        
                }, [], scene);

                
            });

        }, this);
    }

    secondDialog()
    {
        DialogController.erase();
        this.cleanNextDialog();

        this.dialog("theo", Text.get("c10s1-dialog5"));
        this.dialog("theo", Text.get("c10s1-dialog6"), function(scene){
            
            scene.timerHelpExif = scene.time.delayedCall(10000, function(){
                HelpController.add(Text.get("c10s1-help1"));
            }, [], scene);

            scene.timerHelpAddress = scene.time.delayedCall(30000, function(){
                HelpController.add(Text.get("c10s1-help2"));
            }, [], scene);

            scene.timerHelpTotal = scene.time.delayedCall(120000, function(){
                HelpController.add(Text.get("c10s1-help3"));
            }, [], scene);

        }, this);

        this.quizFillIn(
            "theo", 
            Text.get("c10s1-dialog7"),
            Text.get("c10s1-dialog8"),
            function(result){
                DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
                
                if( (result[0].value.toUpperCase() == Text.get("c10s1-dialog9")) )
                {
                    MainController.completeChapter('chapter10');

                    this.scene.timerHelpExif.remove();
                    this.scene.timerHelpAddress.remove();
                    this.scene.timerHelpTotal.remove();

                    HelpController.erase();

                    this.scene.dialog("theo", Text.get("c10s1-dialog10"), function(scene){
                        scene.finalDialog();
                    }, this.scene);
                }else
                {
                    
                    HelpController.add( Text.get("c10s1-help4"));
                    
                }

                

            },
            null,
            this,
            true // true pour multipleChances
        );
    }

    finalDialog()
    {
        // on lance l'appel tél
        this.numTone.play();
        this.time.delayedCall(4000, function(){
            
            this.cleanNextDialog();

            this.dialog("weston", Text.get("c10s1-dialog11"),function(scene){
                scene.numTone.stop();
            }, this);
            this.dialog("theo", Text.get("c10s1-dialog12"));
            this.dialog("weston", Text.get("c10s1-dialog13"));
            this.dialog("theo", Text.get("c10s1-dialog14"));
            this.dialog("weston", Text.get("c10s1-dialog15"));
            this.dialog("theo", Text.get("c10s1-dialog16"));
            this.dialog("weston", Text.get("c10s1-dialog17"));
            this.dialog("theo", Text.get("c10s1-dialog18"), function(){
                RessourcesController.setRessource('catfishing');
            });
            this.dialog("weston", Text.get("c10s1-dialog19"));
            this.dialog("theo", Text.get("c10s1-dialog20"));
            this.dialog("weston", Text.get("c10s1-dialog21"));
            this.dialog("theo", Text.get("c10s1-dialog22"));
            this.dialog("weston", Text.get("c10s1-dialog23"));
            this.quizUniqueChoice("theo", Text.get("c10s1-dialog24"),
            [
                {
                    "id": "q1-choice1",
                    "label": Text.get("c10s1-dialog25"),
                    "value": 0
                },
                {
                    "id": "q1-choice2",
                    "label": Text.get("c10s1-dialog26"),
                    "value": 1
                }
            ],
            function(result){
                this.scene.endDialog();
                
            },
            null,
            this
        );

        }, [], this);

        
    }

    endDialog()
    {
        this.toneBusy.play();

        this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
            this.removeEventListener(DialogEvent.CLOSE, handler);
            var scene = MainController.getCurrentScene();

            if(scene.endTimeOut)
            {
                clearTimeout(scene.endDialog);
            }

            scene.goToScene('chapter11Intro');
        });

        this.endTimeOut = setTimeout(()=> {
            var scene = MainController.getCurrentScene();
            scene.goToScene('chapter11Intro');
        }, 3000);
    }



    beforeTransition()
    {
        
        
        this.tweens.add({
            targets:  this.musicAudio,
            volume:   0,
            duration: 1000
        });
        

    }

    beforeDestroy()
    {
        this.musicAudio.destroy();
        this.toneBusy.destroy();
        this.ringtone.destroy();
    }   


}
export {Chapter10Scene1}