import { DialogController } from "../controller/dialogController";
import { HelpController } from "../controller/helpController";
import { MainController } from "../controller/mainController";
import { GameScene } from "../display/gameScene";
import { ParallaxScene } from "../display/parallaxScene";
import { Text } from "../display/text";
import { DialogEvent } from "../events/dialogEvent";

class Chapter1Scene1 extends GameScene {



    constructor() {
        super("chapter1Scene1");

        this.title = Text.get('c1s1-title');
        this.subtitle = Text.get('c1s1-subtitle');
    } 

    preload ()
    {
        super.preload();
        this.load.image('c1-s1-background', 'assets/images/chapters/chapter1/scene1/background.jpg');
        this.load.image('c1-s1-desk', 'assets/images/chapters/chapter1/scene1/desk.png');
        this.load.image('c1-s1-phone', 'assets/images/chapters/chapter1/scene1/phone.png');
        this.load.image('c1-s1-chair', 'assets/images/chapters/chapter1/scene1/chair.png');

        this.load.audio('pick-up-phone', 'assets/audios/pick-up-phone.mp3');
        this.load.audio('num-tone', 'assets/audios/num-tone.mp3');
        this.load.audio('tone', 'assets/audios/tone.mp3');
        this.load.audio('tone-busy', 'assets/audios/tone-busy.mp3');
        this.load.audio('ring-phone', 'assets/audios/ring-phone.mp3');
        this.load.audio('ambient-detente', 'assets/audios/musics/ambiance-detente.mp3');
       
    }

    create ()
    {
        super.create();


        this.setParallaxDistance( ParallaxScene.DISTANCE_NEAR );

        this.background = this.add.image(0,0, 'c1-s1-background');


        this.desk = this.add.image(50, 300, 'c1-s1-desk');
        this.phone = this.add.image(650,70, 'c1-s1-phone');
        this.phone.setInteractive();


        this.chair = this.add.image(340,665, 'c1-s1-chair');
        

        this.musicAudio = this.sound.add(
                'ambient-detente', 
                {
                    volume: 0.5,
                    loop: true
                });

        this.ringPhone = this.sound.add(
                'ring-phone', 
                {
                    volume: 0.5,
                    loop: true
                });


        

        this.musicAudio.play();

        this.pickupAudio = this.sound.add('pick-up-phone');
        this.toneBusy = this.sound.add('tone-busy');
        this.tone = this.sound.add('tone');
        this.numTone = this.sound.add('num-tone');

        

        

        this.addToScene( this.background, 3 );
        this.addToScene( this.desk, 2 );
        this.addToScene( this.phone, 2 );
        this.addToScene( this.chair, 1 );

        
        this.timerHelp = this.time.delayedCall(10000, function(){
            HelpController.add( Text.get('c1s1-help1') );
        }, [], this);


        this.timer = this.time.delayedCall(5000, function(){
                
            this.ringPhone.play();
            

            this.phoneTween = this.tweens.add({
                targets: this.phone,
                x: "+=2",
                ease: 'Linear',       // 'Cubic', 'Elastic', 'Bounce', 'Back'
                duration: 50,
                repeat: -1,            // -1: infinity
                yoyo: true
            });

            this.phone.once('pointerdown', function(){
                this.timerHelp.remove();
                HelpController.clear();

                this.pickupAudio.play();
                this.ringPhone.stop();
                this.phoneTween.stop();

                this.firstDialogSequence();

            
              

            }, this);

            
        }, [], this);


    
        MainController.setCurrentScene( this );
        

    }

    firstDialogSequence()
    {
        this.dialog("theo", Text.get('c1s1-dialog1') );
        this.dialog("emily", Text.get('c1s1-dialog2') );
        this.dialog("theo", Text.get('c1s1-dialog3'), function(scene){
  
            scene.secondDialogSequence();
        }, this);
    }

    secondDialogSequence()
    {
      
        
        this.numTone.play();

        this.cleanNextDialog();
        this.callNextDialog(function(scene){
            scene.numTone.stop();
        }, this);

        this.dialog("weston", Text.get('c1s1-dialog4'));
        this.dialog("theo", Text.get('c1s1-dialog5'));
        this.dialog("weston", Text.get('c1s1-dialog6'));
        this.dialog("theo", Text.get('c1s1-dialog7'));
        this.dialog("weston", Text.get('c1s1-dialog8'), function(scene){
            
            scene.thirdDialogSequence();
        }, this);
    }

    thirdDialogSequence()
    {
        
        this.tone.play();

        this.cleanNextDialog();
        this.callNextDialog(function(scene){
            scene.tone.stop();
        }, this);


        this.dialog("weston", Text.get('c1s1-dialog9'));
        this.dialog("emily", Text.get('c1s1-dialog10'), function(scene){
            DialogController.DisplayNext(); // je le force le futur dialog à apparaitre sans passer par l'étape bouton à cliquer
            scene.fourthDialogSequence();
        }, this);


    }

    fourthDialogSequence()
    {
        this.dialog("weston", Text.get('c1s1-dialog11'));
        this.dialog("emily", Text.get('c1s1-dialog12'));
        this.dialog("weston", Text.get('c1s1-dialog13'));
        this.dialog("emily", Text.get('c1s1-dialog14'), function(scene){
            
            scene.fifthDialogSequence();
        }, this);
    }
  
    fifthDialogSequence()
    {
        
        this.toneBusy.play();

        this.cleanNextDialog();
        this.callNextDialog(function(scene){
            scene.toneBusy.stop();
        }, this);

        this.dialog("weston", Text.get('c1s1-dialog15'));
        this.dialog("theo", Text.get('c1s1-dialog16'));
        this.dialog("weston", Text.get('c1s1-dialog17'));
        this.dialog("theo", Text.get('c1s1-dialog18'));
        this.dialog("weston", Text.get('c1s1-dialog19'));
        this.dialog("theo", Text.get('c1s1-dialog20'));
        this.dialog("weston", Text.get('c1s1-dialog21'));
        this.dialog("theo", Text.get('c1s1-dialog22'));
        this.dialog("weston", Text.get('c1s1-dialog23'), function(scene){
            scene.endDialog();
        }, this);
    }

    endDialog()
    {
        this.toneBusy.play();

        this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
            this.removeEventListener(DialogEvent.CLOSE, handler);
            var scene = MainController.getCurrentScene();

            scene.goToScene('chapter1Scene2');
        });
    }



    beforeTransition()
    {
        
        
        this.tweens.add({
            targets:  this.musicAudio,
            volume:   0,
            duration: 1000
        });
        

    }

    beforeDestroy()
    {
        this.musicAudio.destroy();
        this.ringPhone.destroy();
    }


}
export {Chapter1Scene1};