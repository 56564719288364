<template>
  <div>
      <div id="game"></div>
      <div id="interface">
          <nav>
              <a class="menu active"></a>
              <a class="help"></a>
              <a class="dialog"></a>
              <a class="ressources"></a>
          </nav>
      </div>
      <div id="dialog">
          <div class="messages">
              
              <div class="stack"></div>
              <a class="dialog-bt dialog-next"></a>
              <a class="dialog-bt dialog-close"></a>

          </div>
      </div>
      <div id="help">
          <div class="messages">            
          </div>
      </div>
      <div id="ressources">
          <div class="contener">
              <a class="close"></a>
              <div class="panel">
                  <div class="index">
                      <div class="header" data-translate-content="interface-ressources-title">
                      </div>
                      <ul class="list">
                      </ul>
                  </div>
                  <div class="body">
                      <iframe name="ressources" class="content" src="" ></iframe>
                  </div>
              </div>
          </div>
      </div>
      <div id="menu">
          <a class="close"></a>
          <div class="contener">
              <div class="logo-game">
                  <img data-translate-src="interface-menu-logo">    
              </div>
              
              <ul id="summary" class="list">                  
              </ul>

              <ul class="list">
                  <li><a data-translate-content="interface-menu-result-cyber" id="cyberButton"></a></li>
                  <li><a data-translate-content="interface-menu-client-space" id="clientSpaceButton"></a></li>
                  <li><a data-translate-content="interface-menu-quit" id="quitGameButton"></a></li>
              </ul>
              <ul class="list">
                  <li><a data-translate-content="interface-menu-help" id="helpButton"></a></li>
                  <li><a data-translate-content="interface-menu-credits" @click="openCredits()"></a></li>
              </ul>
              <div class="logos-authors">
                <a href="https://www.cnam-auvergnerhonealpes.fr/" target="_blank"><img src="@/game/assets/images/interface/logo-cnam-menu.svg"></a> <br>
                  <a href="https://www.avant-gout.com/" target="_blank"><img src="@/game/assets/images/interface/logo-ags-menu.svg"></a>
              </div>
          </div>
      </div>


      <!-- Modal startGame -->
      <div class="modal fade" id="startGame" tabindex="-1" data-bs-backdrop="static" role="dialog" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
          <div class="modal-content">
              
            <div class="modal-header">
              <h5 class="modal-title">Bienvenue sur le site norbertweston.com</h5>
            </div>
            <div class="modal-body">

                      <p>Que souhaitez-vous faire ?</p>
                      
                      <div class="d-flex flex-column align-items-center">
                          <button type="button" class="btn btn-primary continueButton">Continuer votre partie</button>
                          <br>
                          <button type="button" class="btn btn-primary startButton">Commencer une nouvelle partie</button>
                          <br>
                      </div>
                    
                    

              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary quitButton">Quitter</button>
              </div>  
              
          </div>
        </div>
      </div>
      <!-- Modal /startGame -->   


      <!-- Modal startQuiz -->
      <div class="modal fade quizzModal" id="startQuiz" tabindex="-1" data-bs-backdrop="static" role="dialog" data-bs-keyboard="false">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered" role="document">
          <div class="modal-content">
              
            <div class="modal-header">
              <h5 class="modal-title">Quelles sont vos compétences en cybersécurité ?</h5>
            </div>
            <div class="modal-body">

                      <p>Remplissez le questionnaire ci-dessous pour mesurer vos compétences en cybersécurité en début de jeu.</p>
                      <p>Vous serez évalué sur un deuxième questionnaire en fin de jeu pour mesurer vos progrès.</p>
                      <p><strong>C'est parti !</strong></p>
                      
                      <div v-for="(question, qIndex) in startQuiz" class="form-group mt-5" v-bind:key="qIndex">
                          
                          <div v-if="question.type == 'unique-choice'">
                            <p><strong>{{(qIndex+1)}}. <span v-html="question.label"></span></strong></p>
                            <div v-for="(answer, aIndex) in question.answers" v-bind:key="aIndex">
                              <input class="form-check-input" type="radio" v-model="startQuiz[qIndex].value" :id="'Sq'+qIndex+'a'+aIndex" :value="aIndex">
                              <label class="form-check-label" :for="'Sq'+qIndex+'a'+aIndex" v-html="answer.label"></label>
                            </div>
                          </div>

                          <div v-if="question.type == 'multiple-choice'">
                            <p><strong>{{(qIndex+1)}}. <span v-html="question.label"></span></strong></p>
                            <div v-for="(answer, aIndex) in question.answers" v-bind:key="aIndex">
                              <input class="form-check-input" type="checkbox" v-model="startQuiz[qIndex].value" :id="'Sq'+qIndex+'a'+aIndex" :value="aIndex">
                              <label class="form-check-label" :for="'Sq'+qIndex+'a'+aIndex" v-html="answer.label">
                              </label>
                            </div>
                          </div>


                      </div>


                    

              </div>
              <div class="modal-footer">
                  <button :disabled="!checkStartQuizComplete()" type="button" class="btn btn-primary validButton" @click="checkStartQuiz()">Valider</button>
              </div>  
              
          </div>
        </div>
      </div>
      <!-- Modal /startQuiz -->   



      <!-- Modal endQuiz -->
      <div class="modal fade" id="endQuiz" tabindex="-1" data-bs-backdrop="static" role="dialog" data-bs-keyboard="false">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered" role="document">
          <div class="modal-content">
              
            <div class="modal-header">
              <h5 class="modal-title">Bravo vous avez fini le jeu !!</h5>
            </div>
            <div class="modal-body">

                      <p>Quelles sont vos nouvelles compétences en cybersécurité ?</p>  
                      <p>Remplissez le questionnaire ci-dessous pour mesurer vos progrès.</p>
                      <p><strong>C'est parti !</strong></p>

                      <div v-for="(question, qIndex) in endQuiz" class="form-group mt-5" v-bind:key="qIndex">
                      
                        <div v-if="question.type == 'unique-choice'">
                          <p><strong>{{(qIndex+1)}}. <span v-html="question.label"></span></strong></p>
                          <div v-for="(answer, aIndex) in question.answers" v-bind:key="aIndex">
                            <input class="form-check-input" type="radio" v-model="endQuiz[qIndex].value" :id="'Eq'+qIndex+'a'+aIndex" :value="aIndex">
                            <label class="form-check-label" :for="'Eq'+qIndex+'a'+aIndex" v-html="answer.label"></label>
                          </div>
                        </div>

                        <div v-if="question.type == 'multiple-choice'">
                          <p><strong>{{(qIndex+1)}}. <span v-html="question.label"></span></strong></p>
                          <div v-for="(answer, aIndex) in question.answers" v-bind:key="aIndex">
                            <input class="form-check-input" type="checkbox" v-model="endQuiz[qIndex].value" :id="'Eq'+qIndex+'a'+aIndex" :value="aIndex">
                            <label class="form-check-label" :for="'Eq'+qIndex+'a'+aIndex" v-html="answer.label"></label>
                          </div>
                        </div>
                      </div>

              </div>
              <div class="modal-footer">
                  <button :disabled="!checkEndQuizComplete()" type="button" class="btn btn-primary validButton" @click="checkEndQuiz()">Valider</button>
              </div>  
              
          </div>
        </div>
      </div>
      <!-- Modal /endQuiz -->   


      <!-- Modal confirmNewGame -->
      <div class="modal fade" id="confirmNewGame" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
          <div class="modal-content">
              
            <div class="modal-header">
              <h5 class="modal-title">Souhaitez-vous recommencer votre partie ?</h5>
            </div>
            <div class="modal-body">

                      <p>En recommençant, la partie actuellement sauvegardée sera effacée.</p>
                      
                
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-default" data-bs-dismiss="modal">Annuler</button>
                  <button type="button" class="btn btn-primary btn-ok confirmNewGameButton">Confirmer</button>
              </div>  
              
          </div>
        </div>
      </div>
      <!-- Modal /confirmNewGame -->  



      <!-- Modal confirmQuit -->
      <div class="modal fade" id="confirmQuit" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
          <div class="modal-content">
              

            <div class="modal-header">
              <h5 class="modal-title">Souhaitez-vous réellement quitter la partie en cours ?</h5>
            </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-default" data-bs-dismiss="modal">Annuler</button>
                  <button type="button" class="btn btn-primary btn-ok confirmQuitButton">Confirmer</button>
              </div>  
              
          </div>
        </div>
      </div>
      <!-- Modal /confirmQuit -->  



      <!-- Modal helpModal -->
      <div class="modal fade" id="helpModal" tabindex="-1" role="dialog">
          
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
              <div class="modal-content">
                  
                    <div class="modal-header">
                      <h5 class="modal-title">Demandez de l'aide</h5>
                    </div>
                    <div class="modal-body">
                      <form @submit="checkHelp" action="/game/help" method="post">
                          <p>
                              Comment pouvons-nous vous aider ? <br>
                              Sélectionnez un sujet et saisissez votre message.
                          </p>
                          
                              <div class="form-group">
                                  <label for="helpFirstname">Votre prénom</label>
                                  <input type="text" v-model="helpFirstname" name="firstname" class="form-control" id="helpFirstname" placeholder="Saisissez votre prénom" required="required">
                              </div>

                              <div class="form-group">
                                  <label for="helpLastname">Votre nom</label>
                                  <input type="text" v-model="helpLastname" name="lastname" class="form-control" id="helpLastname" placeholder="Saisissez votre prénom" required="required">
                              </div>

                              <div class="form-group">
                                  <label for="helpEmail">Votre email</label>
                                  <input type="email" v-model="helpEmail" name="email" class="form-control" id="helpEmail" placeholder="Saisissez votre adresse email" required="required">
                              </div>


                              <div class="form-group">
                                  <label for="helpSubject">Le sujet de votre demande</label>
                                  <select class="form-control" id="helpSubject" name="subject" v-model="helpSubject" required="required">
                                      <option value="Problème informatique">Problème informatique</option>
                                      <option value="Problème dans le jeu">Problème dans le jeu</option>
                                      <option value="Supprimer mon compte">Supprimer mon compte</option>
                                      <option value="Modifier mon compte">Modifier mon compte</option>
                                      <option value="Autre">Autre</option>
                                  </select>
                                  
                              </div>

                              <div class="form-group">
                                  <label for="helpMessage">Votre message</label>
                                  <textarea class="form-control" rows="3" id="helpMessage" name="message" v-model="helpMessage" required="required"></textarea>
                              </div>

                          <!-- alert error -->
                          <div id="errorHelp" class="alert alert-danger" v-bind:class="{ active: isError }" role="alert">{{ helpError }}</div>
                          <!-- /alert error -->

                          <!-- alert success -->
                          <div id="successHelp" class="alert alert-success" v-bind:class="{ active: isSuccess }" role="alert">{{ helpSuccess }}</div>
                          <!-- /alert success -->
                          <br>
                          <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                              <button type="submit" v-bind:disabled="helpBtnDisable" class="btn btn-primary">Envoyer</button>
                          </div>

                      </form>
                    </div>
                    
                  
              </div>
            </div>
          
      </div>
      <!-- Modal /helpModal -->





      <!-- Modal clientSpaceModal -->
      <div class="modal fade" id="clientSpaceModal" tabindex="-1" role="dialog">
          
          <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
            <div class="modal-content">
                
                  <div class="modal-header">
                    <h5 class="modal-title">Votre espace</h5>
                  </div>
                  <div class="modal-body">
                    <form @submit="checkClientSpace" action="/user/editMe" method="post">
                        <p>
                            Vous souhaitez modifier vos informations ? Changer votre mot de passe ? <br>
                            Ou ajouter une clé de licence premium ?
                        </p>
                        
                            <div class="form-group">
                                <label for="clientFirstname">Votre prénom</label>
                                <input type="text" v-model="user.firstName" name="firstname" class="form-control" id="clientFirstname" placeholder="Saisissez votre prénom" required="required">
                            </div>

                            <div class="form-group">
                                <label for="clientLastname">Votre nom</label>
                                <input type="text" v-model="user.lastName" name="lastname" class="form-control" id="clientLastname" placeholder="Saisissez votre prénom" required="required">
                            </div>

                            <div class="form-group">
                                <label for="clientKey">Votre clé de licence premium <small>(facultatif)</small></label>
                                <input :disabled="user.activeKey" type="text" v-model="user.key" name="clientKey" class="form-control" id="clientKey" placeholder="Saisissez votre clé de licence premium">
                            </div>


                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" v-model="user.changePassword" id="clientChangePassword">
                              <label class="form-check-label" for="clientChangePassword">
                                Changer votre mot de passe
                              </label>
                            </div>

                            <div v-if="user.changePassword">
                                <div class="form-group">          
                                  <p>Votre nouveau mot de passe doit :</p>
                                  <ul>
                                    <li v-bind:class="{ is_valid: contains_eight_characters }">avoir une longueur de 8 caractères minimum</li>
                                    <li v-bind:class="{ is_valid: contains_number }">contenir au moins un nombre</li>
                                    <li v-bind:class="{ is_valid: contains_uppercase }">contenir au moins une majuscule</li>
                                    <li v-bind:class="{ is_valid: contains_special_character }">contenir au moins un caractère spécial</li>
                                  </ul>

                                    <input type="password" name="password" class="form-control" id="signupPassword" v-model="user.password" placeholder="Saisissez votre nouveau mot de passe" required @input="checkPassword" autocomplete="off">
                                </div>

                                <div class="form-group">
                                    <label class="pass2" v-bind:class="{ is_valid: passwords_match }" for="signupPassword">Saisissez votre nouveau mot de passe une deuxième fois</label>
                                    <input type="password" name="password2" class="form-control" id="signupPassword2" placeholder="Saisissez à nouveau votre mot de passe" required @input="checkPassword2" v-model="user.password2" autocomplete="off">
                                </div>
                            </div>


                        <!-- alert error -->
                        <div id="errorClientSpace" class="alert alert-danger" v-bind:class="{ active: clientSpaceError }" role="alert">{{ clientSpaceError }}</div>
                        <!-- /alert error -->

                        <!-- alert success -->
                        <div id="successClientSpace" class="alert alert-success" v-bind:class="{ active: clientSpaceSuccess }" role="alert">{{ clientSpaceSuccess }}</div>
                        <!-- /alert success -->
                        <br>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                            <button type="submit" v-bind:disabled="helpBtnDisable" class="btn btn-primary">Sauvegarder</button>
                        </div>

                    </form>
                  </div>
                  
                
            </div>
          </div>
        
    </div>
    <!-- Modal /clientSpaceModal -->





      <!-- Modal cyberModal -->
      <div class="modal fade" id="cyberModal" tabindex="-1" role="dialog">
          
          <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered" role="document">
            <div class="modal-content">
                
                  <div class="modal-header">
                    <h5 class="modal-title">Vos compétences en cybersécurité</h5>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col col-6">
                        <h5>Avant le jeu</h5>
                        <div class="head" v-if="startQuizThemeScores">
                          <div class="avg-score" :class="(getStartQuizThemeScoresAvg() < 25) ? 'red' : (getStartQuizThemeScoresAvg() < 50) ? 'orange' : (getStartQuizThemeScoresAvg() < 75) ? 'yellow' : 'green'">
                            <span>{{getStartQuizThemeScoresAvg()}}%</span>
                          </div>
                        </div>
                        <div v-if="startQuizThemeScores" class="scores">
                          <div v-for="(ts, index) in startQuizThemeScores" v-bind:key="index" class="score" :class="( Math.round(ts.score/ts.maxScore*100) < 25) ? 'red' : (Math.round(ts.score/ts.maxScore*100) < 50) ? 'orange' : (Math.round(ts.score/ts.maxScore*100) < 75) ? 'yellow' : 'green'">
                            <div class="label">{{getThemeNameByCode(ts.code)}}</div>
                            <div class="data">
                              <div class="bar">
                                <div class="data-value" :style="'width: '+(Math.round(ts.score/ts.maxScore*100))+'%;'"></div>
                              </div>
                              <div class="data-text">{{(Math.round(ts.score/ts.maxScore*100))}}%</div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col col-6">
                        <h5>Après le jeu</h5>
                        <div class="head" v-if="endQuizThemeScores">
                          <div class="avg-score" :class="(getEndQuizThemeScoresAvg() < 25) ? 'red' : (getEndQuizThemeScoresAvg() < 50) ? 'orange' : (getEndQuizThemeScoresAvg() < 75) ? 'yellow' : 'green'">
                            <span>{{getEndQuizThemeScoresAvg()}}%</span>
                          </div>
                        </div>
                        <div v-if="endQuizThemeScores" class="scores">
                          <div v-for="(ts, index) in endQuizThemeScores" v-bind:key="index" class="score" :class="( Math.round(ts.score/ts.maxScore*100) < 25) ? 'red' : (Math.round(ts.score/ts.maxScore*100) < 50) ? 'orange' : (Math.round(ts.score/ts.maxScore*100) < 75) ? 'yellow' : 'green'">
                            <div class="label">{{getThemeNameByCode(ts.code)}}</div>
                            <div class="data">
                              <div class="bar">
                                <div class="data-value" :style="'width: '+(Math.round(ts.score/ts.maxScore*100))+'%;'"></div>
                              </div>
                              <div class="data-text">{{(Math.round(ts.score/ts.maxScore*100))}}%</div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                  </div>
                
            </div>
          </div>
        
    </div>
    <!-- Modal /cyberModal -->



      <!-- Modal linkmodal -->
      <div class="modal fade" id="linkmodal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
          <div class="modal-content">
              
                <div class="modal-body">
                  <iframe></iframe>
                </div>
                
                <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                  </div>
          </div>
        </div>
      </div>
      <!-- Modal /linkmodal -->        



    </div>
</template>

<script>
import { PlayerController } from '@/game/assets/js/controller/playerController';
import { MenuController } from '@/game/assets/js/controller/menuController';
import { InterfaceEvent } from '@/game/assets/js/events/interfaceEvent';
import apiService from '@/services/apiService';
import cookieService from '@/services/cookieService';
import {MainController} from '../game/assets/js/controller/mainController'
import { Text } from '@/game/assets/js/display/text';

export default {
  name: 'GameView',
  data: function () {
    return {
		helpError: '',
    helpSuccess: '',
    clientSpaceError: null,
    clientSpaceSuccess: null,
    helpFirstname: '',
    helpLastname: '',
    user: {
      firstName: null,
      lastName: null,
      password: null,
      password2: null,
      key: null,
      changePassword: false,
      activeKey: false
    },
    helpEmail: '',
    helpSubject: '',
    helpMessage: '',
    isError: false,
    isSuccess: false,
    helpBtnDisable: false,
    startQuizThemeScores: [],
    endQuizThemeScores: [],
    startQuiz: [],
    endQuiz: [],
    themeNames: []
    }
  },
  mounted() {
    MainController.init("production", cookieService.getToken(), "null");

    window.addEventListener( InterfaceEvent.CLIENT_SPACE_OPENED, (e)=>{
      this.loadMe();
		});

    window.addEventListener( InterfaceEvent.CYBER_COMPETENCES_OPENED, (e)=>{
      this.loadResults();
		});

    window.addEventListener( InterfaceEvent.START_QUIZ_OPENED, (e)=>{
      this.loadStartQuiz();
		});

    window.addEventListener( InterfaceEvent.END_QUIZ_OPENED, (e)=>{
      this.loadEndQuiz();
		});

    
  },
  
  methods: {
    loadStartQuiz() {
      this.startQuiz = Text.get("startQuiz");
      
    },
    loadEndQuiz() {
      this.endQuiz = Text.get("endQuiz");
      
    },
    getStartQuizThemeScoresAvg() {
      var cumulScore = 0;
      var cumulMaxScore = 0;
      for(let t of this.startQuizThemeScores)
      {
        cumulScore += t.score;
        cumulMaxScore += t.maxScore;
      }

      return Math.round(cumulScore/cumulMaxScore*100);
    },
    getEndQuizThemeScoresAvg() {
      var cumulScore = 0;
      var cumulMaxScore = 0;
      for(let t of this.endQuizThemeScores)
      {
        cumulScore += t.score;
        cumulMaxScore += t.maxScore;
      }

      return Math.round(cumulScore/cumulMaxScore*100);
    },
    getThemeNameByCode(code) {
      for(let t of this.themeNames)
      {
        if(t.code == code)
        {
          return t.name;
        }
      }

      return null;
    },  
    checkStartQuizComplete() {
      for(let q of this.startQuiz)
      {
        if(q.value == null || q.value.length == 0)
        {
          return false;
        }
      }

      return true;
    },
    checkEndQuizComplete() {
      for(let q of this.endQuiz)
      {
        if(q.value == null || q.value.length == 0)
        {
          return false;
        }
      }

      return true;
    },
    indexInStartQuizThemeScores(code)
    {
      for(let i=0; i<this.startQuizThemeScores.length; i++)
      {
        if(this.startQuizThemeScores[i].code == code)
        {
          return i;
        }
      }

      return -1;
    },
    indexInEndQuizThemeScores(code)
    {
      for(let i=0; i<this.endQuizThemeScores.length; i++)
      {
        if(this.endQuizThemeScores[i].code == code)
        {
          return i;
        }
      }

      return -1;
    },    
    getMaxScoreForThemeStart(code)
    {
      var score = 0;
      for(let q of this.startQuiz)
      {
        if(q.theme == code)
        {
          let vMax = 0;
          for(let a of q.answers)
          {
            if(a.value > vMax)
            {
              vMax = a.value;
            }
          }
          score += vMax;
        }
      }

      return score;

    },
    getMaxScoreForThemeEnd(code)
    {
      var score = 0;
      for(let q of this.endQuiz)
      {
        if(q.theme == code)
        {
          let vMax = 0;
          for(let a of q.answers)
          {
            if(a.value > vMax)
            {
              vMax = a.value;
            }
          }
          score += vMax;
        }
      }

      return score;

    },
    checkStartQuiz() {
      this.startQuizThemeScores = [];
      for(let q of this.startQuiz)
      {
        let index = this.indexInStartQuizThemeScores(q.theme);
        if(index > -1)
        {
          if(q.type == "unique-choice")
          {
            this.startQuizThemeScores[index].score += q.answers[q.value].value;
          }else if(q.type == "multiple-choice")
          {
            let success = true;
            for(let i=0; i < q.answers.length; i++)
            {
              if(q.answers[i].value == 1)
              {
                if(!q.value.includes(i))
                {
                  success = false;
                }
              } else
              {
                if(q.value.includes(i))
                {
                  success = false;
                }
              }
            }

            this.startQuizThemeScores[index].score += (success) ? 1 : 0;

          }
          
        }else
        {
          if(q.type == "unique-choice")
          {
            this.startQuizThemeScores.push(
              {
                code: q.theme,
                score: q.answers[q.value].value,
                maxScore: this.getMaxScoreForThemeStart(q.theme)
              }
            );
          }else if(q.type == "multiple-choice")
          {
            let success = true;
            for(let i=0; i < q.answers.length; i++)
            {
              if(q.answers[i].value == 1)
              {
                if(!q.value.includes(i))
                {
                  success = false;
                }
              } else
              {
                if(q.value.includes(i))
                {
                  success = false;
                }
              }
            }

            this.startQuizThemeScores.push(
              {
                code: q.theme,
                score: (success) ? 1 : 0,
                maxScore: this.getMaxScoreForThemeStart(q.theme)
              }
            );
            
          }
        }
      }

      apiService.post('/user/startQuiz', {startQuizData: this.startQuizThemeScores, startQuizForm: this.startQuiz}, true).then(response => {
          
          MainController.completeQuizStart();
      	
      	}
      )
      .catch(error => {
        console.log(error)
          	
      	}
      );

    },
    checkEndQuiz() {
      this.endQuizThemeScores = [];
      for(let q of this.endQuiz)
      {
        let index = this.indexInEndQuizThemeScores(q.theme);
        if(index > -1)
        {
          if(q.type == "unique-choice")
          {
            this.endQuizThemeScores[index].score += q.answers[q.value].value;
          }else if(q.type == "multiple-choice")
          {
            let success = true;
            for(let i=0; i < q.answers.length; i++)
            {
              if(q.answers[i].value == 1)
              {
                if(!q.value.includes(i))
                {
                  success = false;
                }
              } else
              {
                if(q.value.includes(i))
                {
                  success = false;
                }
              }
            }

            this.endQuizThemeScores[index].score += (success) ? 1 : 0;

          }
          
        }else
        {
          if(q.type == "unique-choice")
          {
            this.endQuizThemeScores.push(
              {
                code: q.theme,
                score: q.answers[q.value].value,
                maxScore: this.getMaxScoreForThemeEnd(q.theme)
              }
            );
          }else if(q.type == "multiple-choice")
          {
            let success = true;
            for(let i=0; i < q.answers.length; i++)
            {
              if(q.answers[i].value == 1)
              {
                if(!q.value.includes(i))
                {
                  success = false;
                }
              } else
              {
                if(q.value.includes(i))
                {
                  success = false;
                }
              }
            }

            this.endQuizThemeScores.push(
              {
                code: q.theme,
                score: (success) ? 1 : 0,
                maxScore: this.getMaxScoreForThemeEnd(q.theme)
              }
            );
            
          }
        }
      }

      apiService.post('/user/endQuiz', {endQuizData: this.endQuizThemeScores, endQuizForm: this.endQuiz}, true).then(response => {
          
          MainController.completeQuizEnd();
      	
      	}
      )
      .catch(error => {
        console.log(error)
          	
      	}
      );

    },
    loadMe (){
      PlayerController.getMe().then(me => {
          this.user = me;
      	}
      )
      .catch(error => {
        console.log(error)
          	
      	}
      );	
    },
    loadResults (){
      this.themeNames = Text.get("themeNames");

      PlayerController.getResults().then(results => {
        
          this.startQuizThemeScores = JSON.parse(results.startQuizData);
          this.endQuizThemeScores = JSON.parse(results.endQuizData);
      	}
      )
      .catch(error => {
        console.log(error)
          	
      	}
      );	
    },
    checkClientSpace (e){
      this.clientSpaceError = null;
      this.clientSpaceSuccess = null;

      if(this.user.activeKey)
      {
        this.user.key = null;
      }

      apiService.post('/user/editMe', this.user, true)
      .then(response => {
          
      		this.clientSpaceSuccess = response.data.message;

          this.loadMe();
      	
      	}
      )
      .catch(error => {
        console.log(error)
      		this.clientSpaceError = error.response.data.message;
      		
      	}
      );		

  		e.preventDefault();

    },
  	checkHelp: function (e) {

    this.helpBtnDisable = true;
  	this.helpError = '';
    this.isError = false;

    this.helpSuccess = '';
    this.isSuccess = false;
      
    apiService.post('/game/help', {
            firstname: this.helpFirstname,
            lastname: this.helpLastname,
            email: this.helpEmail.toLowerCase(),
            subject: this.helpSubject,
            message: this.helpMessage
        })
      .then(response => {
          this.isError = false;
          this.isSuccess = true;
          this.helpBtnDisable = false;

          this.helpFirstname = '';
          this.helpLastname = '';
          this.helpEmail = '';
          this.helpSubject = '';
          this.helpMessage = '';

      		this.helpSuccess = response.data.message;

          
      	
      	}
      )
      .catch(error => {
        console.log(error)
          this.isError = true;
          this.isSuccess = false;
          this.helpBtnDisable = false;
      		this.helpError = error.response.data.message;
      		
      	}
      );		

  		e.preventDefault();
  	},
    checkPassword() 
    {
      
      this.password_length = this.user.password.length;
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      if (this.password_length >= 8) 
      {
        this.contains_eight_characters = true;
      } else 
      {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.user.password);
      this.contains_uppercase = /[A-Z]/.test(this.user.password);
      this.contains_special_character = format.test(this.user.password);

      if(	
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true
      ) 
      {
        this.valid_password = true;			
      } else 
      {
        this.valid_password = false;
      }
    },
    checkPassword2() 
    {
      if( (this.valid_password) && (this.user.password == this.user.password2) )
      {
        this.passwords_match = true;
      }
    },
    openCredits()
    {
      MenuController.openCredits();
    }
  }
  
}
</script>
<style lang="scss" scoped>

  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    background-color: #000;
    

    font-family: 'Roboto', sans-serif;

    -ms-overflow-style: none; /* for Internet Explorer, Edge */
	  scrollbar-width: none; /* for Firefox */
	  overflow: hidden !important; 
    
  }

  * {
    -webkit-tap-highlight-color: transparent;	

  }

  *, *:focus{
      outline: none;   
  }

  #game {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
</style>