<template>

<div id="wrapper">

<header>
  <div class="d-none d-sm-block logo"></div>
  <div class="d-sm-none logo mobile"></div>
  <h1>Norbert Weston</h1>
  <h2>Épisode #1 la rançon</h2>				
  
  <div class="buttons">
    <a class="play btn btn-lg btn-primary" v-on:click="playNow()">Jouer maintenant</a>
    <a class="logout btn btn-lg btn-primary" v-on:click="logout()"  v-bind:class="{ visible: connected }"></a>
  </div>

  

  <!--
  <nav class="navbar sticky-top navbar-expand-lg navbar-dark bg-dark">
  
    <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav ml-auto">
        <a class="nav-item nav-link active" href="#game">Le jeu <span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link" href="#course">La formation</a>
        <a class="nav-item nav-link" href="#experts">Les experts</a>
        <a class="nav-item nav-link" href="#contact">Contactez-nous</a>
      </div>
    </div>
  </nav>
  -->
</header>

<div id="content">
  <div class="row m-4">
    <div class="col-12">
      <div style="padding:56.25% 0 0 0;position:relative;">
        <iframe src="https://player.vimeo.com/video/850953431?h=64b0e14d68&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Norbert Weston #1 la rançon"></iframe>
      </div>
    </div>
  </div>
  <div class="row game m-4"><a name="game"></a>
    <div class="col-12 col-md-6">
      <h1>
        Une cybercriminalité en augmentation
      </h1>
      <h2>En 2022</h2>
        <ul>
          <li>
            54% des entreprises françaises ont été attaquées
          </li>
          <li>
            +255% d'attaques par ransomware
          </li>
          <li>
            47% des télétravailleurs se font piéger par un phishing
          </li>
          <li>
            50 000€ de dégâts en moyenne par attaque
          </li>
          <li>
            40% des entreprises ont investi dans leur cybersécurité
          </li>
        </ul>
        <h2></h2>
        <p>
          Vous aussi, déjouez les pièges, soyez sensibilisé aux risques de cybersécurité.</p>
        <p>
          Immergez-vous dans une enquête cybercriminelle en ligne.<br>
          Apprenez en jouant, sans installation, à un jeu simple pour tous publics.<br>
          Parcourez les 11 chapitres, à votre rythme, entre 2 et 4 heures.
        </p>
      </div>
    </div>
    <div class="row mt-5 mb-5">
      <div class="col-12 d-flex">
        <!-- <iframe class="m-auto video" width="100%" height="360" src="https://www.youtube.com/embed/_Rks2oCRS88" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
      </div>
    </div>
  <div class="row course m-4">
    <div class="col-12 col-md-6 offset-md-6">
      <h1>Norbert Weston - Le jeu vidéo sérieux</h1>
      <p>
        Vous incarnez Théo, stagiaire chez Weston Security Network. Vous enquêtez sur une affaire cybercriminelle aux côtés de votre patron, Norbert Weston. Dans ce jeu vidéo sérieux, vous allez découvrir et mettre en application les bonnes pratiques de sécurité informatique définies par ANSSI (l'Agence Nationale de la Sécurité des Systèmes d'Information). Vous devrez étudier, analyser et déjouer les pièges tendus par le pirate. <br>
        Arriverez-vous à résoudre l'enquête avant le versement de la rançon ?  
      </p>
      <h2>Un jeu narratif en réalité alternée</h2>
      <p>
        Immergé dans des scènes en 3D, vous participerez à un jeu narratif qui alternera entre actions sur votre ordinateur et discussion sur mobile.
      </p>
      <h2>Un jeu et une formation</h2>
      <p>	
        À travers ce jeu sérieux, vous découvrirez les bonnes pratiques en matière de sécurité informatique :
      </p>
      <ul>
        <li>Choisir ses mots de passe et les rendre robustes</li>
        <li>L'usage sécurisé des réseaux Wifi</li>
        <li>Le contrôle de vos traces papier</li>
        <li>La sécurisation de votre poste de travail et de votre poste informatique</li>
        <li>La protection de votre vie privée</li>
        <li>Sauvegarder, protéger ses sauvegardes et mettre ses appareils à jour</li>
        <li>Se protéger contre le Phishing, le Catfishing et le social Hacking</li>
        <li>Adopter la double authentification...</li>
      </ul>
    </div>
  </div>



  <div class="row experts m-4"><a name="experts"></a>
    <div class="col-12">
      <h1>Les experts</h1>

      <div>
          <img class="profile-picture" src="@/assets/images/william-bourgeois.jpg" alt="William Bourgeois">
          <h2>William Bourgeois - Expert en cybersécurité</h2>
          <p>
            De formation Docteur en Sciences, spécialité Informatique, William BOURGEOIS est l’ancien Responsable de la Sécurité des Systèmes d’Information dans un groupe d’expert comptable leader en France. Il était membre du comité Sécurité avec lequel il a implémenté un Système de Management de la Sécurité de l’Information au sein de la DSI. La certification ISO27001 a été obtenue en décembre 2017.
          </p>
          <p>
            Il est aujourd’hui consultant en cybersécurité auprès des PME, lead auditeur 27001.
          </p>
          <p>
            Il occupe également des fonctions d’enseignant pour le Master 2 OPSIE (Organisation et Protection des Systèmes d’Information de l’Entreprise) à Lyon 2, master labellisé par l’ANSSI, et le Conservatoire National des Arts et des Métiers (CNAM) d’Auvergne-Rhône Alpes. Les matières enseignées sont : cryptologie (OPSIE), analyse de risques (CNAM), droit-enjeux de sécurité-conformité (CNAM).
          </p>
          <p>
            Fort de ses 21 ans d’expérience dans la direction de projets informatiques en assistance à maîtrise d’ouvrage pour différents métiers, c’est un « généraliste » de l’informatique, avec une forte spécialisation dans la sécurité de l’information : analyse de risques (EBIOS, ISO27005, Mehari), veille, sensibilisation, conformité ISO27001 et RGPD.
          </p>
          <p>
            Il est aujourd’hui pleinement engagé dans la sécurité de l’information auprès des PME : sensibilisation, mesure de maturité SI, analyse de risques, AMOA en projet SI, audit ISO27001 et RGPD.
          </p>
          <p>
            Membre du club ISO27001 et du CLUSIF (Club de la Sécurité de l’Information Français), il se positionne comme un défenseur de la norme et de l’amélioration continue en sécurité de l’information.
          </p>
      </div>



      <div class="mt-4">
        <img class="profile-picture" src="@/assets/images/adrien-piffaretti.jpg" alt="Adrien Piffaretti">
        <h2>Adrien Piffaretti - Expert multimédia</h2>
        <p>
          Diplômé de l’école des Gobelins de Paris,
          Adrien écrit, conçoit et réalise des productions multimédia depuis 2003 à la direction d’Avant-Goût Studios.
        </p>
        <p>
          Fort de 20 ans d’expérience dans les projets numériques innovants. Il est l'inventeur d'Extrapage, il conçoit et développe des interfaces interactives mêlant réel et virtuel.
        </p>
        <p>
          Depuis 2010, son expertise est reconnue à travers le monde. Récompensé par un Adobe Max Award et 3 FWA (Favorite Web Award) en 2011, il travaille désormais sur des projets numériques ambitieux pour des clients prestigieux de la Silicon Valley. 
        </p> 
        <p>
          Adrien a écrit et réaliser ce jeu en réalité alternée, dans le but de rendre accessibles, les compétences essentielles en cybersécurité. 
        </p>

      </div>

    </div>
  </div>



  <div class="row contact m-4"><a name="contact"></a>
    <div class="col-12 col-md-6 offset-md-6">
      <h1>Contactez-nous</h1>
      <p>Écrivez à <a style="color:yellow;" :href="mailtoContact">{{ emailContact }}</a> pour obtenir plus d'informations sur le jeu et sa commercialisation.</p>
    </div>
  </div>

</div>

</div>



<!-- Modal signin -->
<div class="modal fade" id="signin" tabindex="-1" role="dialog">
	<div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
	<div class="modal-content">
		
			<div class="modal-header">
			<h5 class="modal-title">Identifiez-vous</h5>
			</div>
			<div class="modal-body">
			<form @submit.prevent="checkSignin" action="/signin" method="post">
				<p>Pour participer à ce jeu, vous devez vous identifier afin de pouvoir sauvegarder votre aventure.</p>
				

					<div class="form-group">
						<label for="signinEmail">Votre email</label>
						<input type="email" v-model="signinEmail" name="email" class="form-control" id="signinEmail" placeholder="Saisissez votre adresse email">
					</div>


					<div class="form-group">
						<label for="signinPassword">Votre mot de passe</label>
						<input type="password" v-model="signinPassword" name="password" class="form-control" id="signinPassword" placeholder="Saisissez votre mot de passe">
						
					</div>

				<p>
					Vous avez perdu votre mot de passe ? <a v-on:click="gotoPasswordLost()">Réinitialisez-le ici</a>
				</p>	
				<p>
					Vous n'avez pas de compte ? <a v-on:click="gotoSignup()">Créer un compte ici</a>
				</p>

				<!-- alert error -->
				<div id="errorSignin" class="alert alert-danger" role="alert">{{ signinError }}</div>
				<!-- /alert error -->
				
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
					<button type="submit" class="btn btn-primary">Se connecter</button>
				</div>

				</form>
			</div>
			
		
	</div>
	</div>
</div>
<!-- Modal /signin -->



<!-- Modal passwordlost -->
<div class="modal fade" id="passwordlost" tabindex="-1" role="dialog">
<div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
  <div class="modal-content">
    
      <div class="modal-header">
        <h5 class="modal-title">Mot de passe perdu ?</h5>
      </div>
      <div class="modal-body">
        <form @submit.prevent="checkPasswordLost" action="/passwordLost" method="post">
          <p>Saisissez votre adresse email pour recevoir un lien afin de réinitialiser votre mot de passe.</p>
        

          <div class="form-group">
            <label for="passwordLostEmail">Votre email</label>
            <input type="email" v-model="passwordLostEmail" name="email" class="form-control" id="passwordLostEmail" placeholder="Saisissez votre adresse email">
        </div>

          
      <p>
        Retour au <a v-on:click="gotoSignin()">formulaire d'identification</a>
      </p>
      <p>
        Vous n'avez pas de compte ? <a v-on:click="gotoSignup()">Créer un compte ici</a>
      </p>

      <!-- alert error -->
      <div id="errorPasswordLost" class="alert alert-danger" role="alert">{{ passwordLostError }}</div>
      <!-- /alert error -->
        
      <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
            <button type="submit" class="btn btn-primary">Réinitialiser votre mot de passe</button>
        </div>

     </form>
      </div>
      
  
  </div>
</div>
</div>
<!-- Modal /passwordlost -->		


<!-- Modal signup -->
<div class="modal fade" id="signup" tabindex="-1" role="dialog">

  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
    <div class="modal-content">
      
        <div class="modal-header">
          <h5 class="modal-title">Créez un compte</h5>
          
        </div>
        <div class="modal-body">

          <form ref="signupForm" @submit.prevent="checkSignup" method="post">
        
            <p>Pour participer à ce jeu, vous devez créer un compte. C'est gratuit ! <br>
            Vous avez déjà un compte ? <a v-on:click="gotoSignin()">Identifiez-vous ici</a>
        </p>
          
              <div class="form-group">
              <label for="signupFirstname">Votre prénom</label>
              <input type="text" name="firstname" class="form-control" id="signupFirstname" v-model="signupFirstname" placeholder="Saisissez votre prénom" required>
          </div>

          <div class="form-group">
              <label for="signupLastname">Votre nom</label>
              <input type="text" name="lastname" class="form-control" id="signupLastname" v-model="signupLastname" placeholder="Saisissez votre nom" required>
          </div>

          <div class="form-group">
              <label for="signupCompany">Votre société</label>
              <input type="text" name="company" class="form-control" id="signupCompany" v-model="signupCompany" placeholder="Saisissez le nom de votre société">
          </div>
            
          <div class="form-group">
              <label for="signupEmail">Votre email</label>
              <input type="email" name="email" class="form-control" id="signupEmail" v-model="signupEmail" placeholder="Saisissez votre adresse email" required>
          </div>

          <div class="form-group">
              <label for="signupKey">Votre clé de licence premium <small>(facultatif)</small></label>
              <input type="text" name="key" class="form-control" id="signupKey" v-model="signupKey" placeholder="Copiez votre clé de licence premium">
          </div>


          <div class="form-group">          
            <p>Choisissez votre mot de passe, il doit :</p>
            <ul>
              <li v-bind:class="{ is_valid: contains_nine_characters }">avoir une longueur de 9 caractères minimum</li>
              <li v-bind:class="{ is_valid: contains_number }">contenir au moins un nombre</li>
              <li v-bind:class="{ is_valid: contains_uppercase }">contenir au moins une majuscule</li>
              <li v-bind:class="{ is_valid: contains_special_character }">contenir au moins un caractère spécial</li>
            </ul>

              <input type="password" name="password" class="form-control" id="signupPassword" v-model="signupPassword" placeholder="Saisissez votre mot de passe" required @input="checkPassword" autocomplete="off">
          </div>

          <div class="form-group">
              <label class="pass2" v-bind:class="{ is_valid: passwords_match }" for="signupPassword">Saisissez votre mot de passe une deuxième fois</label>
              <input type="password" name="password2" class="form-control" id="signupPassword2" placeholder="Saisissez à nouveau votre mot de passe" required @input="checkPassword2" v-model="password2" autocomplete="off">
          </div>

          <div class="form-group cgv">
              
              <input type="checkbox" name="terms" class="" id="signupTerms" required>
              <label for="signupTerms">J'ai lu et j'accepte</label> <a @click="openCGV()">les conditions générales du site</a>
          </div>

          <div class="form-group">
            <vue-recaptcha @verify="verifyRecaptcha" ref="recaptcha" sitekey="6LcMPs4ZAAAAAChB58nXHJpaa-cfvhmkBtM0Yxlk"></vue-recaptcha>
          </div>
            
        
          

        <!-- alert error -->
        <div id="errorSignup" class="alert alert-danger" role="alert">{{ signupError }}</div>
        <!-- /alert error -->

        <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
              <button id="signupSubmit" type="submit" class="btn btn-primary">Envoyer</button>
           </div>
      </form>
        
        </div>
        
    
    </div>
  </div>

</div>
<!-- Modal /signup -->




<!-- Modal linkmodal -->
<div class="modal fade" id="linkmodal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
    <div class="modal-content">
      
        <div class="modal-header">
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <iframe></iframe>
        </div>
        
    
    </div>
  </div>
</div>
<!-- Modal /linkmodal -->			


<!-- toast message -->
<div id="messageToast" class="toast absolute-top-right mt-2" role="alert" aria-live="assertive" aria-atomic="true">
<div class="toast-header d-flex justify-content-between">
  <strong>{{title}}</strong>
  <a class="btn-close ml-auto" data-bs-dismiss="toast"></a>
</div>
<div class="toast-body">
  {{message}}
</div>
</div>
<!-- toast /message -->





<div id="infoCookies"><span>En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de cookies pour réaliser des statistiques de visites et vous proposer des publicités ciblées.</span> <a href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser" target="_blank" class="external">En savoir plus</a><a class="acceptCookiesBt btn btn-primary" v-on:click="acceptCookies()">J’accepte</a></div>

</template>

<script>

import { VueRecaptcha } from 'vue-recaptcha';
import $ from "jquery";
import {Modal, Toast} from 'bootstrap';
import apiService from '@/services/apiService';
import cookieService from '@/services/cookieService';

export default {
  name: 'HomeView',
  components: { VueRecaptcha },
  data: function () {
    return {
      connected: false,
      player: null,
      signinEmail: null,
      signinPassword: null,
      signupFirstname: null,
      signupLastname: null,
      signupCompany: null,
      signupEmail: null,
      signupKey: null,
      signupPassword: null,
      passwordLostEmail: null,
      token: null,
      signinError: '',
      signupError: '',
      passwordLostError: '',
      message: '',
      title: '',
      password: null,
      password2: null,
      password_length: 0,
      contains_nine_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
      passwords_match: false,
      emailContact: null,
      mailtoContact: null
    }
  },
  mounted() {

    this.composeEmailContact();
    const message = this.$route.params.message;
    

	this.signinModal = new Modal($('#signin'));
	this.signupModal = new Modal($('#signup'));
	this.passwordlostModal = new Modal($('#passwordlost'));

	this.messageToast = new Toast($('#messageToast'));

    if (!localStorage.acceptedCookies) {
      $("#infoCookies").addClass("visible");
    }


    if(message)
    {
      this.title = 'Info';

      switch(message)
      {
        case 'confirmed':
          this.message = "Votre compte est désormais validé.";
        break;
        case 'alreadyConfirmed':
          this.message = "Votre compte a déjà été validé.";
        break;
        case 'passwordChanged':
          this.message = "Votre mot de passe a été modifié.";
        break;
        case 'invalidToken':
          this.title = 'Erreur';
          this.message = "Lien de connexion erroné ou périmé.";
        break;
        case 'invalidPasswordToken':
          this.title = 'Erreur';
          this.message = "Lien de changement de mot de passe erroné ou périmé.";
        break;
        case 'server':
          this.title = 'Erreur';
          this.message = "Erreur du serveur.";
        break;
        case 'unconfirmed':
          this.title = 'Erreur';
          this.message = "Votre compte n'a pas été confirmé. Consultez vos emails.";
        break;
        case 'unknown':
          this.title = 'Erreur';
          this.message = "Utilisateur inconnu";
        break;
        case 'passAlreadyChanged':
          this.title = 'Erreur';
          this.message = "Lien de changement de mot de passe déjà utilisé. Faites une nouvelle demande.";
        break;
        case 'notfound':
          this.title = 'Erreur';
          this.message = "Page introuvable.";
        break;
      }
      this.messageToast.show();
    }

    this.checkSession();
    

  },
  methods: 
  {
    composeEmailContact()
    {
      this.emailContact = "contact";
      this.emailContact += "@";
      this.emailContact += "norbertweston";
      this.emailContact += ".com";

      this.mailtoContact = "mailto:"+this.emailContact;
    },
    acceptCookies()
    {

      $("#infoCookies").removeClass("visible");
      localStorage.acceptedCookies = true;
    },
    logout () 
    {

      cookieService.removeToken();
      this.message = "Vous êtes maintenant déconnecté.";
      this.title = 'Info';
		
		this.messageToast.show();
		this.connected = false;
    },
    checkSignin(e) 
    {
      this.signinError = '';

		if(!this.signinEmail || !this.signinPassword)
		{
			this.signinError = "Veuillez renseigner votre email et votre mot de passe";
		}else
		{

			apiService.post('/user/signin', {
				email: this.signinEmail.toLowerCase(),
				password: this.signinPassword
			})
			.then(response => {

        cookieService.setToken(response.data.token);

			this.signinModal.hide();

			// pas terrible nécessite l'attente de vue js car le watch sur le token n'a pas le tps de s'executer
			setTimeout(this.playGame, 500);
			//this.playGame();
			})
			.catch(error => {
				this.signinError = error.response.data.message;


			});	
		
		}
      e.preventDefault();
    },
    checkPassword() 
    {
      
      this.password_length = this.signupPassword.length;
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      if (this.password_length >= 9) 
      {
        this.contains_nine_characters = true;
      } else 
      {
        this.contains_nine_characters = false;
      }

      this.contains_number = /\d/.test(this.signupPassword);
      this.contains_uppercase = /[A-Z]/.test(this.signupPassword);
      this.contains_special_character = format.test(this.signupPassword);

      if(	
        this.contains_nine_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true
      ) 
      {
        this.valid_password = true;			
      } else 
      {
        this.valid_password = false;
      }
    },
    checkPassword2() 
    {
      if( (this.valid_password) && (this.signupPassword == this.password2) )
      {
        this.passwords_match = true;
      }
    },
    signupSubmit()
    {
      this.$refs.signupForm.submit();
    },
    verifyRecaptcha(response){
      this.recaptchaResponse = response;
    },
    checkSignup(e)
    {
      this.signupError = '';

      if(this.valid_password && this.passwords_match && this.recaptchaResponse)
      {
        apiService.post(
          '/user/signup',
          {
            firstname: this.signupFirstname,
            lastname: this.signupLastname,
            company: this.signupCompany,
            email: this.signupEmail.toLowerCase(),
            key: (this.signupKey) ? this.signupKey.toLowerCase() : this.signupKey,
            password: this.signupPassword,
            recaptchaResponse: this.recaptchaResponse
          }
        )
        .then(() => 
        {
          
          
          this.signupModal.hide();

          this.title = 'Info';
          this.message = 'Vous avez reçu un email pour valider votre compte.';
          this.messageToast.show();

        })
        .catch(error => 
        {
          
          this.$refs.recaptcha.reset();
          this.recaptchaResponse = null;
          this.signupError = error.response.data.message;
        });		  	

      }else
      {
        this.signupError = 'Veuillez définir votre mot de passe selon les critères, le saisir une deuxième fois pour confirmation et valider le reCaptcha.';
      }	
      
      e.preventDefault();
    },
    checkPasswordLost(e) 
    {

      this.passwordLostError = '';

      if(!this.passwordLostEmail)
      {
        this.passwordLostError = "Veuillez renseigner votre email";
      }else
      {
        apiService.post("/user/passwordLost", {
          email: this.passwordLostEmail.toLowerCase()
        })
        .then(() => {
          this.passwordlostModal.hide();
          this.title = 'Info';
          this.message = 'Vous avez reçu un email pour réinitialiser votre mot de passe.';
          this.messageToast.show();
        })
        .catch(error => {
          this.passwordLostError = error.response.data.message;
        }
        );		  	

      }
      e.preventDefault();

    },
    gotoSignin () 
    {
		this.signinModal.show();
		this.signupModal.hide();
		this.passwordlostModal.hide();
		
    },
    gotoSignup () {
		this.signinModal.hide();
		this.passwordlostModal.hide();
		this.signupModal.show();
      
    },
    gotoPasswordLost () {
		this.signinModal.hide();
		this.passwordlostModal.show();
		this.signupModal.hide();
    },
    playNow() 
    {
      if(!this.connected)
      {
        this.gotoSignin();	
      }else
      {
        this.playGame();
      }

    },
    checkSession()
    {
      apiService.get('/user/session', true)
      .then(() => {
        this.connected = true;
      })
      .catch(error => {
        console.log(error.response.data);
      });
    },
    playGame() 
    {
      
      this.$router.push('/play');
    },
    openCGV()
    {
      window.open(process.env.VUE_APP_API_URL+'/terms','cgv');
    }
  }
}
</script>
<style scoped lang="scss">
html, body {
  overflow: scroll !important;
  height: auto !important;
}

.profile-picture {
  float: left;
  width: 100%;
  height: auto;
  max-width: 190px;
  margin-right: 20px;
}

.video {
  max-width: 640px;
}
</style>