import { DialogController } from "../controller/dialogController";
import { HelpController } from "../controller/helpController";
import { MainController } from "../controller/mainController";
import { GameScene } from "../display/gameScene";
import { DialogEvent } from "../events/dialogEvent";
import { Text } from "../display/text";

class Chapter6Scene2 extends GameScene {



    constructor() {
        super("chapter6Scene2");
    } 

    
    preload ()
    {
        super.preload(false);
        
        
        
    }

    create ()
    {
        super.create();




        this.checkComplete();

        

        MainController.setCurrentScene( this );


    }

    checkComplete()
    {
        var chapterCompletedPromise = MainController.isChapterCompleted('chapter6');

        Promise.resolve(chapterCompletedPromise).then((result) => {
            
            if(result)
            {
                this.goToScene('chapter7Intro');
            }else
            {
                this.firstDialog();
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    firstDialog()
    {
        this.timerDialog = this.time.delayedCall(2000, function(){

            this.dialog("theo", Text.get("c6s2-dialog1"), function(scene){
                scene.endDialog();
            }, this);

        }, [], this);
        

        this.timerHelp = this.time.delayedCall(4000, function(){
            if(MainController.isMobile())
            {
                this.displayHelpMobile();      
            }else
            {
                this.displayHelpDesktop();
            }
        }, [], this);


    }


    displayHelpDesktop()
    {
        HelpController.add( Text.get("c6s2-help1")+"<img src='https://api.qrserver.com/v1/create-qr-code/?data="+MainController.getFlirterURL()+"/c6s1/"+MainController.getToken()+"&size=180x180'><br><a target='flirter' href='"+MainController.getFlirterURL()+"/c6s1/"+MainController.getToken()+"'>"+Text.get("c6s2-help1_1")+"</a>" );
    }

    displayHelpMobile()
    {
        HelpController.add( Text.get("c6s2-help2")+"<a class='button' target='flirter' href='"+MainController.getFlirterURL()+"/c6s1/"+MainController.getToken()+"'>"+Text.get("c6s2-help2_1")+"</a>" );        
        

    }



    endDialog()
    {
  
        this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
     
            this.removeEventListener(DialogEvent.CLOSE, handler);
            var scene = MainController.getCurrentScene();

            var chapterCompletedPromise = MainController.isChapterCompleted('chapter6');

            Promise.resolve(chapterCompletedPromise).then((result) => {
            
                if(result)
                {
                    scene.goToScene('chapter7Intro');
                }else
                {
                    HelpController.erase();
                    DialogController.erase();
    
                    DialogController.DisplayNext();
                    
                    scene.firstDialog();
                }
            }).catch((error) => {
                console.log(error);
            });

            
        });
    }

    



}
export {Chapter6Scene2}