import { DialogController } from "../controller/dialogController";
import { HelpController } from "../controller/helpController";
import { MainController } from "../controller/mainController";
import { GameScene } from "../display/gameScene";
import { ParallaxScene } from "../display/parallaxScene";
import { DialogEvent } from "../events/dialogEvent";
import { Text } from "../display/text";

class Chapter8Scene2 extends GameScene {



    constructor() {
        super("chapter8Scene2");
    } 

    preload()
    {
    
        this.load.image('c8-s2-background', 'assets/images/chapters/chapter8/scene2/background.jpg');
        this.load.image('c8-s2-i0', 'assets/images/chapters/chapter8/scene2/i0.jpg');
        this.load.image('c8-s2-i1', 'assets/images/chapters/chapter8/scene2/i1.jpg');
        this.load.image('c8-s2-i2', 'assets/images/chapters/chapter8/scene2/i2.jpg');
        this.load.image('c8-s2-i3', 'assets/images/chapters/chapter8/scene2/i3.jpg');
        this.load.image('c8-s2-i4', 'assets/images/chapters/chapter8/scene2/i4.jpg');
        this.load.image('c8-s2-i5', 'assets/images/chapters/chapter8/scene2/i5.jpg');
        this.load.image('c8-s2-i6', 'assets/images/chapters/chapter8/scene2/i6.jpg');
        this.load.image('c8-s2-i7', 'assets/images/chapters/chapter8/scene2/i7.jpg');
        this.load.image('c8-s2-i8', 'assets/images/chapters/chapter8/scene2/i8.jpg');
        this.load.image('c8-s2-i9', 'assets/images/chapters/chapter8/scene2/i9.jpg');
        this.load.image('c8-s2-i10', 'assets/images/chapters/chapter8/scene2/i10.jpg');
        this.load.image('c8-s2-i11', 'assets/images/chapters/chapter8/scene2/i11.jpg');
        this.load.image('c8-s2-i12', 'assets/images/chapters/chapter8/scene2/i12.jpg');
        this.load.image('c8-s2-i13', 'assets/images/chapters/chapter8/scene2/i13.jpg');
        this.load.image('c8-s2-i14', 'assets/images/chapters/chapter8/scene2/i14.jpg');
        this.load.image('c8-s2-zoom', 'assets/images/chapters/chapter8/scene2/zoom.png');
        
        this.load.audio('ambient-secret', 'assets/audios/musics/ambiance-secret.mp3');
        
    }

    create ()
    {
        
        super.create();

        this.setParallaxDistance( ParallaxScene.DISTANCE_NEAR );

        this.rotationTurn = -1;

        this.posSc1X = -648;
        this.posSc1Y = -519;

        this.posSc2X = 243;
        this.posSc2Y = -519;

        this.posSc3X = 1137;
        this.posSc3Y = -519;

        this.posSc4X = -648;
        this.posSc4Y = 108;

        this.posSc5X = 243;
        this.posSc5Y = 108;

        this.posSc6X = 1137;
        this.posSc6Y = 108;

        this.positions = [
            {
                x: this.posSc6X,
                y: this.posSc6Y
            },
            {
                x: this.posSc5X,
                y: this.posSc5Y
            },
            {
                x: this.posSc4X,
                y: this.posSc4Y
            },
            {
                x: this.posSc3X,
                y: this.posSc3Y
            },
            {
                x: this.posSc2X,
                y: this.posSc2Y
            },
            {
                x: this.posSc1X,
                y: this.posSc1Y
            }
        ];

        this.background = this.add.image(0,0, 'c8-s2-background');
        this.i0 = this.add.image(0, 0, 'c8-s2-i0');
        this.i1 = this.add.image(0, 0, 'c8-s2-i1');
        this.i2 = this.add.image(0, 0, 'c8-s2-i2');
        this.i3 = this.add.image(0, 0, 'c8-s2-i3');
        this.i4 = this.add.image(0, 0, 'c8-s2-i4');
        this.i5 = this.add.image(0, 0, 'c8-s2-i5');
        this.i6 = this.add.image(0, 0, 'c8-s2-i6');
        this.i7 = this.add.image(0, 0, 'c8-s2-i7');
        this.i8 = this.add.image(0, 0, 'c8-s2-i8');
        this.i9 = this.add.image(0, 0, 'c8-s2-i9');
        this.i10 = this.add.image(0, 0, 'c8-s2-i10');
        this.i11 = this.add.image(0, 0, 'c8-s2-i11');
        this.i12 = this.add.image(0, 0, 'c8-s2-i12');
        this.i13 = this.add.image(0, 0, 'c8-s2-i13');
        this.zoom = this.add.image(0, 0, 'c8-s2-zoom');

        this.i0.setVisible(false);
        this.i1.setVisible(false);
        this.i2.setVisible(false);
        this.i3.setVisible(false);
        this.i4.setVisible(false);
        this.i5.setVisible(false);
        this.i6.setVisible(false);
        this.i7.setVisible(false);
        this.i8.setVisible(false);
        this.i9.setVisible(false);
        this.i10.setVisible(false);
        this.i11.setVisible(false);
        this.i12.setVisible(false);
        this.i13.setVisible(false);
        this.zoom.setVisible(false);

        this.addToScene( this.background, 1 );
        this.addToScene( this.i0, 1 );
        this.addToScene( this.i1, 1 );
        this.addToScene( this.i2, 1 );
        this.addToScene( this.i3, 1 );
        this.addToScene( this.i4, 1 );
        this.addToScene( this.i5, 1 );
        this.addToScene( this.i6, 1 );
        this.addToScene( this.i7, 1 );
        this.addToScene( this.i8, 1 );
        this.addToScene( this.i9, 1 );
        this.addToScene( this.i10, 1 );
        this.addToScene( this.i11, 1 );
        this.addToScene( this.i12, 1 );
        this.addToScene( this.i13, 1 );
        this.addToScene( this.zoom, 1 );

        this.capturesNum = 13;
        this.firstViewOfFatboy = false;
        this.firstViewOfWang = false;
        
          


        this.musicAudio = this.sound.add(
            'ambient-secret', 
            {
                volume: 0.5,
                loop: true
            });

        this.musicAudio.play();

        this.timerStart = this.time.delayedCall(5000, function(){
                
            this.firstDialog();

        }, [], this);
    

        MainController.setCurrentScene( this );


    }

    unbindAll()
    {
        for(var i=0; i <= this.capturesNum; i++)
        {
            this['i'+i].off('pointerdown');
        }
    }

    hideAll()
    {
        for(var i=0; i <= this.capturesNum; i++)
        {
            this['i'+i].setVisible(false);
        }
    }
    capturesRotation()
    {
        this.rotationTurn++;
        this.hideAll();
        if(this.rotationTurn <= 8)
        {
            
            for(var i=0; i<6; i++)
            {
                var index = i+this.rotationTurn;
                this['i'+index].off('pointerdown');

                if(index <= this.capturesNum)
                {
                    this['i'+index].x = this.positions[i].x;
                    this['i'+index].y = this.positions[i].y;
                    this['i'+index].alpha = 0;
                    this['i'+index].setVisible(true);

                    this.tweens.add({
                        targets: this['i'+index],
                        alpha: {from: 0, to: 1},
                        duration: 500,
                        delay: 200*i
                    });

                    
                    this['i'+index].setInteractive();
                    this['i'+index].once('pointerdown', function(){
                        this.capturesRotation();
                    }, this);
                }

                if( (index == 9) && !this.firstViewOfWang)
                {
                    this.firstViewOfWang = true;
                    this.time.delayedCall(2000, function(){
                
                        this.secondDialog();
            
                    }, [], this);
                    
                }

                if( (index == 13) && !this.firstViewOfFatboy)
                {
                    this.firstViewOfFatboy = true;
                    this.unbindAll();
                    this.time.delayedCall(2000, function(){
                
                        this.thirdDialog();
                        
            
                    }, [], this);
                }
                

            }

        }
    }

    firstDialog()
    {
        this.dialog("myriam", Text.get("c8s2-dialog1"), function(scene){
            
            this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                this.removeEventListener(DialogEvent.CLOSE, handler);
                var scene = MainController.getCurrentScene();
                scene.capturesRotation();
            });

        });
    }

    secondDialog()
    {
        this.unbindAll();
        HelpController.erase();
        DialogController.erase();
        this.cleanNextDialog();
        this.dialog("theo", Text.get("c8s2-dialog2"));
        this.dialog("weston", Text.get("c8s2-dialog3"));
        this.dialog("myriam", Text.get("c8s2-dialog4"), function(scene){
            
            this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                this.removeEventListener(DialogEvent.CLOSE, handler);
                var scene = MainController.getCurrentScene();
                scene.capturesRotation();
            });

        });
    }

    thirdDialog()
    {
        HelpController.erase();
        DialogController.erase();
        this.cleanNextDialog();

        this.dialog("theo", Text.get("c8s2-dialog5"));
        this.dialog("weston", Text.get("c8s2-dialog6"));
        this.dialog("myriam", Text.get("c8s2-dialog7"), function(scene){
            
            this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                this.removeEventListener(DialogEvent.CLOSE, handler);
                var scene = MainController.getCurrentScene();
                scene.finalZoom();
            });

        });
    }

    finalZoom()
    {
        this.i13.off('pointerdown');
        this.i13.once('pointerdown', function(){
            this.zoom.setVisible(true);
            this.zoom.alpha = 0;
            this.tweens.add({
                targets: this.zoom,
                alpha: {from: 0, to: 1},
                duration: 1000
            });
            this.time.delayedCall(3000, function(){
                
                this.fourthDialog();
    
            }, [], this);
        }, this);
    }
    

    fourthDialog()
    {
        HelpController.erase();
        DialogController.erase();
        this.cleanNextDialog();

        this.dialog("weston", Text.get("c8s2-dialog8"));
        this.dialog("theo", Text.get("c8s2-dialog9"));
        this.dialog("weston", Text.get("c8s2-dialog10"));
        this.dialog("theo", Text.get("c8s2-dialog11"));
        this.dialog("weston", Text.get("c8s2-dialog12"));
        this.dialog("myriam", Text.get("c8s2-dialog13"), function(scene){
            
            this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                this.removeEventListener(DialogEvent.CLOSE, handler);
                var scene = MainController.getCurrentScene();
                scene.zoom.setVisible(false);
                scene.rotationTurn = -1;
                scene.capturesRotation();
                scene.time.delayedCall(10000, function(){
                
                    this.endDialog();
        
                }, [], scene);
            });
        });
    }

    endDialog()
    {
        this.unbindAll();
        HelpController.erase();
        DialogController.erase();
        this.cleanNextDialog();

        this.dialog("weston", Text.get("c8s2-dialog14"));
        this.dialog("myriam", Text.get("c8s2-dialog15"));
        this.dialog("weston", Text.get("c8s2-dialog16"));
        this.dialog("theo", Text.get("c8s2-dialog17"));
        this.dialog("weston", Text.get("c8s2-dialog18"), function(){
            if(MainController.isMobile())
            {
                HelpController.add( Text.get("c8s2-help1")+"<a class='button' target='flirter' href='"+MainController.getFlirterURL()+"/c8s2/"+MainController.getToken()+"'>"+Text.get("c8s2-help1_1")+"</a>" );
            }else
            {
                HelpController.add( Text.get("c8s2-help2")+"<img src='https://api.qrserver.com/v1/create-qr-code/?data="+MainController.getFlirterURL()+"/c8s2/"+MainController.getToken()+"&size=180x180'><br><a target='flirter' href='"+MainController.getFlirterURL()+"/c8s2/"+MainController.getToken()+"'>"+Text.get("c8s2-help2_1")+"</a>" );
            }


           this.endDialListener = window.addEventListener( DialogEvent.CLOSE, function handler(){
                this.removeEventListener(DialogEvent.CLOSE, handler);
                var scene = MainController.getCurrentScene();
                scene.goToScene('chapter8Scene3');
            });
        });

        
       
    }



    beforeTransition()
    {
        
        this.tweens.add({
            targets:  this.musicAudio,
            volume:   0,
            duration: 1000
        });

    } 

    beforeDestroy()
    {
        this.musicAudio.destroy();
    }


}
export {Chapter8Scene2}