import $ from "jquery";
import {Modal} from 'bootstrap';
import { MainController } from "./mainController";
import { PlayerController } from "./playerController";
import { InterfaceEvent } from "../events/interfaceEvent";

class MenuController {

	constructor() {
        if (!MenuController.instance) {
            return MenuController.instance;
        }

        MenuController.instance = this;
        

        return this;
    }

	static init()
	{
		this.confirmQuitModal = new Modal($('#confirmQuit'));
		this.helpModal = new Modal($('#helpModal'));
		this.linkModal = new Modal($('#linkmodal'));
		this.clientSpaceModal = new Modal($('#clientSpaceModal'));
		this.cyberModal = new Modal($('#cyberModal'));
		this.opened = false;

		MainController.stopPropagation();
		$("#interface nav .menu").on("click", function(e){
			MenuController.toggle();
			

			e.stopPropagation();
		})

		$("#menu a.close").on("click", function(e){
			MenuController.close();
			

			e.stopPropagation();
		});


		// ouverture des crédits et autres modals...
		$('.ls-modal').on('click', (e)=>{
          e.preventDefault();
          this.linkModal.show();
		  $("#linkmodal .modal-body iframe").attr('src', $(this).attr('href'));
        });


		// bouton quit
		$('#quitGameButton').click(()=>{
			this.confirmQuitModal.show();

			$('#confirmQuit .confirmQuitButton').click(()=>{
				location.href = '/';
			});
		});

		// bouton espace client
		$('#clientSpaceButton').click(()=>{
			this.clientSpaceModal.show();
			window.dispatchEvent(new CustomEvent(InterfaceEvent.CLIENT_SPACE_OPENED));
		});

		// bouton compétences cyber
		$('#cyberButton').click(()=>{
			this.openCyberModal();
		});
        

		// bouton help
		$('#helpButton').click(()=>{
			
			this.helpModal.show();
		});


		

	}

	static openCyberModal()
	{
		this.cyberModal.show();
		window.dispatchEvent(new CustomEvent(InterfaceEvent.CYBER_COMPETENCES_OPENED));
	}

	static openCredits()
	{
		this.linkModal.show();
		$('#linkmodal .modal-body iframe').attr('src', process.env.VUE_APP_API_URL+'/credits');
	}


	static toggle()
	{
		if(this.opened)
		{
			MenuController.close();
		}else
		{
			MenuController.open();
		}
	}

	static open()
	{
		PlayerController.getMe().then(me => {


			$("#menu").addClass("active");

			$("#summary").html('');

			let chapters = PlayerController.getChapters();
			let currentChapter = MainController.getCurrentChapter();



			for (var i = 0; i < chapters.length; i++)
			{
				let chapter = chapters[i];

				let active = '';
				if(chapter.opened || me.activeKey)
				{
					active = 'active';
				}

				if(chapter.id == currentChapter)
				{
					active = 'current';
				}

				$("#summary").append("<li><a data-scene='"+chapter.scene+"' class='chapter "+active+"' title='"+chapter.subtitle+"'>"+chapter.title+"</a></li>");
			}

			$("#summary .chapter").click(function(e){
				
				if( $(this).hasClass('active') )
				{
					MainController.changeScene( $(this).attr('data-scene') );
					MenuController.close();
				}
			});

			this.opened = true;
				
			}
		)
		.catch(error => {
		console.log(error)
				
			}
		);

		
	}

	static close()
	{
		$("#menu").removeClass("active");
			

		this.opened = false;
	}


}
export {MenuController}